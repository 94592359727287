// import node module libraries
import React, { Fragment, useState, useEffect } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { Col, Row, Card, Breadcrumb, Spinner } from 'react-bootstrap';

// import sub custom components
import BookSectionsTable from './BookSectionsTable';

// rest api
import { deleteBookSection, getBook } from 'services/api/KumajiroApi';

// to display success / error messages
import Notify from 'services/Notify';

const AllBookSections = () => {
    const { bookId, version } = useParams();
	const history = useHistory()

    const [book, setBook] = useState('');
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		refreshData();
	}, [bookId, version]);

	const handleEditInfo = () => {
		history.push(`/admin/library/editor/update-book/${bookId}/${version}`)
	};

	const handleCreate = () => {
		history.push(`/admin/library/editor/create-section/${bookId}/${version}`)
	};

	const handleUpdate = (section) => {
		history.push(`/admin/library/editor/update-section/${bookId}/${version}/${section.id}`)
	};

	const handleDelete = (section) => {
		setLoading(true)
		deleteBookSection(section.id)
			.then((response) => {
				setLoading(false)
				refreshData()
				Notify.success("Section deleted")
			})
			.catch((error) => {
				setLoading(false)
				Notify.error(error.response?.data?.message ?? "Failed to delete the section")
			})
	};

	const refreshData = () => {
		setLoading(true)
		getBook(bookId, version)
			.then(response => {
                setLoading(false)
                const book = response.data
				setBook(book)                
			})
			.catch(error => {
				setLoading(false)				
				Notify.error(error.response?.data?.message ?? "An error occured")
				if (error.response?.status === 404) {
					history.replace(`/admin/library`, { replace: true });
				}				
			});
	};

	return (
		<Fragment>
			<Row>
				<Col lg={12} md={12} sm={12}>
					<div className="border-bottom pb-4 mb-4 d-md-flex align-items-center justify-content-between">
						<div className="mb-3 mb-md-0">
							<h1 className="mb-1 h2 fw-bold">
                                {book?.info?.editionName ?? ''}
                            </h1>
							<Breadcrumb>
								<Breadcrumb.Item href="/admin">Dashboard</Breadcrumb.Item>
								<Breadcrumb.Item href="/admin/library">Books</Breadcrumb.Item>
								<Breadcrumb.Item href={`/admin/library/list/${bookId}`}>
									{book?.info?.editionName ?? ''}
								</Breadcrumb.Item>
								<Breadcrumb.Item href="#" active>
                                    {book?.params?.filter((item) => item.code === 'VERSION')[0]?.value}
                                </Breadcrumb.Item>                                
							</Breadcrumb>
						</div>
						<div>
							{loading === true && 
							 	<Spinner animation="border" variant="primary" size="sm" className='me-5' />
							}
							<Link to="#" className="btn btn-secondary me-1" onClick={handleEditInfo}>
								Edit info
							</Link>
							<Link to="#" className="btn btn-primary" onClick={handleCreate}>
								Add New Section
							</Link>
						</div>
					</div>
				</Col>
			</Row>
			<Row>
				<Col lg={12} md={12} sm={12}>
					<Card>						
						<BookSectionsTable 
							items={book?.sections ?? []}
							onUpdate={handleUpdate}
							onDelete={handleDelete} />
					</Card>
				</Col>
			</Row>
		</Fragment>
	);
};

export default AllBookSections;
