// import node module libraries
import { Fragment, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import ReactPaginate from 'react-paginate';
import { ChevronLeft, ChevronRight } from 'react-feather';

// import sub components
import CourseCard from '../pages/courses/CourseCard';

const DashboardCoursesView = ({items, onBookmarked = (id, bookmarked) => {}}) => {

	const [page, setPage] = useState(0)

	//------paging start----------
	const RecordsPerPage = 16;
	const pagesVisited = page * RecordsPerPage;
	const pageCount = Math.ceil((items?.length ?? 0) / RecordsPerPage);
	const changePage = ({ selected }) => {
		setPage(selected)
	};
	const displayRecords = items.slice(
		pagesVisited,
		pagesVisited + RecordsPerPage
	).map((item, index) => {
		return (
			<Col lg={3} md={6} sm={12} key={index}>
				{<CourseCard item={item} onBookmarked={onBookmarked} />}
			</Col>
		);
	});
	//---end of paging start----------

	return (
		<Fragment>
			<Row>
				{(items?.length ?? 0) > 0 ? (
					displayRecords
				) : (
					<Col>No matching records found.</Col>
				)}
			</Row>

			<ReactPaginate
				previousLabel={<ChevronLeft size="14px" />}
				nextLabel={<ChevronRight size="14px" />}
				pageCount={pageCount}
				onPageChange={changePage}
				forcePage={page}
				containerClassName={'justify-content-center mb-0 pagination'}
				previousLinkClassName={'page-link mx-1 rounded'}
				nextLinkClassName={'page-link mx-1 rounded'}
				pageClassName={'page-item'}
				pageLinkClassName={'page-link mx-1 rounded'}
				disabledClassName={'paginationDisabled'}
				activeClassName={'active'}
			/>
		</Fragment>
	);
};
export default DashboardCoursesView;
