import { v4 as uuid } from 'uuid';

const NavbarDefault = [
	{
		id: uuid(),
		menuitem: 'Browse',
		link: '#',
		children: [
			{
				id: uuid(),
				menuitem: 'Mobile Development',
				link: '#',
				children: [
					{
						id: uuid(),
						menuitem: 'Android & Kotlin',
						link: '/category/android'
					},
					{
						id: uuid(),
						menuitem: 'iOS & Swift',
						link: '/category/ios'
					},
					{
						id: uuid(),
						menuitem: 'Flutter',
						link: '/category/flutter'
					}					
				]
			},
			{
				id: uuid(),
				menuitem: 'Web Development',
				link: '#',
				children: [
					{
						id: uuid(),
						menuitem: 'Server-Side Swift',
						link: '/category/swift-server-side'
					}
				]
			},
			{
				id: uuid(),
				menuitem: 'Game Development',
				link: '#',
				children: [
					{
						id: uuid(),
						menuitem: 'Game Tech',
						link: '/category/game'
					}
				]
			},	
			{
				id: uuid(),
				menuitem: 'macOS',
				link: '/category/macOs'
			},			
			{
				id: uuid(),
				menuitem: 'UI & UX',
				link: '/category/design'
			},
			{
				id: uuid(),
				menuitem: 'Professional Growth',
				link: '/category/professional-growth'
			},
			{
				id: uuid(),
				menuitem: 'Tools',
				link: '/category/tools'
			}
		]
	},
	{
		id: uuid(),
		menuitem: 'Learn',
		link: '#',
		children: [		
			{
				id: uuid(),
				menuitem: 'Courses',
				link: '/courses'
			},	
			{
				id: uuid(),
				menuitem: 'Books',
				link: '/library'
			}			
		]
	}
];

export default NavbarDefault;
