// import context file
import { useState } from "react";
import { FiltersContext } from 'context/Context';
import { useFilters } from 'hooks/useFilters';

const useBooksFilterProvider = () => {
  const [query, setQuery] = useState('')
  const [page, setPage] = useState(0)
  const [sortBy, setSortBy] = useState('newest')
  const [categories, setCategories] = useState([])
  const [display, setDisplay] = useState('grid')
  return {
    query,
    page,
    sortBy,
    categories,
    display,
    setQuery,
    setPage,
    setSortBy,
    setCategories,
    setDisplay
  };
}

const useCoursesFilterProvider = () => {
  const [query, setQuery] = useState('')
  const [page, setPage] = useState(0)
  const [sortBy, setSortBy] = useState('-released_at')
  const [contentTypes, setContentTypes] = useState([])
  const [categories, setCategories] = useState([])
  const [subCategories, setSubCategories] = useState([])
  const [display, setDisplay] = useState('grid')
  const [difficulties, setDifficulties] = useState([])
  return {
    query,
    page,
    sortBy,
    contentTypes,
    categories,
    subCategories,
    display,
    difficulties,
    setQuery,
    setPage,
    setSortBy,
    setContentTypes,
    setCategories,
    setDisplay,
    setSubCategories,
    setDifficulties
  };
}

/**
 * 
 * @returns 
 * List Provider
 */
 const useFiltersProvider = () => {
  return {
    books: useBooksFilterProvider(),
    courses: useCoursesFilterProvider()
  }
}
  /**
   * 
   * @param {*} param0 
   * @returns 
   */
  const FiltersProvider = ({ children }) => {
    const filters = useFiltersProvider();
    return (
      <FiltersContext.Provider value={filters}>
        {children}
      </FiltersContext.Provider>
    );
  }
  export {
    FiltersProvider,
    useFilters
  }
