import React from 'react';
import { settings } from 'AppConfig';
export const FiltersContext = React.createContext();
export const MailContext = React.createContext({ mails: [] });
export const ChatContext = React.createContext();
export const TaskKanbanContext = React.createContext({
	taskColumns: [],
	tasks: []
});
export const AuthContext = React.createContext();
export const AppConfigContext = React.createContext(settings);