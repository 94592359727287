export const settings = {
    app: {
        name: "Bamboo",
        version: "1.0.0"
    }, 
    theme:{
        skin: "light" // possible values are light, dark
    }
};
export default { settings };
