// import node module libraries
import React, { Fragment } from 'react';
import {
	Col,
	Row,
	Container,
	Nav,
	Card,
	Tab,
	Image
} from 'react-bootstrap';
import { Link, useHistory, useParams } from 'react-router-dom';
import { useEffect } from 'react';

// tippy tooltip
import 'tippy.js/animations/scale.css';

// import sub components
import BookCard from '../BookCard';
import BookLoader from './BookLoader';
import BookToc from '../BookToc';

// import sub components tabs
import DescriptionTab from './DescriptionTab';

// import api rest
import { getBook, getRelatedBooks } from 'services/api/KumajiroApi';

import LazyImage from 'components/elements/images/LazyImage';

// import utils
import { bookParamToIcon } from 'services/books/utils';
import MarkdownToHtml from 'components/elements/markdown-html/MarkdownToHtml';

const BookSynopsis = () => {
	const { bookId } = useParams();

    const [book, setBook] = React.useState(null)

	const [relatedBooks, setRelatedBooks] = React.useState([])

	const history = useHistory();

	useEffect(() => {
		if (book?.id !== bookId) {
			setBook(null)
		}
        getBook(bookId)
          .then(res => {
			setBook(res.data)
          })
          .catch((error) => {
            if (error.response?.status === 404) {
				history.replace("/404-error", { replace: true });
			} else {
				history.replace("/500-error", { replace: true });
			}
          });
    }, [bookId, history])

	useEffect(() => {
		if (book?.id !== bookId) {
			setRelatedBooks([])
		}
        getRelatedBooks(bookId)
          .then(res => {
			setRelatedBooks(res.data)
          })
          .catch((error) => {
            setRelatedBooks([])
          });
    }, [bookId])

	if (!book) {
		return (
			<BookLoader/>
		)
	}

	return (
		<Fragment>
			{/* Page header */}
			<div className="pt-lg-8 pb-lg-16 pt-8 pb-12 bg-primary">
				<Container>
					<Row className="align-items-center">
						<Col xl={7} lg={7} md={12} sm={12}>
							<div>
								<h1 className="text-white display-4 fw-semi-bold">
									{book.info.editionName}
								</h1>
								<p className="text-white mb-6 lead">
									
								</p>
								<div className="d-flex align-items-center">
									{book.params
										.map((param) => {
											return (
												<span className="text-white ms-3" key={param.name}>
													<i className={`fe ${bookParamToIcon(param.code)} text-white-50`}></i> {param.value}{' '}
												</span>
											);
										})}
								</div>
							</div>
						</Col>
					</Row>
				</Container>
			</div>
			{/* Page content */}
			<div className="pb-10">
				<Container>
					<Row>
						<Col lg={8} md={12} sm={12} className="mt-n8 mb-4 mb-lg-0">
							<Tab.Container defaultActiveKey="contents">
								<Card>
									<Nav className="nav-lb-tab">
										{[
											'Contents',
											'Description'											
										].map((item, index) => (
											<Nav.Item key={index}>
												<Nav.Link
													href={`#${item.toLowerCase()}`}
													eventKey={item.toLowerCase()}
													className="mb-sm-3 mb-md-0"
												>
													{item}
												</Nav.Link>
											</Nav.Item>
										))}
									</Nav>
									<Card.Body className="p-0">
										<Tab.Content>
											<Tab.Pane eventKey="contents" className="pb-4 pt-3 px-4">
												{/* Course Index Accordion */}
												<BookToc
													book={book}
													itemClass="px-0"
												/>
											</Tab.Pane>
											<Tab.Pane eventKey="description" className="pb-4 p-4">
												{/* Description */}
												<DescriptionTab
													book={book}/>
											</Tab.Pane>											
										</Tab.Content>
									</Card.Body>
								</Card>
							</Tab.Container>
						</Col>
						<Col lg={4} md={12} sm={12} className="mt-lg-n22">
							{/* Card */}
							<Card className="mb-3 mb-4">
								<div className='text-center'>
									<LazyImage
										className="img-fluid rounded"
										src={book.info.cover}
										cachable={true}/>
								</div>								
								{/* Card body */}
								<Card.Body>
									{/* Action button */}	
									<div className="d-grid">
										{book.nextChapter &&
											<Link to={`/library/${book.code}/${book.nextChapter.code}`} className="btn btn-primary mb-2  ">
												Keep Reading: {book.nextChapter.name}
											</Link>
										}
										{!book.nextChapter &&
											<Link to={`/library/${book.code}/${book.sections[0].chapters[0].code}`} className="btn btn-primary mb-2  ">
												Start Reading
											</Link>
										}																			
									</div>
								</Card.Body>
							</Card>
							{/* Card */}
							<Card>
								{/* Card body */}
								<Card.Body>
									<div className="d-flex align-items-center">
										<div className="position-relative">
											<Image
												src={book.editor.avatar}
												alt=""
												className="rounded-circle avatar-xl"
											/>											
										</div>
										<div className="ms-4">
											<h4 className="mb-0">{book.info.author}</h4>
											<p className="mb-1 fs-6">{book.editor.name}</p>											
										</div>
									</div>									
									<div className='mt-5'>
										<MarkdownToHtml 
											content={book.editor.description}
											cachable={true}/>
									</div>									
								</Card.Body>
							</Card>
						</Col>
					</Row>
					{/* Card */}
					{relatedBooks.length > 0 && 
						<div className="pt-12 pb-3">
							<Row className="d-md-flex align-items-center mb-4">
								<Col lg={12} md={12} sm={12}>
									<h2 className="mb-0">Related books</h2>
								</Col>
							</Row>
							<Row>
								{relatedBooks
									.map((item, index) => (
										<Col lg={3} md={6} sm={12} key={index}>
											<BookCard item={item} cachable={true} />
										</Col>
									))}
							</Row>
						</div>				
					}
				</Container>
			</div>	
		</Fragment>
	);
};

export default BookSynopsis;
