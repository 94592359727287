// import node module libraries
import React, { useContext, Fragment, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
	Accordion,
	useAccordionButton,
	AccordionContext,
	ListGroup,
	ProgressBar
} from 'react-bootstrap';

// import MDI icons
import Icon from '@mdi/react';
import { mdiPlay } from '@mdi/js';

// import utils
import { getHHMMSS } from 'helper/utils';

const CourseCollectionAccordionProgress = ({ course, refreshTime, onItemClicked }) => {
	const [item, setItem] = useState(course)
	const [lastRefreshTime, setLastRefreshTime] = useState(refreshTime)

	useEffect(() => {
		setItem(course)
		setLastRefreshTime(refreshTime)
	}, [course, refreshTime])

	const ContextAwareToggle = ({ children, eventKey, callback }) => {
		const currentEventKey = useContext(AccordionContext);
		const decoratedOnClick = useAccordionButton(
			eventKey,
			() => callback && callback(eventKey)
		);
		const isCurrentEventKey = currentEventKey === eventKey;		
		return (
			<Fragment>
				<Link
					to="#"
					onClick={decoratedOnClick}
					aria-expanded={isCurrentEventKey}
					className="h4 mb-0 d-flex align-items-center text-inherit text-decoration-none py-3 px-4 collapsed "
					data-bs-toggle="collapse"
					role="button"
					aria-controls="courseTwo"
				>
					<div className={`me-auto ${isCurrentEventKey ? 'text-primary' : ''}`}>
						{children.name}
						<p className="mb-0 text-muted fs-6 mt-1 fw-normal">
							{children.collection?.length ?? 1} videos ({getHHMMSS(children.duration, true)})
						</p>
					</div>
					<span className="chevron-arrow ms-4">
						<i className="fe fe-chevron-down fs-4"></i>
					</span>
				</Link>
			</Fragment>
		);
	};

	return (
		<Accordion defaultActiveKey={item.id} className="card">
			<ListGroup as="ul" variant="flush">
				{item.collection?.length > 0 &&
					<ListGroup.Item key={item.id} as="li" className="p-0">
						<ContextAwareToggle eventKey={item.id}>
							{item}
						</ContextAwareToggle>
						<Accordion.Collapse eventKey={item.id}>
							<ListGroup variant="flush">
								<ListGroup.Item className="border-top-0">
									<ProgressBar
										variant="success"
										className="mb-2 progress"
										now={item.progress}
										style={{ height: '6px' }}
									/>
									<small>{item.progress ?? 0}% Completed</small>
								</ListGroup.Item>
								{item.collection?.map((subitem, index) => (
									<ListGroup.Item
										key={index}
										active={subitem.status === 'continue'}
										disabled={subitem.locked}
									>
										<Link
											to="#"
											className={`d-flex justify-content-between align-items-center text-${
												subitem.status === 'continue' ? 'white' : 'inherit'
											} text-decoration-none`}
											onClick={e => onItemClicked(subitem)}
										>
											<div className="text-truncate ">
												<span
													className={`icon-shape bg-${
														subitem.status === 'continue' || subitem.locked
															? 'light'
															: subitem.status === 'finished'
															? 'success'
															: 'light'
													} text-${
														subitem.locked
															? 'muted'
															: subitem.status === 'continue' ||
																subitem.status === 'pending'
															? 'primary'
															: 'white'
													} icon-sm rounded-circle me-2`}
												>
													{subitem.locked ? (
														<i className="fe fe-lock fs-4"></i>
													) : (
														<Icon path={mdiPlay} size={0.8} />
													)}{' '}
												</span>
												<span className="fs-5">{subitem.name}</span>
											</div>
											<div className="text-truncate fs-5">
												<span>{getHHMMSS(subitem.duration, true)}</span>
											</div>
										</Link>
									</ListGroup.Item>
								))}
							</ListGroup>
						</Accordion.Collapse>
					</ListGroup.Item>
				}
				{(item.collection?.length ?? 0) === 0 &&
					<ListGroup.Item key={item.id} as="li" className="p-0">
						<ContextAwareToggle eventKey={item.id}>
							{item}
						</ContextAwareToggle>
						<Accordion.Collapse eventKey={item.id}>
							<ListGroup variant="flush">
								<ListGroup.Item className="fs-5  rounded-3">
									{item.summary}
								</ListGroup.Item>
							</ListGroup>
						</Accordion.Collapse>
					</ListGroup.Item>
				}
			</ListGroup>
		</Accordion>
	);
};

export default CourseCollectionAccordionProgress;
