// import node module libraries
import { Fragment, useContext, useState, useEffect } from 'react'
import { Form, Image } from 'react-bootstrap'
import { Link } from 'react-router-dom';

// import context file
import { AppConfigContext } from 'context/Context';

// import media file
import LightModeIcon from 'assets/images/svg/sun.svg';
import DarkModeIcon from 'assets/images/svg/moon.svg';

const DarkLightMode = ({ className }) => {
    const ConfigContext = useContext(AppConfigContext);
    const [skin, setSkin] = useState(localStorage.getItem('skin'));
    useEffect(() => {
        localStorage.setItem('skin', skin);
        document.querySelector('html').setAttribute('data-theme', localStorage.getItem('skin'));
    }, [ConfigContext]);

    const changeColorMode = () => {
        setSkin(skin === 'light' ? 'dark' : 'light');
        ConfigContext.appStateDispatch({
            type: 'CHANGE_SKIN',
            payload: {
                skin: skin
            }
        })
    }
    return (
        <Fragment>
            <Link to="#" type="checkbox" id="flexSwitchCheckDefault" onClick={changeColorMode}
                className={`form-check form-switch theme-switch btn btn-light btn-icon rounded-circle ${className}`}>
                <Form.Check.Input type="checkbox" isValid value={skin} style={{ display: 'none' }} />
                <Form.Check.Label style={{ cursor: 'pointer' }}><Image src={skin === 'dark' ? DarkModeIcon : LightModeIcon} /></Form.Check.Label>
            </Link>
        </Fragment>
    )
}

export default DarkLightMode