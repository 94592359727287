// import node module libraries
import { useState, useEffect, useRef } from 'react';
import { Form, Card } from 'react-bootstrap';

// import hooks
import { useFilters } from 'hooks/useFilters';

// import api rest
import { getCategories, getSubCategories } from 'services/api/KumajiroApi';

const FilterOptions = () => {
	const filters = useFilters()

	const [categories, setCategories] = useState([])
	const [subCategories, setSubCategories] = useState([])
	const [query, setQuery] = useState(filters.courses.query)

	const handleSelectedContentType = (event) => {	
		const id = event.target.id
		const selection = filters.courses.contentTypes
		let newSelection = selection
		if (selection.includes(id)) {
			newSelection = selection.filter(item => item !== id)			
		} else {
			newSelection = newSelection.concat([id])
		}
		filters.courses.setContentTypes(newSelection)
		filters.courses.setPage(0)
	};

	const handleSelectedCategoriesChange = (event) => {	
		const id = event.target.id
		const selectedCategories = filters.courses.categories
		let newSelection = selectedCategories
		if (selectedCategories.includes(id)) {
			newSelection = selectedCategories.filter(item => item !== id)			
		} else {
			newSelection = newSelection.concat([id])
		}
		filters.courses.setCategories(newSelection)
		filters.courses.setPage(0)
	};

	const handleSelectedSubCategoriesChange = (event) => {	
		const id = event.target.id
		const selectedSubCategories = filters.courses.subCategories
		let newSelection = selectedSubCategories
		if (selectedSubCategories.includes(id)) {
			newSelection = selectedSubCategories.filter(item => item !== id)			
		} else {
			newSelection = newSelection.concat([id])
		}
		filters.courses.setSubCategories(newSelection)
		filters.courses.setPage(0)
	};

	const handleSelectedDifficultiesChange = (event) => {	
		const id = event.target.id.toLowerCase()
		const selectedDifficulties = filters.courses.difficulties
		let newSelection = selectedDifficulties
		if (selectedDifficulties.includes(id)) {
			newSelection = selectedDifficulties.filter(item => item !== id)			
		} else {
			newSelection = newSelection.concat([id])
		}
		filters.courses.setDifficulties(newSelection)		
		filters.courses.setPage(0)
	};
	
	const handleQueryChange = (event) => {	
		const query = event.target.value
		setQuery(query)
	};

	const handleQuerySubmit = (event) => {
		event.preventDefault();
		filters.courses.setQuery(query)
		filters.courses.setPage(0)
	}

	useEffect(() => {
		getCategories()
		  .then(res => {
			if (res.status === 204) {
				setCategories([])
			} else {
				setCategories(res.data)
			}
		  })
		  .catch((error) => {
			setCategories([])
		  });
	}, [])

	useEffect(() => {
		getSubCategories()
		  .then(res => {
			if (res.status === 204) {
				setSubCategories([])
			} else {
				setSubCategories(res.data)
			}
		  })
		  .catch((error) => {
			setSubCategories([])
		  });
	}, [])

	const queryEffectMounted = useRef(true);
	useEffect(() => {
		queryEffectMounted.current = true
		if (queryEffectMounted.current) {
			setQuery(filters.courses.query)
		}
		return () => {
			queryEffectMounted.current = false;
		}
	}, [filters.courses.query])

	const contentTypes = [
		{id: "collection", name: "Video course"},
		{id: "screencast", name: "Video"},
		{id: "article", name: "Article"}
	]

	return (
		<Card>
			{/* Card header */}
			<Card.Header>
				<h4 className="mb-0">Filter</h4>
			</Card.Header>
			{/* Card body */}
			<Card.Body>
				{/* Search Form */}
				<Form className="mt-3 mt-lg-0 d-flex align-items-center" onSubmit={handleQuerySubmit}>
					<span className="position-absolute ps-3 search-icon">
						<i className="fe fe-search"></i>
					</span>
					<Form.Control
						type="Search"
						id="formSearch"
						className="ps-6"
						placeholder="Search"
						value={query}
						onChange={handleQueryChange}
					/>
				</Form>
			</Card.Body>
			<Card.Body className='border-top'>
				<span className="dropdown-header px-0 mb-2"> Content-Type</span>
				<Form>
				{contentTypes.map((item, index) => (
					<Form.Check
						type="checkbox"
						className="mb-1"
						label={item.name}
						key={item.id}
						onChange={handleSelectedContentType}
						checked={filters.courses.contentTypes.includes(item.id)}
						id={item.id}
					/>
				))}
				</Form>
			</Card.Body>
			<Card.Body className='border-top'>
				<span className="dropdown-header px-0 mb-2"> Category</span>
				<Form>
				{categories.map((item, index) => (
					<Form.Check
						type="checkbox"
						className="mb-1"
						label={item.name}
						key={item.id}
						onChange={handleSelectedCategoriesChange}
						checked={filters.courses.categories.includes(item.id)}
						id={item.id}
					/>
				))}
				</Form>
			</Card.Body>
			<Card.Body className='border-top'>
				<span className="dropdown-header px-0 mb-2"> Sub-Category</span>
				<Form>
				{subCategories.map((item, index) => (
					<Form.Check
						type="checkbox"
						className="mb-1"
						label={item.name}
						key={item.id}
						onChange={handleSelectedSubCategoriesChange}
						checked={filters.courses.subCategories.includes(item.id)}
						id={item.id}
					/>
				))}
				</Form>
			</Card.Body>			
			<Card.Body className="border-top">
				<span className="dropdown-header px-0 mb-2"> Skill Level</span>
				<Form>
					{/* Checkboxes for Level */}
					{['Beginner', 'Intermediate', 'Advance'].map(
						(item, index) => (
							<Form.Check
								type="checkbox"
								className="mb-1"
								label={item}
								key={index}
								onChange={handleSelectedDifficultiesChange}
								checked={filters.courses.difficulties.includes(item.toLowerCase())}
								id={item}
							/>
						)
					)}
				</Form>
			</Card.Body>
		</Card>
	);
};
export default FilterOptions;
