// import node module libraries
import React, { useState, useEffect, Fragment } from 'react';
import { useHistory, useParams } from 'react-router-dom';

// subcomponents
import { Col, Row, Container, Card, Form, Button, Spinner, Breadcrumb } from 'react-bootstrap';
import MDEditor from '@uiw/react-md-editor';

// rest api
import { getBook, updateBook } from 'services/api/KumajiroApi';

// to display success / error messages
import Notify from 'services/Notify';

const BookSectionEditor = () => {
	const INITIAL_STATE = {
        sectionTitle: '',
        sectionSummary: '',        
        sectionRank: ''
    };

    const { bookId, version, sectionId } = useParams();

    const history = useHistory()

    const [book, setBook] = useState();
    const [fullScreenLoading, setFullScreenLoading] = useState(false);
    const [loading, setLoading] = useState(false);
	const [formData, setFormData] = useState(INITIAL_STATE);
    
	const handleChange = (event) => {  
		setFormData({
			...formData,
			[event.target.name]: event.target.value
		});
	};    
    const handleSummaryChange = (value) => {  
		setFormData({
			...formData,
			'sectionSummary': value
		});
	};     

    const goBack = () => {
        history.push(`/admin/library/list/${bookId}/${version}`)
    };

    useEffect(() => {            
        setFullScreenLoading(true)
        getBook(bookId, version)
            .then((response) => {
                setFullScreenLoading(false)
                
                const book = response.data 
                setBook(book)

                const section = book.sections.filter((section) => section.id === sectionId)[0]
                if (section) {
                    setFormData({
                        sectionTitle: section.name ?? '',
                        sectionSummary: section.summary ?? '',
                        sectionRank: section.rank ?? ''
                    })
                } else if (sectionId) {
                    Notify.error("Section not found")
                    goBack()
                }
            })
            .catch((error) => {
                setFullScreenLoading(false)
                Notify.error(error.response?.data?.message ?? "An error occured")
                if (error.response?.status === 404) {
                    goBack()
                }
            });          
    }, [bookId, version, sectionId])

    const handleSubmit = (e) => {
        e.preventDefault()

        setLoading(true)

        const data = {
            id: bookId,
            version: version,
            sections: [
                {
                    id: sectionId,
                    name: formData.sectionTitle,
                    summary: formData.sectionSummary,
                    rank: formData.sectionRank
                }
            ]            
        };

        updateBook(bookId, data)
            .then((response) => {
                setLoading(false)
                Notify.success(sectionId ? "Section updated" : "New section created")
                goBack()
            })
            .catch((error) => {
                setLoading(false)
                Notify.error(error.response?.data?.message ?? "An error occured")
                if (error.response?.status === 404) {
                    goBack()
                }
            });
    };

    const Header = ({title, subtitle}) =>
        <div className="py-4 py-lg-6 bg-primary">
            <Container>
                <Row>
                    <Col lg={{ span: 10, offset: 1 }} md={12} sm={12}>
                        <div className="d-lg-flex align-items-center justify-content-between">
                            <div className="mb-4 mb-lg-0">
                                <h1 className="text-white mb-1">{title}</h1>
                                <p className="mb-0 text-white lead">{subtitle}</p>                                    
                            </div>		
                            <div>
                                {!loading && 
                                    <Button variant="success" type="submit" form="book-section">
                                        Save
                                    </Button>
                                }
                                { loading && 
                                    <Button variant="success" type="submit" form="book-section" disabled>
                                        <Spinner
                                            as="span"
                                            animation="grow"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"/>
                                            {' '} Loading...
                                    </Button>
                                }
                            </div>						
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>

    if (fullScreenLoading === true) {
        return (
            <div className="d-flex align-items-center justify-content-center" style={{height: "40vh"}}>
                <Spinner animation="border" variant="primary" />
            </div>
        );
    }

	return (
		<Fragment>            
            <Fragment>
                <Breadcrumb>
                    <Breadcrumb.Item href="/admin">Dashboard</Breadcrumb.Item>
                    <Breadcrumb.Item href="/admin/library">Books</Breadcrumb.Item>
                    <Breadcrumb.Item href={`/admin/library/list/${bookId}`}>
                        {book?.info?.editionName ?? ''}
                    </Breadcrumb.Item>                    
                    <Breadcrumb.Item href={`/admin/library/list/${bookId}/${version}`}>
                        {book?.params?.filter((item) => item.code === 'VERSION')[0]?.value}
                    </Breadcrumb.Item>        
                    <Breadcrumb.Item href="#" active>
                        {sectionId ? formData.sectionTitle : "New Section"}
                    </Breadcrumb.Item>
                </Breadcrumb>
                <Header
                    title={book?.info?.editionName ?? ''}
                    subtitle={formData.sectionTitle}/>
            </Fragment>        
            {/* Card */}
            <Card className="mb-3 ">                
                {/* Card body */}
                <Card.Body>
                    <Form id="book-section" onSubmit={handleSubmit}>
                        {/* Rank  */}
                        <Form.Group className="mb-3">
                            <Form.Label htmlFor="sectionRank">Section Rank</Form.Label>
                            <Form.Control
                                type="number"
                                placeholder="Chapter Rank"
                                id="sectionRank"
                                name="sectionRank"
                                value={formData.sectionRank}
                                onChange={handleChange}
                                required
                            />
                            <Form.Text className="text-muted">
                                Rank used to sort sections in ascending order
                            </Form.Text>
                        </Form.Group>                    

                        {/* Title  */}
                        <Form.Group className="mb-3">
                            <Form.Label htmlFor="sectionTitle">Section Title</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Section Title"
                                id="sectionTitle"
                                name="sectionTitle"
                                value={formData.sectionTitle}
                                onChange={handleChange}
                                required
                            />
                            <Form.Text className="text-muted">
                                Write a 60 character section title.
                            </Form.Text>
                        </Form.Group>

                        {/* Summary  */}
                        <Form.Group className="mb-3" data-color-mode="light">
                            <Form.Label htmlFor="sectionSummary">Section Summary</Form.Label>
                            <MDEditor
                                type="text"
                                placeholder="Section Summary"
                                id="sectionSummary"
                                name="sectionSummary"
                                value={formData.sectionSummary}
                                onChange={handleSummaryChange}
                            />                                            
                        </Form.Group>
                    </Form>
                </Card.Body>
            </Card>
            {/* Button */}
            {!loading && 
                <Button variant="primary" type="submit" form="book-section">
                    Save
                </Button>
            }
            { loading && 
                <Button variant="primary" type="submit" form="book-section" disabled>
                    <Spinner
                        as="span"
                        animation="grow"
                        size="sm"
                        role="status"
                        aria-hidden="true"/>
                        {' '} Loading...
                </Button>
            }            
		</Fragment>
	);
};

export default BookSectionEditor;
