// import node module libraries
import { Fragment, useState, useEffect } from 'react';
import { Col, Row, Breadcrumb, Card, Form, Button, Image, Spinner } from 'react-bootstrap';

// import icons
import RwLogoImage from 'assets/images/avatar/avatar-rw.png';

// import Rest api
import { createRwSso, getRwSso, updateRwSso } from 'services/api/KumajiroApi';

// to display success / error messages
import Notify from 'services/Notify';

const SsoSettings = () => {
    const RW_LOGIN_SETTINGS_INITIAL_STATE = {
        clientApiKey: '',
        appToken: '',
		ssoUrl: '',
        consumerUrl: '',
        source: '',
        clientName: '',
        clientVersion: '',
        host: '',
        userAgent: ''
    };

    const RW_EXTRACTOR_SETTINGS_INITIAL_STATE = {
        carolusSession: '',
        appSession: '',
		appSession2: ''
    };

    const [loading, setLoading] = useState(false);
	const [id, setId] = useState()
    const [loginSettings, setLoginSettings] = useState(RW_LOGIN_SETTINGS_INITIAL_STATE);
    const [extractorSettings, setExtractorSettings] = useState(RW_EXTRACTOR_SETTINGS_INITIAL_STATE);

	const getParamValue = (params, code) => params.filter((param) => param.code === code).map((param) => param.value)[0] ?? ''
    
    useEffect(() => {
        setLoading(true)
        getRwSso()
            .then((response) => {
                setLoading(false)
				const data = response.data
				setId(data.id)
				setLoginSettings({				
					clientApiKey: getParamValue(data.params, 'clientApiKey'),
					appToken: getParamValue(data.params, 'appToken'),
					ssoUrl: getParamValue(data.params, 'ssoUrl'),
					consumerUrl: getParamValue(data.params, 'consumerUrl'),
					source: getParamValue(data.params, 'source'),
					clientName: getParamValue(data.params, 'clientName'),
					clientVersion: getParamValue(data.params, 'clientVersion'),
					host: getParamValue(data.params, 'host'),
					userAgent: getParamValue(data.params, 'userAgent')
				})
				setExtractorSettings({
					carolusSession: getParamValue(data.params, 'carolusSession'),
					appSession: getParamValue(data.params, 'appSession'),
					appSession2: getParamValue(data.params, 'appSession2')
				})
            })
            .catch((error) => {				
                setLoading(false)
                if (error.response?.status !== 404) {
                    Notify.error(error.response?.data?.message ?? "Failed to get data")
                }
            });
    }, []);

    const handleLoginSettingsFieldChange = (event) => {
		setLoginSettings({
		  ...loginSettings,
		  [event.target.id]: event.target.value,
		});
	};

    const handleExtractorSettingsFieldChange = (event) => {
		setExtractorSettings({
		  ...extractorSettings,
		  [event.target.id]: event.target.value,
		});
	};

    const handleLoginSettingsSubmit = (e) => {
        e.preventDefault();		
		saveRwSso(id, loginSettings)
    };

    const handleExtractorSettingsSubmit = (e) => {
        e.preventDefault();
		saveRwSso(id, extractorSettings)
    }

	const saveRwSso = (id, data) => {						
		let promise
		if (id) {						
			promise = updateRwSso(id, data)
		} else {
			promise = createRwSso(data)
		}
		setLoading(true)
		promise
			.then((response) => {
				setLoading(false)
				const data = response.data
				setId(data.id)
			})
			.catch((error) => {
				setLoading(false)
				Notify.error(error.response?.data?.message ?? "Failed to save data")
			})
	}

	return (
		<Fragment>
			<Row>
				<Col lg={12} md={12} sm={12}>
					<div className="border-bottom pb-4 mb-4 d-md-flex align-items-center justify-content-between">
						<div className="mb-3 mb-md-0">
							<h1 className="mb-1 h2 font-weight-bold">
								SSO Settings{' '}
							</h1>
							<Breadcrumb>
								<Breadcrumb.Item href="/admin">Dashboard</Breadcrumb.Item>
								<Breadcrumb.Item href="#">Settings</Breadcrumb.Item>
								<Breadcrumb.Item active>SSO Settings</Breadcrumb.Item>
							</Breadcrumb>
						</div>
					</div>
				</Col>
			</Row>

			{/* RW Login Settings form */}
			<Row>
				<Col xl={6} lg={12} md={12}>
					<Card className="mb-4">
						<Card.Header className="d-flex align-items-center justify-content-between">
							<h4 className="mb-0">
                            <Image
                                src={RwLogoImage}
                                alt=""
                                className="avatar-xs me-2"/>
								RW
								Login Settings
							</h4>							
						</Card.Header>
						<Card.Body>
							<Form onSubmit={handleLoginSettingsSubmit}>
								<Row>
									<Col lg={12} md={12} className="mb-3">
										{/* App id */}
										<Form.Label>
											Client API Key <span className="text-danger">*</span>{' '}
										</Form.Label>
										<Form.Control
											type="text"
											id="clientApiKey"
											placeholder="xxxxxxxxxxxxxxxxxxxxxx"
                                            value={loginSettings.clientApiKey}
											onChange={handleLoginSettingsFieldChange}
											required
										/>
										<small className="text-muted">
											Used to generate the client signature
										</small>
									</Col>

									<Col lg={12} md={12} className="mb-3">
										{/* App Secret */}
										<Form.Label>
											App Token<span className="text-danger">*</span>{' '}
										</Form.Label>
										<Form.Control
											type="text"
											id="appToken"
											placeholder="xxxxxxxxxxxxxxxxxxxxxx"
                                            value={loginSettings.appToken}
											onChange={handleLoginSettingsFieldChange}
											required
										/>										
									</Col>

									<Col lg={12} md={12} className="mb-3">
										{/* App Login Redirect URL */}
										<Form.Label>
											Login URL<span className="text-danger">*</span>{' '}
										</Form.Label>
										<Form.Control
											type="text"
											id="ssoUrl"
                                            value={loginSettings.ssoUrl}
											onChange={handleLoginSettingsFieldChange}
											placeholder="https://domain.com/login"
											required
										/>
									</Col>

									<Col lg={12} md={12} className="mb-3">
										{/* App Login Redirect URL */}
										<Form.Label>
											Login Redirect URL<span className="text-danger">*</span>{' '}
										</Form.Label>
										<Form.Control
											type="text"
											id="consumerUrl"
                                            value={loginSettings.consumerUrl}
											onChange={handleLoginSettingsFieldChange}
											placeholder="https://domain.com/consumer"
											required
										/>
									</Col>

                                    <Col lg={12} md={12} className="mb-3">
										{/* App Secret */}
										<Form.Label>
											Source<span className="text-danger">*</span>{' '}
										</Form.Label>
										<Form.Control
											type="text"
											id="source"
                                            value={loginSettings.source}
											onChange={handleLoginSettingsFieldChange}
											placeholder="android/ios/web"
											required
										/>										
									</Col>

                                    <Col lg={12} md={12} className="mb-3">
										{/* App Secret */}
										<Form.Label>
											Client name<span className="text-danger">*</span>{' '}
										</Form.Label>
										<Form.Control
											type="text"
											id="clientName"
                                            value={loginSettings.clientName}
											onChange={handleLoginSettingsFieldChange}
											placeholder="App identifier"
											required
										/>										
									</Col>

                                    <Col lg={12} md={12} className="mb-3">
										{/* App Secret */}
										<Form.Label>
											Client version<span className="text-danger">*</span>{' '}
										</Form.Label>
										<Form.Control
											type="text"
											id="clientVersion"
                                            value={loginSettings.clientVersion}
											onChange={handleLoginSettingsFieldChange}
											placeholder="App version"
											required
										/>										
									</Col>

                                    <Col lg={12} md={12} className="mb-3">
										{/* App Secret */}
										<Form.Label>
											Host<span className="text-danger">*</span>{' '}
										</Form.Label>
										<Form.Control
											type="text"
											id="host"
                                            value={loginSettings.host}
											onChange={handleLoginSettingsFieldChange}
											placeholder="Host"
											required
										/>										
									</Col>

                                    <Col lg={12} md={12} className="mb-3">
										{/* App Secret */}
										<Form.Label>
											User agent<span className="text-danger">*</span>{' '}
										</Form.Label>
										<Form.Control
											type="text"
											id="userAgent"
                                            value={loginSettings.userAgent}
											onChange={handleLoginSettingsFieldChange}
											placeholder="User agent"
											required
										/>										
									</Col>
								</Row>
								<Row>
									<Col lg={12} md={12}>
										{!loading && 
											<Button variant="primary" type="submit">
												Update Settings{' '}
											</Button>
										}
										{loading && 
											<Button variant="primary" type="submit" disabled>
												<Spinner
													as="span"
													animation="grow"
													size="sm"
													role="status"
													aria-hidden="true"/>
													{' '} Loading...
											</Button>
										}										
									</Col>
								</Row>
							</Form>
						</Card.Body>
					</Card>
				</Col>
				{/* end of RW Login Settings form */}

				{/* RW Extractor Settings form */}
				<Col xl={6} lg={12} md={12}>
					<Card className="mb-4">
						<Card.Header className="d-flex align-items-center justify-content-between">
							<h4 className="mb-0">
                            <Image
                                src={RwLogoImage}
                                alt=""
                                className="avatar-xs me-2"/> RW 
								Extractor Settings
							</h4>							
						</Card.Header>
						<Card.Body>
							<Form onSubmit={handleExtractorSettingsSubmit}>
								<Row>
									<Col lg={12} md={12} className="mb-3">
										{/* key */}
										<Form.Label>
											Carolus Session{' '}
											<span className="text-danger">*</span>{' '}
										</Form.Label>
										<Form.Control
											type="text"
											id="carolusSession"
                                            value={extractorSettings.carolusSession}
											onChange={handleExtractorSettingsFieldChange}
											placeholder="xxxxxxxxxxxxxxxxxxxxxx"
											required
										/>
										<small className="text-muted">
											Get this one from Cookies (_carolus_session)
										</small>
									</Col>

									<Col lg={12} md={12} className="mb-3">
										{/* Secret key */}
										<Form.Label>
											App Session{' '}
											<span className="text-danger">*</span>{' '}
										</Form.Label>
										<Form.Control
											type="text"
											id="appSession"
                                            value={extractorSettings.appSession}
											onChange={handleExtractorSettingsFieldChange}
											placeholder="xxxxxxxxxxxxxxxxxxxxxx"
											required
										/>
                                        <small className="text-muted">
											Get this one from Cookies (kodeco-sso-v2)
										</small>
									</Col>

									<Col lg={12} md={12} className="mb-3">
										{/* Secret key */}
										<Form.Label>
											App Session 2{' '}
											<span className="text-danger">*</span>{' '}
										</Form.Label>
										<Form.Control
											type="text"
											id="appSession2"
                                            value={extractorSettings.appSession2}
											onChange={handleExtractorSettingsFieldChange}
											placeholder="xxxxxxxxxxxxxxxxxxxxxx"
											required
										/>
                                        <small className="text-muted">
											Get this one from Cookies (rw-sso-session-v2)
										</small>
									</Col>
								</Row>
								<Row>
									<Col lg={12} md={12}>
										{!loading && 
											<Button variant="primary" type="submit">
												Update Settings{' '}
											</Button>
										}
										{loading && 
											<Button variant="primary" type="submit" disabled>
												<Spinner
													as="span"
													animation="grow"
													size="sm"
													role="status"
													aria-hidden="true"/>
													{' '} Loading...
											</Button>
										}
									</Col>
								</Row>
							</Form>
						</Card.Body>
					</Card>
				</Col>
				{/* end of RW Extractor Settings form */}				
			</Row>
		</Fragment>
	);
};

export default SsoSettings;
