// import node module libraries
import { useState } from 'react';

// import icon
import Icon from '@mdi/react';
import { mdiFile, mdiCheckBold } from '@mdi/js';

// import rest api
import { updateBookUserProgress } from 'services/api/KumajiroApi';

// import custom components
import { Spinner } from 'react-bootstrap';

// for error/success toast messages
import Notify from 'services/Notify';

const BookTocProgressButton = ({item, onUpdateProgress = (chapterId, completed) => {}}) => {
    const [completed, setCompleted] = useState(item.completed)
    const [loading, setLoading] = useState(false)

    const handleClick = (event) => {
        event.preventDefault()
        setLoading(true)
        updateBookUserProgress(item.id, !completed)
            .then(res => {
                setLoading(false)
                onUpdateProgress(item.id, !completed)
                setCompleted(!completed)
                Notify.success(`Chapter marked as ${!completed ? "completed" : "uncompleted"}`)
            })
            .catch((error) => {
                setLoading(false)
                Notify.error("Failed to update the progress")
            });
    }

    if (loading) {
        return (
            <button
                disabled
                onClick={handleClick}
                className={`icon-shape bg-light text-${
                    completed ? 'primary' : 'muted'
                } icon-sm rounded-circle me-2 btn-inherit border-0 inline-block`}
            >
                <Spinner animation="grow" variant="secondary" style={{width: '1.2rem', height: '1.2rem'}} />
            </button>
        );
    } else {
        return (
            <button
                onClick={handleClick}
                className={`icon-shape bg-light text-${
                    completed ? 'primary' : 'muted'
                } icon-sm rounded-circle me-2 btn-inherit border-0`}
            >
                <Icon path={completed ? mdiCheckBold : mdiFile} size={0.8} />
            </button>
        );
    }
};

export default BookTocProgressButton;
