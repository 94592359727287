// import node module libraries
import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';

// import sub components
import CourseCard from './CourseCard';

// import rest api
import { getCourses } from 'services/api/KumajiroApi';
import { Spinner } from 'react-bootstrap';

const CourseSlider = ({ recommended, popular, newest, videoCourses, screencasts }) => {
	const [courses, setCourses] = useState()

	useEffect(() => {
		if (videoCourses) {
			getCourses(
				1,
				8,
				['collection'],
				[],
				[], 
				[], 
				'-released_at',
				''
			)
			.then(res => {
				if (res.status === 204) {
					setCourses([])
				} else {
					setCourses(res.data.data)
				}
			})
			.catch((error) => {
				setCourses([])
			});
		} else if (screencasts) {
			getCourses(
				1,
				8,
				['screencast'],
				[],
				[], 
				[], 
				'-released_at',
				''
			)
			.then(res => {
				if (res.status === 204) {
					setCourses([])
				} else {
					setCourses(res.data.data)
				}
			})
			.catch((error) => {
				setCourses([])
			});
		} else if (popular) {
			getCourses(
				1,
				8,
				[],
				[],
				[], 
				[], 
				'-popularity',
				''
			)
			.then(res => {
				if (res.status === 204) {
					setCourses([])
				} else {
					setCourses(res.data.data)
				}
			})
			.catch((error) => {
				setCourses([])
			});
		} else if (newest) {
			getCourses(
				1,
				8,
				[],
				[],
				[], 
				[], 
				'-released_at',
				''
			)
			.then(res => {
				if (res.status === 204) {
					setCourses([])
				} else {
					setCourses(res.data.data)
				}
			})
			.catch((error) => {
				setCourses([])
			});
		}
	  }, [recommended, popular, newest, videoCourses])

	const settings = {
		infinite: true,
		slidesToShow: 4,
		slidesToScroll: 1,
		responsive: [
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 4,
					slidesToScroll: 1
				}
			},
			{
				breakpoint: 768,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 1,
					initialSlide: 2
				}
			},
			{
				breakpoint: 540,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1
				}
			}
		]
	};

	return (
		<Fragment>
			{!courses &&
				<div className="d-flex align-items-center justify-content-center">
					<Spinner animation="border" variant="primary" />
				</div>
			}
			{courses &&
				<Slider {...settings} className="pb-sm-5 mb-5 slick-slider-wrapper">				
					{courses.map((item, index) => (
						<div className="item px-md-1" key={item.id}>
							<CourseCard key={index} item={item} extraclass="mx-2" />
						</div>
					))}
				</Slider>
			}
		</Fragment>
	);
};

// Specifies the default values for props
CourseSlider.defaultProps = {
	recommended: false,
	popular: false,
	newest: false,
	videoCourses: false,
	screencasts: false
};

// Typechecking With PropTypes
CourseSlider.propTypes = {
	recommended: PropTypes.bool,
	popular: PropTypes.bool,
	newest: PropTypes.bool,
	videoCourses: PropTypes.bool,
	screencasts: PropTypes.bool
};

export default CourseSlider;
