// import node module libraries
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

// import custom components
import StatRightIcon from 'components/dashboard/common/stats/StatRightIcon';

// import sub components
import RecentCourses from './RecentCourses';
import RecentBooks from './RecentBooks';

// rest api
import { getAdminOverview } from 'services/api/KumajiroApi';

// to display success/error messages
import Notify from 'services/Notify';

const Overview = () => {
	const history = useHistory()

	const [data, setData] = useState(null)

	useEffect(() => {
		getAdminOverview()
			.then((data) => {
				setData(data.data)
			})
			.catch(error => {
				Notify.error(error.response?.data?.message ?? "An error occured")
				if (error.response?.status === 403) {
					history.push(`/`)
				} else {
					setData({
						books: [],
						courses: [],
						videos: []
					});
				}
			});
	}, [history])

	return (
		<div>
			<Row>
				<Col lg={12} md={12} sm={12}>
					<div className="border-bottom pb-4 mb-4 d-lg-flex justify-content-between align-items-center">
						<div className="mb-3 mb-lg-0">
							<h1 className="mb-0 h2 fw-bold">Dashboard</h1>
						</div>						
					</div>
				</Col>
			</Row>
			<Row>
				<Col xl={3} lg={6} md={12} sm={12}>
					<StatRightIcon
						title="BOOKS"
						value={data?.stats?.totalBooks ?? '-'}
						iconName="book-open"
						iconColorVariant="primary"
						classValue="mb-4"
					/>
				</Col>

				<Col xl={3} lg={6} md={12} sm={12}>
					<StatRightIcon
						title="COURSES"
						value={data?.stats?.totalCourses ?? '-'}
						iconName="book"
						iconColorVariant="primary"
						classValue="mb-4"
					/>
				</Col>

				<Col xl={3} lg={6} md={12} sm={12}>
					<StatRightIcon
						title="VIDEOS"
						value={data?.stats?.totalVideos ?? '-'}
						iconName="play"
						iconColorVariant="primary"
						classValue="mb-4"
					/>
				</Col>

				<Col xl={3} lg={6} md={12} sm={12}>
					<StatRightIcon
						title="STUDENTS"
						value={data?.stats?.totalUsers ?? '-'}
						iconName="users"
						iconColorVariant="primary"
						classValue="mb-4"
					/>
				</Col>				
			</Row>			

			<Row>
				<Col xl={4} lg={6} md={12} className="mb-4">
					<RecentBooks title="Latest Books" items={data?.books} />
				</Col>
				<Col xl={4} lg={6} md={12} className="mb-4">
					<RecentCourses title="Latest Courses" items={data?.courses} />
				</Col>
				<Col xl={4} lg={6} md={12} className="mb-4">
					<RecentCourses title="Latest Videos" items={data?.videos} />
				</Col>
			</Row>
		</div>
	);
};

export default Overview;
