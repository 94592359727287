import { useState, useEffect } from 'react';

// import node module libraries
import { Form, Card } from 'react-bootstrap';

// import hooks
import { useFilters } from 'hooks/useFilters';

// import api rest
import { getCategories } from 'services/api/KumajiroApi';

const FilterOptions = () => {
	const filters = useFilters()

	const [categories, setCategories] = useState([])
	const [query, setQuery] = useState(filters.books.query)

	const handleSelectionChange = (event) => {	
		const id = event.target.id
		const selection = filters.books.categories
		let newSelection = selection
		if (selection.includes(id)) {
			newSelection = selection.filter(item => item !== id)			
		} else {
			newSelection = newSelection.concat([id])
		}
		filters.books.setCategories(newSelection)
		filters.books.setPage(0)
	  };
	
	const handleQueryChange = (event) => {	
		const query = event.target.value
		setQuery(query)		
	};

	const handleQuerySubmit = (event) => {
		event.preventDefault();
		filters.books.setQuery(query)
		filters.books.setPage(0)
	}

	useEffect(() => {
		getCategories()
		  .then(res => {
			if (res.status === 204) {
				setCategories([])
			} else {
				setCategories(res.data)
			}
		  })
		  .catch((error) => {
			setCategories([])
		  });
	  }, [])

	return (
		<Card>
			{/* Card header */}
			<Card.Header>
				<h4 className="mb-0">Filter</h4>
			</Card.Header>
			{/* Card body */}
			<Card.Body>
				{/* Search Form */}
				<Form className="mt-3 mt-lg-0 d-flex align-items-center" onSubmit={handleQuerySubmit}>
					<span className="position-absolute ps-3 search-icon">
						<i className="fe fe-search"></i>
					</span>
					<Form.Control
						type="Search"
						id="formSearch"
						className="ps-6"
						placeholder="Search"
						value={query}
						onChange={handleQueryChange}
					/>
				</Form>
			</Card.Body>
			<Card.Body className='border-top'>
				<span className="dropdown-header px-0 mb-2"> Category</span>
				<Form>
					{/* Checkboxes for Courses */}
					{categories.map((item, index) => (
						<Form.Check
							type="checkbox"
							className="mb-1"
							label={item.name}
							key={item.id}
							onChange={handleSelectionChange}
							checked={filters.books.categories.includes(item.id)}
							id={item.id}
						/>
					))}
				</Form>
			</Card.Body>		
		</Card>
	);
};
export default FilterOptions;
