// import node module libraries
import React, { useState, useEffect } from 'react';
import {
	Col,
	Row,
	Form,
	Card,
	OverlayTrigger,
	Tooltip,
	Button,
	Spinner,
	Image
} from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';

// import custom components
import PasswordStrengthMeter from 'components/elements/passwordstrength/PasswordStrengthMeter';
import Switch from "react-switch";

// import hooks
import { useAuth } from 'hooks/useAuth';

// to display success / error message
import Notify from 'services/Notify';

// import profile layout wrapper
import ProfileLayoutWrap from './ProfileLayoutWrap';

// import session
import UserSession from 'services/UserSession';

// import rest api
import { getOtpConfig, resetProfilePassword, updateProfileDoubleAuth, updateProfileMail, updateProfilePassword } from 'services/api/KumajiroApi';

const Security = () => {
	const location = useLocation();

	const { user } = useAuth();

	const [mail, setMail] = useState("");
	const [currentMail, setCurrentMail] = useState(user?.user?.email ?? "");
	const [loadingMail, setLoadingMail] = useState(false);

	const [password, setPassword] = useState('');
	const [confirmpassword, setConfirmPassword] = useState('');
	const [currentpassword, setCurrentPassword] = useState('');
	const [loadingPassword, setLoadingPassword] = useState(false);

	const [doubleAuthEnabled, setDoubleAuthEnabled] = useState(false);
	const [doubleAuthConfig, setDoubleAuthConfig] = useState();
	const [loadingDoubleAuth, setLoadingDoubleAuth] = useState(false);

	useEffect(() => {
		setLoadingDoubleAuth(true)
		getOtpConfig()
			.then(blob => {
				setLoadingDoubleAuth(false)
				setDoubleAuthEnabled(true)
				let file = new File([blob.data], null, { type: blob.type })
				setDoubleAuthConfig(URL.createObjectURL(file))
			})
			.catch((error) => {
				setLoadingDoubleAuth(false)				
				if (error.response?.status === 404) {
					setDoubleAuthEnabled(false)
					setDoubleAuthConfig(null)	
				} else {
					Notify.error(error.response?.data?.message ?? "An error occured")
				}
			});
	}, [doubleAuthEnabled]);

	const handleMailSubmit = (event) => {
		event.preventDefault();

		setLoadingMail(true)
		updateProfileMail(mail)
			.then(res => {
				setLoadingMail(false)
				setCurrentMail(mail)
				setMail("")
				UserSession.updateLocalMail(mail)
				Notify.success('Your mail has been updated')
			})
			.catch((error) => {
				setLoadingMail(false)
				Notify.error(error.response?.data?.message ?? "An error occured")				
			});
  	};

	const handlePasswordSubmit = (event) => {
		event.preventDefault();

		if (password !== confirmpassword) {
			Notify.error("Conformation password does not match")
			return
		}

		setLoadingPassword(true)
		updateProfilePassword(currentpassword, password)
			.then(res => {
				setLoadingPassword(false)
				setCurrentMail(mail)
				setPassword("")
				setCurrentPassword("")
				setConfirmPassword("")
				Notify.success('Your password has been updated')
			})
			.catch((error) => {
				setLoadingPassword(false)
				Notify.error(error.response?.data?.message ?? "An error occured")				
			});
  	};

	const handleDoubleAuthCheck = (checked) => {
		setLoadingDoubleAuth(true)		
		updateProfileDoubleAuth(checked)
			.then(res => {
				setLoadingDoubleAuth(false)				
				setDoubleAuthEnabled(checked)
			})
			.catch(error => {
				Notify.error(error.response?.data?.message ?? "An error occured")
				setLoadingDoubleAuth(false)
				setDoubleAuthEnabled(!checked)
			})
	};

	const resetPassword = (event) => {
		event.preventDefault();
		resetProfilePassword()
			.then(res => {				
				Notify.success('Your password has been reset. Check your mails.')
			})
			.catch((error) => {
				Notify.error(error.response?.data?.message ?? "An error occured")				
			});
	}

	return (
		<ProfileLayoutWrap pathpara={location.pathname}>
			<Card className="border-0">
				<Card.Header>
					<div className="mb-3 mb-lg-0">
						<h3 className="mb-0">Security</h3>
						<p className="mb-0">
							Edit your account settings and change your password here.
						</p>
					</div>
				</Card.Header>
				<Card.Body>
					<h4 className="mb-0">Email Address</h4>
					<p>
						Your current email address is{' '}
						<span className="text-success">{currentMail}</span>
					</p>
					<Form onSubmit={handleMailSubmit}>
						<Row>
							<Col lg={6} md={12} sm={12} className="mb-3">
								<Form.Group>
									<Form.Label htmlFor="email">New email address</Form.Label>
									<Form.Control 
										type="email" 
										id="email" 
										required 
										value={mail} 
										onChange={(e) => setMail(e.target.value)} />
								</Form.Group>
								{!loadingMail && 
									<Button variant="primary" type="submit" className="mt-2">
										Update Details
									</Button>
								}
								{ loadingMail && 
									<Button variant="primary" type="submit" className="mt-2" disabled>
										<Spinner
											as="span"
											animation="grow"
											size="sm"
											role="status"
											aria-hidden="true"/>
											{' '} Loading...
									</Button>
								}
							</Col>
						</Row>
					</Form>
					<hr className="my-5" />
					<div>
						<h4 className="mb-0">Change Password</h4>
						<p>
							We will email you a confirmation when changing your password, so
							please expect that email after submitting.
						</p>
						{/* Form */}
						<Form onSubmit={handlePasswordSubmit}>
							<Row>
								<Col lg={6} md={12} sm={12}>
									{/* Current password */}

									<Form.Group className="mb-3">
										<Form.Label htmlFor="currentpassword">
											Current Password
										</Form.Label>
										<Form.Control
											type="password"
											id="currentpassword"
											value={currentpassword}
											onChange={(e) => setCurrentPassword(e.target.value)}
											required
										/>
									</Form.Group>

									{/* New password */}
									<Form.Group className="mb-3">
										<Form.Label htmlFor="newpassword">New Password</Form.Label>
										<Form.Control
											type="password"
											id="newpassword"
											value={password}
											onChange={(e) => setPassword(e.target.value)}
											required
										/>
									</Form.Group>

									<Row className="align-items-center g-0">
										<Col sm={6}>
											<span
												data-bs-toggle="tooltip"
												data-placement="right"
												title=""
											>
												Password strength
												<OverlayTrigger
													key="top"
													placement="top"
													overlay={
														<Tooltip id="tooltip-top">
															Test it by typing a password in the field below.
															To reach full strength, use at least 6 characters,
															a capital letter and a digit, e.g. 'Test01'
														</Tooltip>
													}
												>
													<i className="fas fa-question-circle ms-1"></i>
												</OverlayTrigger>
											</span>
										</Col>
									</Row>
									<PasswordStrengthMeter password={password} />

									{/* Confirm new password */}
									<Form.Group className="mb-3">
										<Form.Label htmlFor="confirmpassword">
											Confirm New Password
										</Form.Label>
										<Form.Control
											type="password"
											id="confirmpassword"
											value={confirmpassword}
											onChange={(e) => setConfirmPassword(e.target.value)}
											required
										/>
									</Form.Group>
									{/* Button */}
									{!loadingPassword && 
										<Button variant="primary" type="submit">
											Save Password
										</Button>
									}
									{ loadingPassword && 
										<Button variant="primary" type="submit" disabled>
											<Spinner
												as="span"
												animation="grow"
												size="sm"
												role="status"
												aria-hidden="true"/>
												{' '} Loading...
										</Button>
									}									
									<div className="col-6"></div>
								</Col>
								<Col lg={12} md={12} sm={12} className="mt-4">
									<p className="mb-0">
										Can't remember your current password?{' '}
										<Link to="#" onClick={resetPassword}>Reset your password via email</Link>
									</p>
								</Col>
							</Row>
						</Form>
					</div>
					<hr className="my-5" />
					<div>
						<h4 className="mb-0">Two-Factor Authentication</h4>
						<p>
						Use Google's Two-Factor Authentication to protect your account from brute-force and man-in-the-middle hacker attacks 
						with your smartphone. Keep your credentials secure even when logging in from public or unsecured networks.
						</p>						
						{/* Form */}						
						{loadingDoubleAuth &&
							<div className="d-flex align-items-center justify-content-center">
								<Spinner animation="border" variant="primary" />								
							</div>
						}
						{!loadingDoubleAuth &&
							<>
								<div className="d-lg-flex align-items-center justify-content-between">
									<div className="d-flex align-items-center mb-4 mb-lg-0">
										<p>
											The service is{' '}
											{doubleAuthEnabled ? 
												<span className="text-success">enabled</span> :
												<span className="text-danger">disabled</span>
											}
										</p>
										<div>
											<Switch											
												onChange={handleDoubleAuthCheck} 
												checked={doubleAuthEnabled}
												className="ms-5"/>							
										</div>
									</div>
								</div>
								{doubleAuthConfig &&
									<div className="d-lg-flex align-items-center justify-content-between">
										<div className="d-flex align-items-center mb-4 mb-lg-0">
											<Image
												className='img-fluid'
												src={doubleAuthConfig}
												alt=""/>									
											<div className="ms-3">
												<p className="mb-0">
													Install the "Google Authenticator" app on your mobile and scan the QR Code to configure
												</p>
											</div>
										</div>									
									</div>
								}								
							</>
						}						
					</div>
				</Card.Body>
			</Card>
		</ProfileLayoutWrap>
	);
};

export default Security;
