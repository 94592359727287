// import node module libraries
import React from 'react';
import { Container } from 'react-bootstrap';

// import layouts
import NavbarBrandOnly from 'layouts/marketing/navbars/NavbarBrandOnly';
import Footer from './footers/Footer';

const NotFound = (props) => {

	return (
		<div id="db-wrapper" className="bg-white">
			<Container className="d-flex flex-column">
				<NavbarBrandOnly />
				{props.children}
				<Footer />
			</Container>
		</div>
	);
};

export default NotFound;
