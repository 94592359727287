// import node module libraries
import React, { Fragment, useMemo } from 'react';
import {
	useTable,
	useFilters,
	useGlobalFilter,
	usePagination
} from 'react-table';
import { Link } from 'react-router-dom';
import {
	Image,
	Row,
	Col,
	Table,
	Dropdown
} from 'react-bootstrap';

// Icons
import { MoreVertical, ShieldOff, Trash } from 'react-feather';
import { ShieldCheck } from 'react-bootstrap-icons';

// import custom components
import GlobalFilter from 'components/elements/advance-table/GlobalFilter';
import Pagination from 'components/elements/advance-table/Pagination';
import DotBadge from 'components/elements/bootstrap/DotBadge';

const StudentsListItems = ({items, onDelete = (id) => {}, onEnable = (id, enable) => {}}) => {
	const handleEnable = (id, enable) => {
		onEnable(id, enable)
	}

	const handleDelete = (id) => {
		onDelete(id)
	}

	// The forwardRef is important!!
	// Dropdown needs access to the DOM node in order to position the Menu
	const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
		<Link
			to=""
			ref={ref}
			onClick={(e) => {
				e.preventDefault();
				onClick(e);
			}}
		>
			{children}
		</Link>
	));

	const ActionMenu = ({user}) => {
		const userId = user.id
		const userIsEnabled = user.enabled
		return (
			<Dropdown>
				<Dropdown.Toggle as={CustomToggle}>
					<MoreVertical size="15px" className="text-secondary" />
				</Dropdown.Toggle>
				<Dropdown.Menu align="end">
					<Dropdown.Header>ACTION</Dropdown.Header>
					<Dropdown.Item eventKey="2" onClick={(e) => handleEnable(userId, !userIsEnabled)}>
						{' '}
						{userIsEnabled === true &&
							<>
								<ShieldOff size="18px" className="dropdown-item-icon" /> Disable
							</>
						}
						{userIsEnabled === false &&
							<>
								<ShieldCheck size="18px" className="dropdown-item-icon" /> Enable
							</>
						}						
					</Dropdown.Item>
					<Dropdown.Item eventKey="3" onClick={e => handleDelete(userId)}>
						{' '}
						<Trash size="18px" className="dropdown-item-icon" /> Delete
					</Dropdown.Item>
				</Dropdown.Menu>
			</Dropdown>
		);
	};

	const columns = useMemo(
		() => [
			{ accessor: 'id', Header: 'ID', show: false },
			{
				accessor: 'lastname',
				Header: 'NAME',
				Cell: ({ value, row }) => {
					return (
						<div className="d-flex align-items-center">
							<Image
								src={row.original.avatar}
								alt=""
								className="rounded-circle avatar-md me-2"
							/>
							<h5 className="mb-0">{(row.original.firstname + ' ' + row.original.lastname).trim()}</h5>
						</div>
					);
				}
			},
			{ accessor: 'firstname', Header: '', show: false },
			{
				accessor: 'achievements',
				Header: 'ACHIEVEMENTS',
				Cell: ({ value }) => {
					return value + ' Courses';
				}
			},
			{ 
				accessor: 'joinedAt', 
				Header: 'JOINED AT',
				Cell: ({ value, row }) => new Date(row.original.joinedAt).toLocaleDateString('en-US')			
			},	
			{
				accessor: 'status',
				Header: 'STATUS',
				Cell: ({ value, row }) => {
					return (
						<DotBadge bg={row.original.enabled === true ? 'success' : 'danger'}></DotBadge>
					);
				}
			}			
		],
		[]
	);

	const data = useMemo(() => items, [items]);

	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		page,
		nextPage,
		previousPage,
		state,
		gotoPage,
		pageCount,
		prepareRow,
		setGlobalFilter
	} = useTable(
		{
			columns,
			data,
			initialState: {
				pageSize: 10,
				hiddenColumns: columns.map((column) => {
					if (column.show === false) return column.accessor || column.id;
					else return false;
				})
			}
		},
		useFilters,
		useGlobalFilter,
		usePagination,
		(hooks) => {
			hooks.visibleColumns.push((columns) => [				
				...columns,
				{
					id: 'selection',
					Header: ({ getToggleAllRowsSelectedProps }) => (
						''
					),
					Cell: ({ row }) => <ActionMenu user={row.original} />
				},
			]);
		}
	);

	const { pageIndex, globalFilter } = state;

	return (
		<Fragment>
			<div className=" overflow-hidden">
				<Row>
					<Col lg={12} md={12} sm={12} className="mb-lg-0 mb-2 px-5 py-4">
						<GlobalFilter
							filter={globalFilter}
							setFilter={setGlobalFilter}
							placeholder="Search Students"
						/>
					</Col>
				</Row>
			</div>

			<div className="table-responsive ">
				<Table {...getTableProps()} className="text-nowrap">
					<thead className="table-light">
						{headerGroups.map((headerGroup) => (
							<tr {...headerGroup.getHeaderGroupProps()}>
								{headerGroup.headers.map((column) => (
									<th {...column.getHeaderProps()}>
										{column.render('Header')}
									</th>
								))}
							</tr>
						))}
					</thead>
					<tbody {...getTableBodyProps()}>
						{page.map((row) => {
							prepareRow(row);
							return (
								<tr {...row.getRowProps()}>
									{row.cells.map((cell) => {
										return (
											<td {...cell.getCellProps()}>{cell.render('Cell')}</td>
										);
									})}
								</tr>
							);
						})}
					</tbody>
				</Table>
			</div>

			{/* Pagination @ Footer */}
			<Pagination
				previousPage={previousPage}
				pageCount={pageCount}
				pageIndex={pageIndex}
				gotoPage={gotoPage}
				nextPage={nextPage}
			/>
		</Fragment>
	);
};

export default StudentsListItems;
