// import node module libraries
import { Link } from 'react-router-dom';
import {
	Card,	
} from 'react-bootstrap';

const BookSearchResult = ({ item }) => {
	return (
        <Card className={`mb-4 card-hover`}>            
            <Card.Body>
                <h3 className="h4 mb-2 text-truncate-line-2 ">
                    <Link to={`/library/${item.bookCode}/${item.code}`} className="text-inherit">
                        {item.number}. {item.name}
                    </Link>
                </h3>                
            </Card.Body>            				
        </Card>
    );
};

export default BookSearchResult;
