import React from "react";
import ReactMarkdown from 'react-markdown'
import {Prism as SyntaxHighlighter} from 'react-syntax-highlighter'
import {atomDark} from 'react-syntax-highlighter/dist/esm/styles/prism'
import LazyImage from 'components/elements/images/LazyImage';


const MarkdownToHtml = ({content, cachable = false}) => {
    return (        
        <ReactMarkdown
            components={{
                h1: ({node, ...props}) => {
                    return  <h1 {...props} className="mb-5">{props.children[0]}</h1>
                },              
                h2: ({node, ...props}) => {
                    return  <h2 {...props} className="mt-8 mb-3">{props.children[0]}</h2>
                },
                h3: ({node, ...props}) => {
                    return  <h3 {...props} className="mt-6 mb-3">{props.children[0]}</h3>
                }, 
                h4: ({node, ...props}) => {
                    return  <h4 {...props} className="mt-5 mb-3">{props.children[0]}</h4>
                }, 
                h5: ({node, ...props}) => {
                    return  <h5 {...props} className="mt-5 mb-3">{props.children[0]}</h5>
                }, 
                h6: ({node, ...props}) => {
                    return  <h6 {...props} className="mt-5 mb-3">{props.children[0]}</h6>
                },              
                img: ({node, ...props}) => {
                    return (
                        <figure className="figure-fluid-article my-5">
                            <picture className={props["src"].startsWith("data:image") ? "bg-svg" : ""}>                                
                                <LazyImage 
                                    {...props}
                                    role="img" 
                                    zoomable={true}
                                    effect='blur'
                                    cachable={cachable} />
                            </picture>
                            <figcaption className="mt-3 text-muted text-sm text-center">{props["alt"] || ""}</figcaption>
                        </figure>
                    )                   
                },
                blockquote: ({node, ...props}) => {
                    return  (
                        <blockquote className="card shadow-none" {...props}>
                            <div className="card-body">{props.children}</div>
                        </blockquote>
                    )
                },
                code({node, inline, className, children, ...props}) {
                    const match = /language-(\w+)/.exec(className || '')
                    return !inline && match ? (
                        <SyntaxHighlighter
                            children={String(children).replace(/\n$/, '')}
                            style={atomDark}
                            language={match[1]}
                            PreTag="div"
                            {...props} />
                    ) : (
                        <code className={className} {...props}>
                        {children}
                        </code>
                    )
                    }
            }}>
            {
                content
            }
        </ReactMarkdown>
    ); 
}

export default React.memo(MarkdownToHtml);