// import node module libraries
import React from 'react';
import { Link } from 'react-router-dom';
import { Col, Row, Card, ListGroup, Image, Spinner } from 'react-bootstrap';
import LazyImage from 'components/elements/images/LazyImage';

const RecentBooks = ({ title, items }) => {
	return (
		<Card className="h-100">
			<Card.Header className="d-flex align-items-center justify-content-between card-header-height">
				<h4 className="mb-0">{title}</h4>
				<Link to="/library" className="btn btn-outline-dark btn-sm">
					View all
				</Link>
			</Card.Header>
			<Card.Body>
                {!items &&
                    <div className="align-items-center">
                        <div className="d-flex align-items-center justify-content-center mt-5 mb-5">
                            <Spinner animation="border" variant="primary" />
                        </div>
                    </div>
                }
				<ListGroup variant="flush">                    
					{items?.map((item, index) => (
						<ListGroup.Item
							className={`px-0 ${index === 0 ? 'pt-0' : ''}`}
							key={index}
						>
							<Row>
								<Col className="col-auto">
									<Link to={`/library/${item.id}`}>
										<LazyImage
											src={item.info.thumbnail}
											cachable={true}
											alt=""
											className="img-fluid rounded img-4by3-xs"
										/>
									</Link>
								</Col>
								<Col className="ps-0">
									<Link to={`/library/${item.id}`}>
										<h5 className="text-primary-hover">{item.info.editionName}</h5>
									</Link>
									<div className="d-flex align-items-center">
										<Image
											src={item.editor.avatar}
											alt={item.editor.name}
											className="rounded-circle avatar-xs me-2"
										/>
										<span className="fs-6">{"Kodeco"}</span>
									</div>
								</Col>								
							</Row>
						</ListGroup.Item>
					))}
				</ListGroup>
			</Card.Body>
		</Card>
	);
};
export default RecentBooks;
