// import node module libraries
import { BrowserRouter as Router } from 'react-router-dom';

// import layouts
import ScrollToTop from 'layouts/dashboard/ScrollToTop';
import AllRoutes from 'layouts/AllRoutes';

// import required stylesheet
import 'simplebar/dist/simplebar.min.css';
import 'tippy.js/animations/scale.css';
import { ErrorBoundary } from 'react-error-boundary';
import { ToastContainer } from 'react-toastify';

function App() {
	return (
		<ErrorBoundary FallbackComponent={OurFallbackComponent}>
			<Router>
				<div className="App">
					<ScrollToTop />
					<AllRoutes />
					<ToastContainer />
				</div>
			</Router>
		</ErrorBoundary>
	);
}

const OurFallbackComponent = ({ error, componentStack, resetErrorBoundary }) => {
	return (
	  <div>
		<h1>An error occurred: {error.message}</h1>
		<button onClick={resetErrorBoundary}>Try again</button>
	  </div>
	);
  };

export default App;
