export const bookParamToIcon = (code) => {
    if (code === "EDITOR") {
        return "fe-edit-2"
    } else if (code === "LANGUAGE") {
        return "fe-code"
    } else if (code === "PLATFORM") {
        return "fe-terminal"
    } else if (code === "VERSION") {
        return "fe-git-branch"
    } else {
        return "fe-info"
    }
}