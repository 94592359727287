// import node module libraries
import { Fragment, useState } from 'react';
import { Menu } from 'react-feather';
import { Link, useHistory } from 'react-router-dom';
import {
	Nav,
	Navbar,
	Dropdown,
	Form,
	Image
} from 'react-bootstrap';

// import custom components
import DarkLightMode from 'layouts/DarkLightMode';

// import media files
import AvatarDefault from 'assets/images/avatar/avatar-default.png';

// session
import { useAuth } from 'hooks/useAuth';

const NavBarReaderDefault = ({showMenu, SidebarToggleMenu, bookId, initialQuery=""}) => {
    const { user, logout } = useAuth();

	const [query, setQuery] = useState(initialQuery);

	const history = useHistory();
    
    let avatarSrc = AvatarDefault
	let username = ""
	let email = ""
    if (user.user !== undefined) {
        avatarSrc = user.user.avatar
		username = (user.user.firstname + " " + user.user.lastname).trim()
		email = user.user.email
    }

	const goToSearch = (event) => {
		event.preventDefault()
		history.push(`/library/${bookId}/search?q=${query}`);
	}

	const goToProfile = (event) => {
		event.preventDefault()
		history.push(`/account/student/dashboard`);
	}

	const goToProfileSettings = (event) => {
		event.preventDefault()
		history.push(`/account/student/edit-profile`);
	}

	const handleQueryChange = (event) => {
		setQuery(event.target.value)
	}

    const handleLogout = (event) => {
        event.preventDefault()
        logout()
    }

	return (
		<Fragment>
			<Navbar expanded="lg" className="navbar-default">
				<div className="d-flex justify-content-between w-100">
					<div className="d-flex align-items-center">
						<Link
							id="nav-toggle"
							to="#"
							onClick={() => SidebarToggleMenu(!showMenu)}
						>
							<Menu size="18px" />
						</Link>
						<div className="ms-lg-3 d-none d-md-none d-lg-block">
							{/* <!-- Form --> */}
							<Form className="mt-3 mt-lg-0 ms-lg-3 d-flex align-items-center" onSubmit={goToSearch}>
								<span className="position-absolute ps-3 search-icon">
									<i className="fe fe-search"></i>
								</span>
								<Form.Control
									type="Search"
									id="formSearch"
									className="ps-6"
									placeholder="Search in book"
									value={query}
									onChange={handleQueryChange}
								/>
							</Form>							
						</div>
					</div>
									
					<Nav className="navbar-nav navbar-right-wrap ms-auto d-flex align-items-center nav-top-wrap">
						<DarkLightMode className="me-2"/>
						<Dropdown as={Nav.Item}>
							<Dropdown.Toggle
								as={Nav.Link}
								bsPrefix="dt"
								className="rounded-circle border-bottom-0"
								id="dropdownUser"
							>
								<div className="avatar avatar-md avatar-indicators avatar-online">
                                    <Image
										alt="avatar"
										src={avatarSrc}
										className="rounded-circle"
									/>
								</div>
							</Dropdown.Toggle>
							<Dropdown.Menu
								className="dashboard-dropdown dropdown-menu-end mt-4 py-0"
								aria-labelledby="dropdownUser"
								align="end"
							>
								<Dropdown.Item className="mt-3">
									<div className="d-flex" onClick={goToProfile}>
										<div className="avatar avatar-md avatar-indicators avatar-online">
											<Image
												alt="avatar"
												src={avatarSrc}
												className="rounded-circle"
											/>
										</div>
										<div className="ms-3 lh-1">
                                            <h5 className="mb-1">{username}</h5>
                                            <p className="mb-0 text-muted">{email}</p>
										</div>
									</div>
								</Dropdown.Item>
								<Dropdown.Divider />
								<Dropdown.Item eventKey="2" onClick={goToProfile}>
									<i className="fe fe-user me-2"></i> Profile
								</Dropdown.Item>
								<Dropdown.Item onClick={goToProfileSettings}>
									<i className="fe fe-settings me-2"></i> Settings
								</Dropdown.Item>
								<Dropdown.Divider />
								<Dropdown.Item className="mb-3" onClick={handleLogout}>
									<i className="fe fe-power me-2"></i> Sign Out
								</Dropdown.Item>
							</Dropdown.Menu>
						</Dropdown>
					</Nav>
				</div>
			</Navbar>
		</Fragment>
	);
};

export default NavBarReaderDefault;
