// import node module libraries
import { Fragment, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Col, Row, Card, Form, Button, Image, Spinner } from 'react-bootstrap';

// import sub-components
import DarkLightMode from 'layouts/DarkLightMode';

// import media files
import Logo from 'assets/images/brand/logo/logo.svg';

// for error/success message
import Notify from 'services/Notify';

// import rest api
import { signUp } from 'services/api/KumajiroApi';

const INITIAL_STATE = {
	firstname: '',
	lastname: '',
	email: '',
	password: '',
	cgu: false
};

const SignUp = () => {
	const [form, setForm] = useState(INITIAL_STATE);
	const [loading, setLoading] = useState(false);

	const history = useHistory();

	const handleSubmit = (event) => {
		event.preventDefault();

		const email = form.email
		const password = form.password	
		const firstname = form.firstname	
		const lastname = form.lastname

		if (form.cgu !== true) {
			Notify.error("You must accept the Terms Of Service and Privacy Policy to sign-up")
			return
		}

		setLoading(true)
		signUp(email, password, firstname, lastname)
			.then(res => {
				setLoading(false)
				setForm(INITIAL_STATE)
				Notify.success("Your account has been created")
				history.push(`/`)
			})
			.catch((error) => {
				setLoading(false)
				Notify.error(error.response?.data?.message ?? "An error occured")
				setForm(INITIAL_STATE);
			});
  	};	

	const handleChange = (event) => {
		setForm({
		  ...form,
		  [event.target.id]: event.target.value,
		});
	  };

	  const handleCheck = (event) => {
		setForm({
		  ...form,
		  [event.target.id]: event.target.checked,
		});
	  };

	return (
		<Fragment>
			<div className='position-absolute top-0 end-0 me-2 mt-2 d-none d-lg-block'>
				<DarkLightMode />
			</div>	
			<Row className="align-items-center justify-content-center g-0 min-vh-100">
				<Col lg={5} md={5} className="py-8 py-xl-0">
					<Card>
						<Card.Body className="p-6">
							<div className="mb-4">
								<Link to="/">
									<Image src={Logo} className="mb-4 brand" alt="" />
								</Link>
								<h1 className="mb-1 fw-bold">Sign up</h1>
								<span>
									Already have an account?{' '}
									<Link to="/" className="ms-1">
										Sign in
									</Link>
								</span>
							</div>
							{/* Form */}
							<Form onSubmit={handleSubmit}>
								<Row>	
									<Col lg={12} md={12} className="mb-3">
										{/* firstname */}
										<Form.Label>Firstname </Form.Label>
										<Form.Control
											type="text"
											id="firstname"
											placeholder="Your firstname"
											value={form.firstname}
											onChange={handleChange}
											required
										/>
									</Col>
									<Col lg={12} md={12} className="mb-3">
										{/* lastname */}
										<Form.Label>Lastname </Form.Label>
										<Form.Control
											type="text"
											id="lastname"
											placeholder="Your lastname"
											value={form.lastname}
											onChange={handleChange}
											required
										/>
									</Col>								
									<Col lg={12} md={12} className="mb-3">
										{/* email */}
										<Form.Label>Email </Form.Label>
										<Form.Control
											type="email"
											id="email"
											placeholder="Email address here"
											value={form.email}
											onChange={handleChange}
											required
										/>
									</Col>
									<Col lg={12} md={12} className="mb-3">
										{/* Password */}
										<Form.Label>Password </Form.Label>
										<Form.Control
											type="password"
											id="password"
											value={form.password}
											onChange={handleChange}
											placeholder="**************"
											required
										/>
									</Col>
									<Col lg={12} md={12} className="mb-3">
										{/* Checkbox */}
										<Form.Check type="checkbox" id="check-api-checkbox">
											<Form.Check.Input 
												id="cgu" 
												type="checkbox" 
												checked={form.cgu}
												onChange={handleCheck} />
											<Form.Check.Label>
												I agree to the{' '}
												<Link to="#">
													Terms of Service{' '}
												</Link>{' '}
												and{' '}
												<Link to="#">
													Privacy Policy.
												</Link>
											</Form.Check.Label>
										</Form.Check>
									</Col>
									<Col lg={12} md={12} className="mb-0 d-grid gap-2">
										{/* Button */}
										{ !loading && 
											<Button variant="primary" type="submit">
												Sign up
											</Button>
										}
										{ loading && 
											<Button variant="primary" type="submit" disabled>
												<Spinner
													as="span"
													animation="grow"
													size="sm"
													role="status"
													aria-hidden="true"/>
												 {' '} Loading...
											</Button>
										}
									</Col>
								</Row>
							</Form>							
						</Card.Body>
					</Card>
				</Col>
			</Row>
		</Fragment>
	);
};

export default SignUp;
