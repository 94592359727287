// import node module libraries
import { Fragment, useEffect, useState } from 'react';
import { Col, Row, Container, Tab } from 'react-bootstrap';

// import custom components
import FormSelect from 'components/elements/form-select/FormSelect';
import GridListViewButton from 'components/elements/miscellaneous/GridListViewButton';
import PageHeading from 'components/marketing/common/page-headings/PageHeading';

// import sub components
import FilterOptions from './FilterOptions';
import BookGridView from './BookGridView';
import BookListView from './BookListView';

// import rest api
import { getBooks } from 'services/api/KumajiroApi';

// import hooks
import { useFilters } from 'context/providers/FiltersProvider';

// for error toast message
import Notify from 'services/Notify';

const BookFilterPage = () => {
	const filters = useFilters()
	
	const sortByOptions = [
		{ value: 'newest', label: 'Newest' },
		{ value: 'name', label: 'name' }
	];

	const handleSortChange = (event) => {
		filters.books.setSortBy(event.target.value)		
		filters.books.setPage(0)
	};

	const handleDisplayChange = (display) => {
		filters.books.setDisplay(display)		
	};	

	const [books, setBooks] = useState([])

	useEffect(() => {
		getBooks(filters.books.query, filters.books.categories, filters.books.sortBy)
		  .then(res => {	
			if (res.status === 204) {
				setBooks([])
			} else {
				setBooks(res.data)
			}
		  })
		  .catch((error) => {
			Notify.error("Failed to get data")
			setBooks([])
		  });
	}, [filters.books.query, filters.books.categories, filters.books.sortBy])

	useEffect(() => {
		setTimeout(() => {
			window.scrollTo(0, 0);
		}, 50);
	}, [filters.books.page]);

	return (
		<Fragment>
			{/* Page header */}
			<PageHeading pagetitle="Library" />

			{/* Content */}
			<div className="py-6">
				<Container>
					<Tab.Container defaultActiveKey={filters.books.display} activeKey={filters.books.display}>
						<Row>
							<Col lg={12} md={12} sm={12} className="mb-4">
								<Row className="d-lg-flex justify-content-between align-items-center">
									<Col md={6} lg={8} xl={9}>
										<h4 className="mb-3 mb-lg-0">
											{books?.length ?? 0} result{books?.length > 0 && "s"}
										</h4>
									</Col>
									<Col md={6} lg={4} xl={3} className="d-inline-flex">
										<div className="me-2">
											<GridListViewButton keyGrid="grid" keyList="list" onChange={handleDisplayChange} defaultValue={filters.books.display} />
										</div>
										<FormSelect options={sortByOptions} placeholder="Sort by" onChange={handleSortChange} defaultselected={filters.books.sortBy} />
									</Col>
								</Row>
							</Col>
							<Col xl={3} lg={3} md={4} sm={12} className="mb-4 mb-lg-0">
								<FilterOptions />
							</Col>
							{/* Tab content */}
							<Col xl={9} lg={9} md={8} sm={12}>
								<Tab.Content>
									<Tab.Pane eventKey="grid" className="pb-4 px-0">
										<BookGridView items={books} />
									</Tab.Pane>
									<Tab.Pane eventKey="list" className="pb-4 px-0 react-code">
										<BookListView items={books} />
									</Tab.Pane>
								</Tab.Content>
							</Col>
						</Row>
					</Tab.Container>
				</Container>
			</div>
		</Fragment>
	);
};

export default BookFilterPage;
