// import node module libraries
import { Fragment, useEffect, useState } from 'react';
import { Col, Row, Nav, Tab, Card, Container, Spinner } from 'react-bootstrap';

// import custom components
import ProfileCover from 'components/marketing/common/headers/ProfileCover';
import DashboardCoursesView from './DashboardCoursesView';
import DashboardBooksView from './DashboardBooksView';

// import media files
import AvatarDefault from 'assets/images/avatar/avatar-default.png';

// hooks
import { useAuth } from 'hooks/useAuth';

// import rest api
import { getBookmarkedCourses, getCompletedCourses, getReadingBooks } from 'services/api/KumajiroApi';

const StudentDashboard = () => {
	const { user } = useAuth();

	const [bookmarkedCourses, setBookmarkedCourses] = useState(null)
	const [readingBooks, setReadingBooks] = useState(null)
	const [completedCourses, setCompletedCourses] = useState(null)

	const [selectedTab, setSelectedTab] = useState("bookmarked")

	const dashboardData = {
		avatar: user.user ?  user.user.avatar : AvatarDefault,
		name: user.user ? (user.user.firstname + " " + user.user.lastname).trim() : '',
		username: user.user ? user.user.email : '',
		linkname: 'Account Setting',
		link: '/account/student/edit-profile',
		verified: false,
		outlinebutton: false,
		level: 'None'
	};

	const loadReadingBook = () => {
		getReadingBooks()
			.then(res => {
				if (res.status === 204) {
					setReadingBooks([])
				} else {
					setReadingBooks(res.data)
				}
			})
			.catch((error) => {
				setReadingBooks([])
			});
	};

	const loadBookmarkedCourses = () => {
		getBookmarkedCourses()
			.then(res => {
				if (res.status === 204) {
					setBookmarkedCourses([])
				} else {
					setBookmarkedCourses(res.data)
				}
			})
			.catch((error) => {
				setBookmarkedCourses([])
			});
	};

	const loadCompletedBooks = () => {
		getCompletedCourses()
			.then(res => {
				if (res.status === 204) {
					setCompletedCourses([])
				} else {
					setCompletedCourses(res.data)
				}
			})
			.catch((error) => {
				setCompletedCourses([])
			});
	};

	const refresh = (tab = selectedTab) => {
		if (tab === "achievements") {
			loadCompletedBooks();
		} else if (tab === "reading") {
			loadReadingBook();
		} else {
			loadBookmarkedCourses();
		}
	}
	
	const handleTabSelect = (tab) => {
		setSelectedTab(tab)
		refresh(tab)
	}

	useEffect(() => {
		refresh();
	}, []);

	return (
		<Fragment>
			<div className="pt-5 pb-5">
				<Container>
					{/* User info */}
					<ProfileCover dashboardData={dashboardData} />

					{/* Content */}
					<Row className="mt-0 mt-md-4">
						<Col lg={12} md={12} sm={12}>
							<Row className="mb-6">
								<Col md={12}>
									<Tab.Container defaultActiveKey="bookmarked" onSelect={tab => handleTabSelect(tab)}>
										<Card className="bg-transparent shadow-none ">
											<Card.Header className="border-0 p-0 bg-transparent">
												<Nav className="nav-lb-tab">
													<Nav.Item className="ms-0">
														<Nav.Link
															eventKey="bookmarked"
															className="mb-sm-3 mb-md-0"
														>
															Bookmarked
														</Nav.Link>
													</Nav.Item>													
													<Nav.Item>
														<Nav.Link
															eventKey="reading"
															className="mb-sm-3 mb-md-0"
														>
															Reading books
														</Nav.Link>
													</Nav.Item>
													<Nav.Item className="ms-0">
														<Nav.Link
															eventKey="achievements"
															className="mb-sm-3 mb-md-0"
														>
															Achievements
														</Nav.Link>
													</Nav.Item>
												</Nav>
											</Card.Header>
											<Card.Body className="p-0">
												<Tab.Content>
													<Tab.Pane
														eventKey="bookmarked"
														className="pb-4 p-4 ps-0 pe-0"
													>
														{/* bookmarked started */}						
														<Row>
															{bookmarkedCourses &&
																<DashboardCoursesView
																	items={bookmarkedCourses}
																	onBookmarked={refresh}/>		
															}
															{!bookmarkedCourses &&
																<Col className="align-items-center">
																	<div className="d-flex align-items-center justify-content-center">
																		<Spinner animation="border" variant="primary" />
																	</div>
																</Col>
															}
														</Row>
														{/* end of bookmarked */}
													</Tab.Pane>
													<Tab.Pane
														eventKey="reading"
														className="pb-4 p-4 ps-0 pe-0"
													>
														{/* reading books started */}
														<Row>
															{readingBooks &&
																<DashboardBooksView
																	items={readingBooks}/>
															}
															{!readingBooks &&
																<Col className="align-items-center">
																	<div className="d-flex align-items-center justify-content-center">
																		<Spinner animation="border" variant="primary" />
																	</div>
																</Col>
															}
														</Row>
														{/* end of reading books */}
													</Tab.Pane>
													<Tab.Pane
														eventKey="achievements"
														className="pb-4 p-4 ps-0 pe-0"
													>
														{/* reading books started */}
														<Row>
															{completedCourses &&
																<DashboardCoursesView
																	items={completedCourses}/>
															}
															{!completedCourses &&
																<Col className="align-items-center">
																	<div className="d-flex align-items-center justify-content-center">
																		<Spinner animation="border" variant="primary" />
																	</div>
																</Col>
															}
														</Row>
														{/* end of reading books */}
													</Tab.Pane>
												</Tab.Content>
											</Card.Body>
										</Card>
									</Tab.Container>
								</Col>
							</Row>
						</Col>
					</Row>
				</Container>
			</div>
		</Fragment>
	);
};
export default StudentDashboard;
