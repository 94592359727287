const CryptoJS = require("crypto-js");

const KEY_LENGTH = 32

const getItem = (key) => {
    return decrypt(localStorage.getItem(key));
  };
  
const setItem = (key, value) => {
  localStorage.setItem(key, encrypt(value));
};

const encrypt = (data) => {
  const key = getStorageKey()
  return key + CryptoJS.Rabbit.encrypt(data, key).toString();
}

const decrypt = (data) => {
  const encryptedString = data.substring(KEY_LENGTH)
  const key = data.substring(0, KEY_LENGTH)
  return CryptoJS.Rabbit.decrypt(encryptedString, key).toString(CryptoJS.enc.Utf8);
}

const getStorageKey = () => CryptoJS.lib.WordArray.random(256/8).toString().slice(0, KEY_LENGTH)
  
const SecureStorage = {
  getItem,
  setItem
};
  
export default SecureStorage;