// import node module libraries
import { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { Link, useHistory } from 'react-router-dom';
import {
	Image,
	Navbar,
	Nav,
	Container,
	Form,
	Dropdown,
	NavDropdown
} from 'react-bootstrap';
import { useMediaQuery } from 'react-responsive';

// simple bar scrolling used for notification item scrolling
import 'simplebar/dist/simplebar.min.css';

// import custom components
import DarkLightMode from '../../DarkLightMode'; 

// import media files
import Logo from 'assets/images/brand/logo/logo.svg';
import AvatarDefault from 'assets/images/avatar/avatar-default.png';

// import data files
import NavbarDefaultRoutes from 'routes/marketing/NavbarDefault';
import NavDropdownMain from 'layouts/marketing/navbars/NavDropdownMain';

// session
import { useAuth } from 'hooks/useAuth';

// hooks
import { useFilters } from 'context/providers/FiltersProvider';

const NavbarDefault = ({ headerstyle, login }) => {
	const isDesktop = useMediaQuery({
		query: '(min-width: 1224px)'
	});
	const isLaptop = useMediaQuery({
		query: '(min-width: 1024px)'
	});

	const { user, logout } = useAuth();

	const filters = useFilters()

	const [expandedMenu, setExpandedMenu] = useState(false);
	const [query, setQuery] = useState(filters.courses.query)

	const history = useHistory();

	let avatarSrc = AvatarDefault
	let username = ""
	let email = ""
    if (user.user) {
		avatarSrc = user.user.avatar
		username = (user.user.firstname + " " + user.user.lastname).trim()
		email = user.user.email
    }	

	const handleLogout = (event) => {
        event.preventDefault()
        logout()
    }

	const goToSearch = (event) => {
		event.preventDefault()
		filters.courses.setQuery(query)
		filters.courses.setPage(0)
		history.push(`/courses`);
	}

	const goToProfile = (event) => {
		event.preventDefault()
		history.push(`/account/student/dashboard`);
	}

	const goToProfileSettings = (event) => {
		event.preventDefault()
		history.push(`/account/student/edit-profile`);
	}

	const handleQueryChange = (event) => {
		setQuery(event.target.value)
	}

	const QuickMenu = () => {
		return (
			<Fragment>
				<Dropdown as={Nav.Item}>
					<Dropdown.Toggle
						as={Nav.Link}
						bsPrefix="dt"
						className="rounded-circle border-bottom-0"
						id="dropdownUser"
					>
						<div className="avatar avatar-md avatar-indicators avatar-online">
						<Image alt="avatar" src={avatarSrc} className="rounded-circle" />
						</div>
					</Dropdown.Toggle>
					<Dropdown.Menu
						show={isDesktop ? true : false}
						className="dashboard-dropdown dropdown-menu-end mt-4 py-0"
						aria-labelledby="dropdownUser"
						align="end"
					>
						<Dropdown.Item className="mt-3">
							<div className="d-flex" onClick={goToProfile}>
								<div className="avatar avatar-md avatar-indicators avatar-online">
									<Image
										alt="avatar"
										src={avatarSrc}
										className="rounded-circle"
									/>
								</div>
								<div className="ms-3 lh-1">
									<h5 className="mb-1">{username}</h5>
									<p className="mb-0 text-muted">{email}</p>
								</div>
							</div>
						</Dropdown.Item>
						<Dropdown.Divider />
						<Dropdown.Item onClick={goToProfile}>
							<i className="fe fe-user me-2"></i> Profile
						</Dropdown.Item>
						<Dropdown.Item onClick={goToProfileSettings}>
							<i className="fe fe-settings me-2"></i> Settings
						</Dropdown.Item>
						<Dropdown.Divider />
						<Dropdown.Item className="mb-3" onClick={handleLogout}>
							<i className="fe fe-power me-2"></i> Sign Out
						</Dropdown.Item>
					</Dropdown.Menu>
				</Dropdown>
			</Fragment>
		);
	};
	return (
		<Fragment>
			<Navbar
				onToggle={(collapsed) => setExpandedMenu(collapsed)}
				expanded={expandedMenu}
				expand="lg"
				className="navbar p-2 navbar-default py-2"
			>
				<Container fluid className="px-0 ps-2">
					<Navbar.Brand as={Link} to="/">
						<Image src={Logo} alt="" />
					</Navbar.Brand>
					<div
						className={`navbar-nav navbar-right-wrap ms-auto d-lg-none nav-top-wrap ${login ? (isDesktop || isLaptop ? 'd-none' : 'd-flex') : 'd-none'
							}`}
					>
						<QuickMenu />
					</div>
					<Navbar.Toggle aria-controls="basic-navbar-nav">
						<span className="icon-bar top-bar mt-0"></span>
						<span className="icon-bar middle-bar"></span>
						<span className="icon-bar bottom-bar"></span>
					</Navbar.Toggle>
					<Navbar.Collapse id="basic-navbar-nav">
						<Nav>
							{NavbarDefaultRoutes.map((item, index) => {
								if (item.children === undefined) {
									return (
										<Nav.Link key={index} as={Link} to={item.link}>
											{item.menuitem}
										</Nav.Link>
									);
								} else {
									return (
										<NavDropdownMain
											item={item}
											key={index}
											onClick={(value) => setExpandedMenu(value)}
										/>
									);
								}
							})}
							{user?.user && user.user.isAdmin === true &&
								<NavDropdown
									title="..."
									id="basic-nav-dropdown"
									bsPrefix="no-dropdown-arrow d-block nav-link fs-3 lh-1 pt-0"
								>
									<NavDropdown.Item
										as={Link}
										to="/admin"
										className="py-2 px-3"
									>
										<div className="d-flex align-items-center">
											<i className="fe fe-log-in fs-3 text-primary"></i>
											<div className="ms-3 lh-3">
												<h5 className="mb-0">Administation</h5>
												<p className="mb-0 fs-6">Open the admin dashboard</p>
											</div>
										</div>
									</NavDropdown.Item>
								</NavDropdown>
							}							
						</Nav>
						{/* Search Form */}
						<Form className="mt-3 mt-lg-0 ms-lg-3 d-flex align-items-center" onSubmit={goToSearch}>
							<span className="position-absolute ps-3 search-icon">
								<i className="fe fe-search"></i>
							</span>
							<Form.Control
								type="Search"
								id="formSearch"
								className="ps-6"
								placeholder="Search for learning content"
								value={query}
								onChange={handleQueryChange}
							/>
						</Form>
						{/* Right side quick / shortcut menu  */}

						<Nav className="navbar-nav navbar-right-wrap ms-auto d-flex nav-top-wrap">
							<DarkLightMode className="mt-2 me-2"/>
							<span className={`ms-auto mt-1  ${login ? 'd-none' : ''}`}>
								<Nav.Link
									as={Link}
									to="#"
									bsPrefix="btn"
									className="btn btn-white shadow-sm me-2"
								>
									Sign In
								</Nav.Link>
								<Nav.Link
									as={Link}
									to="#"
									bsPrefix="btn"
									className="btn btn-primary shadow-sm"
								>
									Sign Up
								</Nav.Link>
							</span>

							<span
								className={`${login
									? isDesktop || isLaptop
										? 'd-flex'
										: 'd-none'
									: 'd-none'
									}`}
							>
								<QuickMenu />
							</span>
						</Nav>
						{/* end of right side quick / shortcut menu  */}
					</Navbar.Collapse>
				</Container>
			</Navbar>
		</Fragment>
	);
};

// Specifies the default values for props
NavbarDefault.defaultProps = {
	headerstyle: 'navbar-default',
	login: false
};

// Typechecking With PropTypes
NavbarDefault.propTypes = {
	headerstyle: PropTypes.string,
	login: PropTypes.bool
};

export default NavbarDefault;
