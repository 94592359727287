import { useState, useEffect } from 'react';

// import node module libraries
import { withRouter } from 'react-router-dom';
import { Card, Form, Row, Col, Button, Image, Spinner } from 'react-bootstrap';

// import media files
import AvatarDefault from 'assets/images/avatar/avatar-default.png';

// import profile layout wrapper
import ProfileLayout from 'components/marketing/student/ProfileLayout';

// import hooks
import { useAuth } from 'hooks/useAuth';

// to display success / error message
import Notify from 'services/Notify';

// import rest api
import { getProfile, updateProfileInformation } from 'services/api/KumajiroApi';

// import session
import UserSession from 'services/UserSession';

const EditProfile = (props) => {
	const INITIAL_STATE = {
		firstname: '',
		lastname: '',
	};

	const { user } = useAuth();

	const [form, setForm] = useState(INITIAL_STATE);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		setLoading(true)
		getProfile()
			.then(profile => {
				setLoading(false)
				setForm(profile.data)				
			})
			.catch((error) => {
				setLoading(false)
				Notify.error(error.response?.data?.message ?? "An error occured")				
			});
	}, []);

	const goToGravatar = () => {
		window.location = 'https://en.gravatar.com'
	}

	const handleChange = (event) => {
		setForm({
		  ...form,
		  [event.target.id]: event.target.value,
		});
	};

	const handleSubmit = (event) => {
		event.preventDefault();

		const firstname = form.firstname
		const lastname = form.lastname

		setLoading(true)
		updateProfileInformation(firstname, lastname)
			.then(res => {
				setLoading(false)
				UserSession.updateLocalUserInfo(firstname, lastname)
				Notify.success('Profile updated')
			})
			.catch((error) => {
				setLoading(false)
				Notify.error(error.response?.data?.message ?? "An error occured")				
			});
  	};

	const account = props.location.pathname.substring(21, 11);

	return (
		<ProfileLayout>
			{account === 'instructor'}
			<Card className="border-0">
				<Card.Header>
					<div className="mb-3 mb-lg-0">
						<h3 className="mb-0">Profile Details</h3>
						<p className="mb-0">
							You have full control to manage your own account setting.
						</p>
					</div>
				</Card.Header>
				<Card.Body>
					<div className="d-lg-flex align-items-center justify-content-between">
						<div className="d-flex align-items-center mb-4 mb-lg-0">
							<Image
								src={user.user ? user.user.avatar : AvatarDefault}
								id="img-uploaded"
								className="avatar-xl rounded-circle"
								alt=""
							/>
							<div className="ms-3">
								<h4 className="mb-0">Your avatar</h4>
								<p className="mb-0">
									Upload your avatar through Gravatar
								</p>
							</div>
						</div>
						<div>
							<Button variant="outline-white" size="sm" onClick={goToGravatar}>
								Manage Avatar
							</Button>{' '}							
						</div>
					</div>
					<hr className="my-5" />
					<div>
						<h4 className="mb-0">Personal Details</h4>
						<p className="mb-4">Edit your personal information and address.</p>
						{/* Form */}
						<Form onSubmit={handleSubmit}>
							<Row>
								{/* First name */}
								<Col md={6} sm={12} className="mb-3">
									<Form.Group className="mb-3" controlId="firstname">
										<Form.Label>First Name</Form.Label>
										<Form.Control
											type="text"
											placeholder="First Name"
											required
											value={form.firstname}
											onChange={handleChange}
										/>
									</Form.Group>
								</Col>

								{/* Last name */}
								<Col md={6} sm={12} className="mb-3">
									<Form.Group className="mb-3" controlId="lastname">
										<Form.Label>Last Name</Form.Label>
										<Form.Control
											type="text"
											placeholder="Last Name"
											required
											value={form.lastname}
											onChange={handleChange}
										/>
									</Form.Group>
								</Col>								

								{/* Button */}
								<Col sm={12} md={12}>
									{!loading && 
										<Button variant="primary" type="submit">
											Update Profile
										</Button>
									}
									{ loading && 
										<Button variant="primary" type="submit" disabled>
											<Spinner
												as="span"
												animation="grow"
												size="sm"
												role="status"
												aria-hidden="true"/>
												{' '} Loading...
										</Button>
									}	
								</Col>
							</Row>
						</Form>
					</div>
				</Card.Body>
			</Card>
		</ProfileLayout>
	);
};

export default withRouter(EditProfile);
