// import node module libraries
import { useAuth } from 'hooks/useAuth';
import React from 'react';
import { Container } from 'react-bootstrap';
import { Redirect } from 'react-router-dom';

const AuthLayout = (props) => {
	const { user } = useAuth();

	if (user) {
		return <Redirect to= "/home" />
	}

	return (
		<div id="db-wrapper">
			<Container className="d-flex flex-column">{props.children}</Container>
		</div>
	);
};
export default AuthLayout;
