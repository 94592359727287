// import node module libraries
import MarkdownToHtml from 'components/elements/markdown-html/MarkdownToHtml';

const TranscriptionTab = ({course}) => {
	return (
		<article>			
			{course.transcript &&
				<MarkdownToHtml
                    content={course.transcript}/>
			}
            {!course.transcript &&
                <p>No transcription</p>
            }
		</article>
	);
};
export default TranscriptionTab;
