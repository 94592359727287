// import node module libraries
import React, { Fragment } from 'react';
import { Redirect, useLocation } from 'react-router-dom';

// import layouts
import NavbarDefault from 'layouts/marketing/navbars/NavbarDefault';
import Footer from 'layouts/marketing/footers/Footer';

// import hooks
import { useAuth } from 'hooks/useAuth';

const ProtectedLayout = (props) => {
    const { user } = useAuth();
    const location = useLocation();

    if (!user) {
        return (
            <Redirect
              to={{ pathname: "/", state: { from: location } }}
              replace
            />
          );
    }

	return (
		<Fragment>
			<NavbarDefault login />
			{props.children}
			<Footer />
		</Fragment>
	);
};

export default ProtectedLayout;
