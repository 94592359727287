// import node module libraries
import { Fragment } from 'react';
import { Menu } from 'react-feather';
import { Link, useHistory } from 'react-router-dom';
import {
	Nav,
	Navbar,
	Dropdown,
	Image
} from 'react-bootstrap';

// import media files
import AvatarDefault from 'assets/images/avatar/avatar-default.png';

// import data files
import { useAuth } from 'hooks/useAuth';
import { useMediaQuery } from 'react-responsive';
import DarkLightMode from 'layouts/DarkLightMode';

const HeaderDefault = (props) => {
	const isDesktop = useMediaQuery({
		query: '(min-width: 1224px)'
	});	
	
	const { user, logout } = useAuth();

	const history = useHistory();

	let avatarSrc = AvatarDefault
	let username = ""
	let email = ""
    if (user.user) {
		avatarSrc = user.user.avatar
		username = (user.user.firstname + " " + user.user.lastname).trim()
		email = user.user.email
    }	

	const handleLogout = (event) => {
        event.preventDefault()
        logout()
    }

	const goToProfile = (event) => {
		event.preventDefault()
		history.push(`/account/student/dashboard`);
	}

	const goToProfileSettings = (event) => {
		event.preventDefault()
		history.push(`/account/student/edit-profile`);
	}

	return (
		<Fragment>
			<Navbar expanded="lg" className="navbar-default">
				<div className="d-flex justify-content-between w-100">
					<div className="d-flex align-items-center">
						<Link
							id="nav-toggle"
							to="#"
							onClick={() => props.data.SidebarToggleMenu(!props.data.showMenu)}
						>
							<Menu size="18px" />
						</Link>						
					</div>

					<Nav className="navbar-nav navbar-right-wrap ms-auto d-flex align-items-center nav-top-wrap">
						<DarkLightMode className="me-2"/>
						<Dropdown as={Nav.Item}>
							<Dropdown.Toggle
								as={Nav.Link}
								bsPrefix="dt"
								className="rounded-circle border-bottom-0"
								id="dropdownUser"
							>
								<div className="avatar avatar-md avatar-indicators avatar-online">
									<Image alt="avatar" src={avatarSrc} className="rounded-circle" />
								</div>
							</Dropdown.Toggle>
							<Dropdown.Menu
								show={isDesktop ? true : false}
								className="dashboard-dropdown dropdown-menu-end mt-4 py-0"
								aria-labelledby="dropdownUser"
								align="end"
							>
								<Dropdown.Item className="mt-3">
									<div className="d-flex" onClick={goToProfile}>
										<div className="avatar avatar-md avatar-indicators avatar-online">
											<Image
												alt="avatar"
												src={avatarSrc}
												className="rounded-circle"
											/>
										</div>
										<div className="ms-3 lh-1">
											<h5 className="mb-1">{username}</h5>
											<p className="mb-0 text-muted">{email}</p>
										</div>
									</div>
								</Dropdown.Item>
								<Dropdown.Divider />
								<Dropdown.Item eventKey="2" onClick={goToProfile}>
									<i className="fe fe-user me-2"></i> Profile
								</Dropdown.Item>
								<Dropdown.Item onClick={goToProfileSettings}>
									<i className="fe fe-settings me-2"></i> Settings
								</Dropdown.Item>
								<Dropdown.Divider />
								<Dropdown.Item className="mb-3" onClick={handleLogout}>
									<i className="fe fe-power me-2"></i> Sign Out
								</Dropdown.Item>
							</Dropdown.Menu>
						</Dropdown>
					</Nav>
				</div>
			</Navbar>
		</Fragment>
	);
};

export default HeaderDefault;
