// import node module libraries
import React, { Fragment, useState, useEffect } from 'react';
import { Col, Row, Container, Image, Spinner, Button, ListGroup } from 'react-bootstrap';
import { Link, useHistory, useParams } from 'react-router-dom';

// import sub components
import CourseCard from '../CourseCard';
import MarkdownToHtml from 'components/elements/markdown-html/MarkdownToHtml';
import LazyImage from 'components/elements/images/LazyImage';
import LevelIcon from 'components/marketing/common/miscellaneous/LevelIcon';
import Tippy from '@tippyjs/react';

// import rest api
import { getCourse, getCourses, updateCourseUserBookmark, updateCourseUserProgress } from 'services/api/KumajiroApi';

// import utility file
import { getHHMMSS } from 'helper/utils';

// for success / error messages
import Notify from 'services/Notify';

const CourseReader = (props) => {
    const { courseId } = useParams();

    const [course, setCourse] = useState(null)
    const [relatedCourses, setRelatedCourses] = useState([])
    const [completed, setCompleted] = useState(false)
	const [bookmarked, setBookmarked] = useState(false)
    const [loadingUserProgress, setLoadingUserProgress] = useState(false)	
	const [loadingUserBookmark, setLoadingUserBookmark] = useState(false)	

    const history = useHistory();

    useEffect(() => {
        setCourse(null)
        setTimeout(() => {
			window.scrollTo(0, 0);
		}, 50);
		getCourse(courseId)
			.then(res => {
				setCourse(res.data)
				setCompleted(res.data.completed)
				setBookmarked(res.data.bookmarked)
			})
			.catch((error) => {
				if (error.response?.status === 404) {
					history.replace("/404-error", { replace: true });
				} else {
					history.replace("/500-error", { replace: true });
				}
			});       
    }, [courseId, history])

    useEffect(() => {
        if (!course) {
            setRelatedCourses([])
            return
        }
		getCourses(
            1,
			3,
			[],
			course.category.map((category) => category.id),
			[], 
			[], 
			'-released_at',
			course.name
        )
        .then(res => {
            setRelatedCourses(
                res.data.data.filter(item => item.id !== course.id)
            )
        })
        .catch((error) => {
            setRelatedCourses([])
        });       
    }, [course])

    const handleCompletedClick = (event) => {
        event.preventDefault()
        setLoadingUserProgress(true)
        updateCourseUserProgress(course.id, !completed)
            .then(res => {
				setLoadingUserProgress(false)
				Notify.success(`Course marked as ${!completed ? "completed" : "uncompleted"}`)
                // Update the completed button
                setCompleted(!completed)				
            })
            .catch((error) => {
				Notify.error("Failed to update the progress")
                setLoadingUserProgress(false)
            });
    }

	const handleBookmarkedClick = (event) => {
        setLoadingUserBookmark(true)
        updateCourseUserBookmark(course.id, !bookmarked)
            .then(res => {
				setLoadingUserBookmark(false)				
                // Update the completed button
				if (!bookmarked) {
					Notify.success(`You have bookmarked ${course.name}`)
				} else {
					Notify.success(`You have removed the bookmark for ${course.name}`)
				}
                setBookmarked(!bookmarked)				
            })
            .catch((error) => {
				Notify.error("Failed to update bookmarks")
                setLoadingUserBookmark(false)
            });
    }

	const handleDownloadClick = (event) => {
		window.location = course.downloadMaterialUrl
	}

	const authorAndBookmark = (data) => {
		return (
			<div className="d-flex justify-content-between align-items-center mb-5">
				<div className="d-flex align-items-center me-2">
					<Image
						src={data.editor.avatar}
						alt=""
						className="rounded-circle avatar-md"
					/>
					<div className="ms-2 lh-1">
						<h5 className="mb-1 ">{data.author}</h5>
						<span className="text-primary">{''}</span>
					</div>					
				</div>
				<div className="d-flex">
					{course.downloadMaterialUrl &&
						<Tippy content="Download materials" animation={'scale'}>
							<Button variant='transparent' size="sm" className="text-muted bookmark" onClick={handleDownloadClick} disabled={loadingUserBookmark === true}>
								<i className='fa fa-download text-primary'></i>
							</Button>
						</Tippy>
					}					
					<Tippy content="Add to Bookmarks" animation={'scale'}>
                        <Button variant='transparent' size="sm" className="text-muted bookmark" onClick={handleBookmarkedClick} disabled={loadingUserBookmark === true}>
							{loadingUserBookmark === true &&							
								<Spinner
									as="span"
									animation="grow"
									size="sm"
									role="status"
									aria-hidden="true"/>
							}
							{loadingUserBookmark === false &&							
								<i className={`${bookmarked ? 'fa fa-bookmark text-primary' : 'fe fe-bookmark'}`}></i>
							}
                        </Button>
                    </Tippy>
				</div>
			</div>
		);
	};
	return (
		<Fragment>
			<div className="py-4 py-lg-8 pb-14 ">
				<Container>
                    {!course &&
                        <div className="d-flex align-items-center justify-content-center" style={{height: "40vh"}}>
                            <Spinner animation="border" variant="primary" />
                        </div>
                    }
					{course &&
						<Fragment key={course.id}>
							<Row className="justify-content-center">
								<Col xl={8} lg={8} md={12} sm={12} className="mb-2">
									<div className="text-center mb-4">
                                        <ListGroup as="ul" bsPrefix="list-inline">
										{course.category.length > 2 && 
											<ListGroup.Item as="li" bsPrefix="list-inline-item">
												<Link
													to='#'
													className="fs-5 fw-semi-bold d-block mb-4 text-primary"
												>
													Multi-Domains
												</Link>
											</ListGroup.Item>
										}
                                        {course.category.length <= 2 && course.category.map((item) => (
                                            <ListGroup.Item as="li" bsPrefix="list-inline-item" key={item.id}>
												<Link
													to={`/category/${item.code}`}
													className="fs-5 fw-semi-bold d-block mb-4 text-primary"
												>
													{item.name}
												</Link>
                                            </ListGroup.Item>
                                        ))}										
                                        </ListGroup>
										<h1 className="display-3 fw-bold mb-4">{course.name}</h1>
                                        <ListGroup as="ul" bsPrefix="list-inline">
											<ListGroup.Item as="li" bsPrefix="list-inline-item">
												<LevelIcon level={course.difficulty} />
												<span className='text-capitalize'>{course.difficulty}</span>
											</ListGroup.Item>
                                            <ListGroup.Item as="li" bsPrefix="list-inline-item">
                                                <i className="far fa-calendar me-1"></i>
                                                {new Date(course.publishingDate).toLocaleDateString('en-GB')}
                                            </ListGroup.Item>
                                            <ListGroup.Item as="li" bsPrefix="list-inline-item">
                                                <i className="far fa-clock me-1"></i>
                                                {getHHMMSS(course.duration)} read
                                            </ListGroup.Item>
                                            {course.technology && course.technology.includes('other') &&
                                                <ListGroup.Item as="li" bsPrefix="list-inline-item">
                                                    <i className="far fa-code me-1"></i>
                                                    {course.technology}
                                                </ListGroup.Item>
                                            }                                            
                                        </ListGroup>										
									</div>
									{/* Author */}
									{authorAndBookmark(course)}
								</Col>
							</Row>
							<Row className="justify-content-center">
								{/* Image */}
								<Col xl={10} lg={10} md={12} sm={12} className="mb-6 text-center">
									<LazyImage
										src={course.thumbnail}
										alt=""
										className="img-fluid rounded-3"
									/>
								</Col>
							</Row>
							<Row className="justify-content-center">
								<Col xl={8} lg={8} md={12} sm={12} className="mb-2">                                    
									{/* Blog Content */}
                                    <article>
                                        <MarkdownToHtml
                                            content={
                                                '# ' + course.summary +
                                                course.content
                                            }/>
                                    </article>
                                    <div className="mt-6 mb-10 text-center">
										<Button variant={completed === true ? "primary" : "outline-primary"} className="me-1" disabled={loadingUserProgress === true} onClick={handleCompletedClick}>
											{loadingUserProgress === true && 
												<>
													<Spinner
														as="span"
														animation="grow"
														size="sm"
														role="status"
														aria-hidden="true"/>
													{' '}Progress
												</>
											} 
											{loadingUserProgress === false &&
												<><i className="fe fe-check"></i> {completed ? "Completed" : "Mark complete"}</>
											}
										</Button> 
									</div>	
									<hr className="mt-8 mb-5" />
									{/* Author */}
									{authorAndBookmark(course)}									
								</Col>
							</Row>
						</Fragment>
                    }
				</Container>

				{/* Related Courses */}
                {relatedCourses && relatedCourses.length > 0 && 
                <Container>
                    <Row>
                        <Col xl={12} lg={12} md={12} sm={12}>
                            <div className="my-5">
                                <h2>Related Courses</h2>
                            </div>
                        </Col>
                        {relatedCourses.map((item, index) => (
                            <Col xl={4} lg={4} md={6} sm={12} key={index} className="d-flex">
                                <CourseCard item={item} />
                            </Col>
                        ))}
                    </Row>
                </Container>
                }				
			</div>
		</Fragment>
	);
};

export default CourseReader;
