// import node module libraries
import React, { Fragment, useState, useEffect } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { Col, Row, Card, Breadcrumb, Spinner } from 'react-bootstrap';

// import sub custom components
import BookEditionsTable from './BookEditionsTable';

// rest api
import { deleteBookEdition, getBookEditions, updateBook } from 'services/api/KumajiroApi';

// to display success / error messages
import Notify from 'services/Notify';

const AllBookEditions = () => {
    const { bookId } = useParams();
	
	const history = useHistory()

	const [books, setBooks] = useState([]);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		refreshData();
	}, [bookId]);

	const handleCreate = () => {
		history.push(`/admin/library/editor/update-book/${bookId}`)
	};

	const handleUpdate = (book) => {
		history.push(`/admin/library/editor/update-book/${book.id}/${book.info.version}`)
	};

	const handlePublish = (book, publish) => {
		setLoading(true)
		const data = {
			id: book.id,
			version: book.version,
			published: publish
		}
		updateBook(book.id, data)
			.then((response) => {
				setLoading(false)
				refreshData()
				Notify.success(publish ? "Edition published" : "Edition suspended")
			})
			.catch((error) => {
				setLoading(false)
				Notify.error(error.response?.data?.message ?? (publish ? "Failed to publish the edition" : "Failed to suspend the edition"))
			})
	};

	const handleDelete = (book) => {
		setLoading(true)
		deleteBookEdition(book.id, book.version)
			.then((response) => {
				setLoading(false)
				refreshData()
				Notify.success("Edition deleted")
			})
			.catch((error) => {
				setLoading(false)
				Notify.error(error.response?.data?.message ?? "Failed to delete the book edition")
			})
	};

	const refreshData = () => {
		setLoading(true)
		getBookEditions(bookId)
			.then(response => {
                setLoading(false)
                if (response.status === 204) {
                    setBooks([])
                } else {                    
				    setBooks(response.data)
                }                
			})
			.catch(error => {
				setLoading(false)
				setBooks([])
				Notify.error(error.response?.data?.message ?? "An error occured")				
			});
	};

	return (
		<Fragment>
			<Row>
				<Col lg={12} md={12} sm={12}>
					<div className="border-bottom pb-4 mb-4 d-md-flex align-items-center justify-content-between">
						<div className="mb-3 mb-md-0">
							<h1 className="mb-1 h2 fw-bold">
                                {books.length &&
                                    books[0].info.editionName
                                }                                
                            </h1>
							<Breadcrumb>
								<Breadcrumb.Item href="/admin">Dashboard</Breadcrumb.Item>
								<Breadcrumb.Item href="/admin/library">Books</Breadcrumb.Item>
								<Breadcrumb.Item href={`/admin/library/list/${bookId}`}>
									{books.length &&
                                        books[0].info.editionName
                                    }
								</Breadcrumb.Item>
								<Breadcrumb.Item active>
                                    Editions
                                </Breadcrumb.Item>
							</Breadcrumb>
						</div>
						<div>
							{loading === true && 
							 	<Spinner animation="border" variant="primary" size="sm" className='me-5' />
							}
							<Link to="#" className="btn btn-primary" onClick={handleCreate}>
								Add New Edition
							</Link>
						</div>
					</div>
				</Col>
			</Row>
			<Row>
				<Col lg={12} md={12} sm={12}>
					<Card>						
						<BookEditionsTable 
							items={books}
							onUpdate={handleUpdate}
							onPublish={handlePublish}
							onDelete={handleDelete} />
					</Card>
				</Col>
			</Row>
		</Fragment>
	);
};

export default AllBookEditions;
