import React from "react"
import { Container, Row, Spinner } from "react-bootstrap"

const BookLoader = (props) => {
  return (
    <div className="pt-lg-8 pb-lg-16 pt-8 pb-12 bg-primary">
		<Container>
			<Row className="align-items-center">
				<div className="d-flex align-items-center justify-content-center py-8">
					<Spinner animation="border" variant="light" />															
				</div>
			</Row>
		</Container>
	</div>    
  )
}

export default BookLoader