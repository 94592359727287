// import node module libraries
import React, { useReducer } from 'react';

// import app config file
import { settings } from 'AppConfig';

// import context file
import { AppConfigContext } from 'context/Context';

// import reducer file
import { AppConfigReducer } from 'reducers/AppConfigReducer';

const AppProvider = ({ children }) => {
    const initialState = {
        version: settings.app.version,
        skin: settings.theme.skin
    }
    localStorage.setItem('skin', (localStorage.getItem('skin')===null ? settings.theme.skin : localStorage.getItem('skin'))); 
    const [appState, appStateDispatch] = useReducer(AppConfigReducer,initialState);    
    return (
        <AppConfigContext.Provider value={{ appState, appStateDispatch }}>                    
            {children}
        </AppConfigContext.Provider>
    )
}

export default AppProvider