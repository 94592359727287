// import node module libraries
import React, { useState, useEffect, Fragment } from 'react';
import { useHistory, useParams } from 'react-router-dom';

// subcomponents
import { Col, Row, Container, Card, Form, Button, Spinner, Breadcrumb, Modal, ButtonGroup } from 'react-bootstrap';
import MDEditor from '@uiw/react-md-editor';

// rest api
import { generateChapter, getBook, getBookChapter, updateBook } from 'services/api/KumajiroApi';

// to display success / error messages
import Notify from 'services/Notify';

const BookChapterEditor = () => {
	const INITIAL_STATE = {
        chapterCode: '',
        chapterNumber: '',        
        chapterTitle: '',
        chapterSummary: '',
        chapterContent: '',
        chapterRank: ''
    };

    const { bookId, version, sectionId, chapterId } = useParams();

    const history = useHistory()

    const [chapter, setChapter] = useState();
    const [book, setBook] = useState();
    const [fullScreenLoading, setFullScreenLoading] = useState(false);
    const [loading, setLoading] = useState(false);
	const [formData, setFormData] = useState(INITIAL_STATE);
    
    const [sourceUrl, setSourceUrl] = useState('');
    const [showGenerateModal, setShowGenerateModal] = useState(false);
    const handleCloseGenerateModal = () => setShowGenerateModal(false);	
	const handleShowGenerateModal = () => setShowGenerateModal(true);	
    const [generateLoading, setGenerateLoading] = useState(false);
    
	const handleChange = (event) => {  
		setFormData({
			...formData,
			[event.target.name]: event.target.value
		});
	};    
    const handleSummaryChange = (value) => {  
		setFormData({
			...formData,
			'chapterSummary': value
		});
	};
    const handleContentChange = (value) => {  
		setFormData({
			...formData,
			'chapterContent': value
		});
	};
    const handleGenerate = (e) => {
        e.preventDefault()
        setGenerateLoading(true)
        generateChapter(sourceUrl)
            .then((response) => {
                setGenerateLoading(false)
                const extractedInfo = response.data
                setFormData({
                    ...formData, 
                    chapterCode: extractedInfo.code ?? '',
                    chapterNumber: extractedInfo.number ?? '',        
                    chapterTitle: extractedInfo.name ?? '',
                    chapterSummary: extractedInfo.summary ?? '',
                    chapterContent: extractedInfo.content ?? ''
                })
                handleCloseGenerateModal()
            })
            .catch((error) => {
                setGenerateLoading(false)
				Notify.error(error.response?.data?.message ?? "An error occured")	
            });       
    };

    const goBack = () => {
        if (chapter) {
            history.push(`/admin/library/list/${chapter.id}/${chapter.version}/${chapter.chapter.section.id}`)
        } else {
            history.push(`/admin/library/list/${book.id}/${book.version}/${sectionId}`)
        }        
    };

    useEffect(() => {
        setFullScreenLoading(true)
        if (chapterId) {
            getBookChapter(bookId, chapterId, version)
                .then((response) => {
                    setFullScreenLoading(false)
                    const chapter = response.data
                    setChapter(chapter)
                    setFormData({
                        chapterCode: chapter.chapter.code ?? '',
                        chapterNumber: chapter.chapter.number ?? '',        
                        chapterTitle: chapter.chapter.name ?? '',
                        chapterSummary: chapter.chapter.summary ?? '',
                        chapterContent: chapter.chapter.content ?? '',
                        chapterRank: chapter.chapter.rank ?? ''
                    })
                })
                .catch((error) => {
                    setFullScreenLoading(false)
                    Notify.error(error.response?.data?.message ?? "An error occured")
                    if (error.response?.status === 404) {
                        goBack()
                    }
                });
        } else {
            getBook(bookId, version)
                .then((response) => {
                    setFullScreenLoading(false)
                    const book = response.data
                    setBook(book)
                    if (book.sections.filter((section) => section.id === sectionId).length === 0) {
                        Notify.error("Section not found")
                        goBack()
                    }                    
                })
                .catch((error) => {
                    setFullScreenLoading(false)
                    Notify.error(error.response?.data?.message ?? "An error occured")
                    if (error.response?.status === 404) {
                        goBack()
                    }
                });
        }      
    }, [bookId, version, chapterId])

    const handleSubmit = (e) => {
        e.preventDefault()

        setLoading(true)

        const data = {
            id: bookId,
            version: version,
            sections: [
                {
                    id: sectionId,
                    chapters: [
                        {
                            id: chapterId,
                            bookId: bookId,
                            bookSectionId: sectionId,
                            code: formData.chapterCode,
                            number: formData.chapterNumber,
                            name: formData.chapterTitle,
                            summary: formData.chapterSummary,
                            content: formData.chapterContent,
                            rank: formData.chapterRank
                        }
                    ]
                }
            ]            
        };

        updateBook(bookId, data)
            .then((response) => {
                setLoading(false)
                Notify.success(chapterId ? 'Chapter updated' : 'Chapter created')
                goBack()
            })
            .catch((error) => {
                setLoading(false)
                Notify.error(error.response?.data?.message ?? "An error occured")
                if (error.response?.status === 404) {
                    goBack()
                }
            });
    };

    const Header = ({title, subtitle}) =>
        <div className="py-4 py-lg-6 bg-primary">
            <Container>
                <Row>
                    <Col lg={{ span: 10, offset: 1 }} md={12} sm={12}>
                        <div className="d-lg-flex align-items-center justify-content-between">
                            <div className="mb-4 mb-lg-0">
                                <h1 className="text-white mb-1">{title}</h1>
                                <p className="mb-0 text-white lead">{subtitle}</p>                                    
                            </div>		
                            <div>
                                {!loading && 
                                    <ButtonGroup>
                                        <Button
                                            variant="white"
                                            onClick={handleShowGenerateModal}
                                        >
                                            Autofill
                                        </Button>{' '}
                                        <Button variant="success" type="submit" form="book-chapter">
                                            Save
                                        </Button>
                                    </ButtonGroup>
                                }
                                { loading && 
                                    <ButtonGroup>
                                        <Button
                                            variant="white"
                                            onClick={handleShowGenerateModal}
                                            disabled
                                        >
                                            Autofill
                                        </Button>{' '}
                                        <Button variant="success" type="submit" form="book-chapter" disabled>
                                            <Spinner
                                                as="span"
                                                animation="grow"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"/>
                                                {' '} Loading...
                                        </Button>
                                    </ButtonGroup>                                    
                                }
                            </div>						
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>

    if (fullScreenLoading === true) {
        return (
            <div className="d-flex align-items-center justify-content-center" style={{height: "40vh"}}>
                <Spinner animation="border" variant="primary" />
            </div>
        );
    }

	return (
		<Fragment>
            {chapter &&
                <Fragment>
                    <Breadcrumb>
                        <Breadcrumb.Item href="/admin">Dashboard</Breadcrumb.Item>
                        <Breadcrumb.Item href="/admin/library">Books</Breadcrumb.Item>
                        <Breadcrumb.Item href={`/admin/library/list/${bookId}`}>
                            {chapter?.info?.editionName ?? ''}
                        </Breadcrumb.Item>                    
                        <Breadcrumb.Item href={`/admin/library/list/${bookId}/${version}`}>
                            {chapter?.params?.filter((item) => item.code === 'VERSION')[0]?.value}
                        </Breadcrumb.Item>								
                        <Breadcrumb.Item href={`/admin/library/list/${chapter.id}/${chapter.version}/${chapter.chapter.section.id}`}>
                            {chapter.chapter.section.name}
                        </Breadcrumb.Item>
                        <Breadcrumb.Item active>
                            {formData.chapterTitle}
                        </Breadcrumb.Item>
                    </Breadcrumb>
                    <Header
                        title={chapter.info.editionName}
                        subtitle={formData.chapterTitle}/>
                </Fragment>
            } 
            {book &&
                <Fragment>
                    <Breadcrumb>
                        <Breadcrumb.Item href="/admin">Dashboard</Breadcrumb.Item>
                        <Breadcrumb.Item href="/admin/library">Books</Breadcrumb.Item>
                        <Breadcrumb.Item href={`/admin/library/list/${bookId}`}>
                            {book?.info?.editionName ?? ''}
                        </Breadcrumb.Item> 
                        <Breadcrumb.Item href={`/admin/library/list/${bookId}/${version}`}>
                            {book?.params?.filter((item) => item.code === 'VERSION')[0]?.value}
                        </Breadcrumb.Item>								
                        <Breadcrumb.Item href={`/admin/library/list/${book.id}/${book.version}/${sectionId}`}>
                            {book.sections.filter((section) => section.id === sectionId)[0]?.name ?? ''}
                        </Breadcrumb.Item>
                        <Breadcrumb.Item active>
                            New Chapter
                        </Breadcrumb.Item>
                    </Breadcrumb>
                    <Header
                        title={book.info.editionName}
                        subtitle={formData.chapterTitle}/>
                </Fragment>
            }            
            {/* Card */}
            <Card className="mb-3 ">                
                {/* Card body */}
                <Card.Body>
                    <Form id="book-chapter" onSubmit={handleSubmit}>
                        {/* Code  */}
                        <Form.Group className="mb-3">
                            <Form.Label htmlFor="chapterCode">Chapter Code</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Chapter Code"
                                id="chapterCode"
                                name="chapterCode"
                                value={formData.chapterCode}
                                onChange={handleChange}
                                required
                            />
                            <Form.Text className="text-muted">
                                Code used to get the chapter
                            </Form.Text>
                        </Form.Group>

                        {/* Rank  */}
                        <Form.Group className="mb-3">
                            <Form.Label htmlFor="chapterRank">Chapter Rank</Form.Label>
                            <Form.Control
                                type="number"
                                placeholder="Chapter Rank"
                                id="chapterRank"
                                name="chapterRank"
                                value={formData.chapterRank}
                                onChange={handleChange}
                                required
                            />
                            <Form.Text className="text-muted">
                                Rank used to sort chapters in ascending order
                            </Form.Text>
                        </Form.Group>

                        {/* Number  */}
                        <Form.Group className="mb-3">
                            <Form.Label htmlFor="chapterNumber">Chapter Number</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Chapter Code"
                                id="chapterNumber"
                                name="chapterNumber"
                                value={formData.chapterNumber}
                                onChange={handleChange}
                                required
                            />
                            <Form.Text className="text-muted">
                                Sample: I, II, III, 1, 2, ...
                            </Form.Text>
                        </Form.Group>  

                        {/* Title  */}
                        <Form.Group className="mb-3">
                            <Form.Label htmlFor="chapterTitle">Chapter Title</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Chapter Title"
                                id="chapterTitle"
                                name="chapterTitle"
                                value={formData.chapterTitle}
                                onChange={handleChange}
                                required
                            />
                            <Form.Text className="text-muted">
                                Write a 60 character book title.
                            </Form.Text>
                        </Form.Group>

                        {/* Summary  */}
                        <Form.Group className="mb-3" data-color-mode="light">
                            <Form.Label htmlFor="chapterSummary">Chapter Summary</Form.Label>
                            <MDEditor
                                type="text"
                                placeholder="Chapter Summary"
                                id="chapterSummary"
                                name="chapterSummary"
                                value={formData.chapterSummary}
                                onChange={handleSummaryChange}
                            />                                            
                        </Form.Group>

                        {/* Content  */}
                        <Form.Group className="mb-3" data-color-mode="light">
                            <Form.Label htmlFor="chapterContent">Chapter Content</Form.Label>
                            <MDEditor
                                type="text"
                                placeholder="Chapter Content"
                                id="chapterContent"
                                name="chapterContent"
                                value={formData.chapterContent}
                                onChange={handleContentChange}
                            />                                            
                        </Form.Group>
                    </Form>
                </Card.Body>
            </Card>
            {/* Button */}
            {!loading && 
                <Button variant="primary" type="submit" form="book-chapter">
                    Save
                </Button>
            }
            { loading && 
                <Button variant="primary" type="submit" form="book-chapter" disabled>
                    <Spinner
                        as="span"
                        animation="grow"
                        size="sm"
                        role="status"
                        aria-hidden="true"/>
                        {' '} Loading...
                </Button>
            }

            <Modal show={showGenerateModal} onHide={handleCloseGenerateModal} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Autofill</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form id="book-chapter-generator" onSubmit={handleGenerate}>
                        <Form.Group className="mb-3">
                            <Form.Label htmlFor="sourceUrl">URL</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Source URL"
                                id="sourceUrl"
                                name="sourceUrl"
                                value={sourceUrl}
                                onChange={e => setSourceUrl(e.target.value)}
                            />
                            <Form.Text className="text-muted">
                                URL used to extract information
                            </Form.Text>
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer className="d-flex justify-content-start border-0 pt-0">
                    {/*  Action Buttons  */}
                    {generateLoading &&
                        <Button variant="primary" type="submit" form='book-chapter-generator' disabled>
                            <Spinner
                                as="span"
                                animation="grow"
                                size="sm"
                                role="status"
                                aria-hidden="true"/>
                            {' '} Loading...
                        </Button>
                    }
                    {!generateLoading &&
                        <Button variant="primary" type="submit" form='book-chapter-generator'>
                            Autofill
                        </Button>
                    }									
                    <Button variant="white" onClick={handleCloseGenerateModal}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
		</Fragment>
	);
};

export default BookChapterEditor;
