// import node module libraries
import React, { Fragment } from 'react';

// import layouts
import { Redirect, useLocation } from 'react-router-dom';
import { useAuth } from 'hooks/useAuth';

const EmptyProtectedLayout = (props) => {
    const { user } = useAuth();
    const location = useLocation();

    if (!user) {
        return (
            <Redirect
              to={{ pathname: "/", state: { from: location } }}
              replace
            />
          );
    }

	return (
		<Fragment>
			{props.children}
		</Fragment>
	);
};

export default EmptyProtectedLayout;
