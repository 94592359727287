// import node module libraries
import React from 'react';

export const NoteCell = ({note, onEdit = (note) => {}, onDelete = (note) => {}}) => {
    const DEFAULT_COLOR = "#2196f3"

    const style = {
        borderLeft: "solid 0.3em gold",
        borderLeftColor: `${note.color ?? DEFAULT_COLOR}`,
        paddingLeft: "1rem",
        paddingRight: "1rem"
    }

    const handleEdit = (e) => {
        e.preventDefault()
        onEdit(note)
    }

    const handleDelete = (e) => {
        e.preventDefault()
        onDelete(note)
    }

	return (
        <div className='py-3 border-bottom' style={style}>            
            <div className="d-flex align-items-center">
                <div className='flex-auto'>
                    <h4 className="mb-0">
                        {note.element.number}. {note.element.name}
                    </h4>                           
                </div>   
                <a href="#" className='text-dark ms-3' onClick={handleEdit}><em className='fe fe-edit'></em></a>
                <a href="#" className='text-danger ms-3' onClick={handleDelete}><em className='fe fe-trash'></em></a>
            </div>
            <div
                className='mb-0 mt-1 text-muted'
                dangerouslySetInnerHTML={{
                    __html: note.text.replaceAll('\n', '<br>')
                }}
            ></div>
        </div>
	);
};

export default NoteCell;
