// import node module libraries
import { Fragment, useState, useEffect } from 'react';
import { Col, Row, Card, Tab, Breadcrumb, Spinner } from 'react-bootstrap';

// import custom components
import GridListViewButton from 'components/elements/miscellaneous/GridListViewButton';

// import sub components
import StudentsGridCard from './StudentsGridCard';
import StudentsListItems from './StudentsListItems';

// rest api
import { deleteUser, enableUser, getUsers } from 'services/api/KumajiroApi';

// to display success / error message
import Notify from 'services/Notify';

const Students = () => {
	const [activeTab, setActiveTab] = useState("grid")	

	const [loading, setLoading] = useState(false)
	const [students, setStudents] = useState([]);

	const handleDelete = (userId) => {
		setLoading(true)
		deleteUser(userId)
			.then((response) => {
				setLoading(false)
				refreshData()
			})
			.catch((error) => {
				setLoading(false)
				Notify.error(error.response?.data?.message ?? "Failed to delete user")
			})
	};

	const handleEnable = (userId, enable) => {
		setLoading(true)
		enableUser(userId, enable)
			.then((response) => {
				setLoading(false)
				refreshData()
			})
			.catch((error) => {
				setLoading(false)
				Notify.error(error.response?.data?.message ?? (enable ? "Failed to enable user" : "Failed to disable user"))
			})
	};

	const refreshData = () => {
		setLoading(true)
		getUsers()
			.then((response) => {
				setLoading(false)
				setStudents(response.data)
			})
			.catch((error) => {
				setLoading(false)
				setStudents([])
				Notify.error(error.response?.data?.message ?? "An error occured")
			});
	};

	useEffect(() => {
		refreshData()
	}, [])

	return (
		<Fragment>
			<Tab.Container defaultActiveKey={activeTab} activeKey={activeTab}>
				<Row>
					<Col lg={12} md={12} sm={12}>
						<div className="border-bottom pb-4 mb-4 d-flex align-items-center justify-content-between">
							<div className="mb-3 mb-md-0">
								<h1 className="mb-1 h2 fw-bold">
									Students 
									{loading === true &&
										<Spinner animation="border" variant="primary" size="sm" className='ms-2' />
									}
									{loading === false &&
										<span className="fs-5 text-muted">({students.length})</span>
									}
								</h1>
								<Breadcrumb>
									<Breadcrumb.Item href="/admin">Dashboard</Breadcrumb.Item>
									<Breadcrumb.Item href="#">User</Breadcrumb.Item>
									<Breadcrumb.Item active>Students</Breadcrumb.Item>
								</Breadcrumb>
							</div>
							<div>
								<GridListViewButton keyGrid="grid" keyList="list" onChange={setActiveTab} />
							</div>
						</div>
					</Col>
				</Row>

				<Tab.Content>
					<Tab.Pane eventKey="grid" className="pb-4">
						{/* students in list view */}
						<StudentsGridCard items={students} />
						{/* end of students in list view */}
					</Tab.Pane>
					<Tab.Pane eventKey="list" className="pb-4">
						<Card className="mb-5 ">
							<Card.Body className="p-0">
								{/* students in list view */}
								<StudentsListItems items={students} onDelete={handleDelete} onEnable={handleEnable} />
								{/* end of students in list view */}
							</Card.Body>
						</Card>
					</Tab.Pane>
				</Tab.Content>
			</Tab.Container>
		</Fragment>
	);
};
export default Students;
