// import node module libraries
import React from 'react';
import { useState } from 'react';
import { Button, Form, Toast } from 'react-bootstrap';

// import components
import { CirclePicker } from 'react-color';

const NoteEditor = ({text, color, onChangeText = (text) => {}, onChangeColor = (color) => {}, onSubmit = () => {}}) => {    
    const [showColorPicker, setShowColorPicker] = useState(false);

    const toggleColorPicker = () => setShowColorPicker(!showColorPicker);
    
    const handleSubmit = (e) => {
        e.preventDefault()
        setShowColorPicker(false)              
        onSubmit()
    }

    const handleColorSelected = (color, event) => {
        console.log(color)
        setShowColorPicker(false)
        onChangeColor(color.hex)
    }

    return (
        <Form onSubmit={handleSubmit} className="bg-light px-4 py-3 position-absolute bottom-0 start-0 end-0">
            <Toast className="mb-4 position-absolute bottom-0" show={showColorPicker} onClose={toggleColorPicker} style={{zIndex: 2}}>
                <Toast.Header>
                    <strong className="me-auto">Select a color</strong>
                </Toast.Header>
                <Toast.Body>
                    <CirclePicker 
                        onChangeComplete={handleColorSelected} 
                        color={color} />
                </Toast.Body>
            </Toast>
            <div className="bg-white p-2 rounded-3 shadow-sm">
                <div className="position-relative ps-3 pe-4">
                    <Form.Control
                        as="textarea"
                        placeholder="Type a New Note"
                        id="Excerpt"
                        value={text}
                        onChange={e => onChangeText(e.target.value)}
                        className="form-control border-0 form-control-simple no-resize"
                        style={{ height: '50px' }}
                    />
                </div>
                <div className="position-absolute start-0 mt-n7 ms-4">                
                    <Button
                        variant="none"                        
                        className='fs-3 btn-focus-none px-2'
                        onClick={toggleColorPicker}
                        style={{color: color}}
                    >
                        <i className="fe fe-circle"></i>
                    </Button>
                </div>
                <div className="position-absolute end-0 mt-n7 me-4">                
                    <Button
                        variant="none"
                        bsPrefix="btn"
                        type="submit"
                        className={`fs-3 ${text.length > 0 ? 'text-primary' : 'text-secondary'} btn-focus-none px-2`}
                    >
                        <i className="fe fe-send"></i>
                    </Button>
                </div>
            </div>
        </Form>
    );
}

export default NoteEditor;