import SecureStorage from "./storage/SecureStorage";

const getLocalRefreshToken = () => {
  const user = JSON.parse(SecureStorage.getItem("user"));
  return user?.jwt?.refreshToken;
};

const getLocalAccessToken = () => {
  const user = JSON.parse(SecureStorage.getItem("user"));
  return user?.jwt?.token;
};

const getLocalAssetsToken = () => {
  const user = JSON.parse(SecureStorage.getItem("user"));
  return user?.jwt?.assetsToken;
};

const updateLocalAccessToken = (token, assetsToken) => {
  let user = JSON.parse(SecureStorage.getItem("user"));
  user.jwt.token = token;
  user.jwt.assetsToken = assetsToken
  SecureStorage.setItem("user", JSON.stringify(user));
};

const updateLocalUserInfo = (firstname, lastname) => {
  let user = JSON.parse(SecureStorage.getItem("user"));
  user.user.firstname = firstname;
  user.user.lastname = lastname;
  SecureStorage.setItem("user", JSON.stringify(user));
}

const updateLocalMail = (mail) => {
  let user = JSON.parse(SecureStorage.getItem("user"));
  user.user.email = mail;
  SecureStorage.setItem("user", JSON.stringify(user));
}

const getUser = () => {
  return JSON.parse(SecureStorage.getItem("user"));
};

const setUser = (user) => {
  SecureStorage.setItem("user", JSON.stringify(user));
};

const removeUser = () => {
  SecureStorage.removeItem("user");
};

const UserSession = {
  getLocalRefreshToken,
  getLocalAccessToken,
  getLocalAssetsToken,
  updateLocalAccessToken,
  updateLocalUserInfo,
  updateLocalMail,
  getUser,
  setUser,
  removeUser
};

export default UserSession;