// import node module libraries
import React from 'react';
import { Fragment } from 'react';
import { Col, Row, Container } from 'react-bootstrap';

// import custom components
import BookSlider from 'components/marketing/pages/books/BookSlider';
import HeroHeader from 'components/marketing/pages/courses/course-index/HeroHeader';
import CourseSlider from '../CourseSlider';

const CourseIndex = () => {
	return (
		<Fragment>
			{/*  Page Content  */}
			<HeroHeader />

			{
				/*  Features list  */
				/*<FeaturesList />*/
			}			

			<div className="pt-lg-12 pb-lg-3 pt-8 pb-6">
				<Container>
					<Row className="mb-4">
						<Col>
							<h2 className="mb-0 mx-2">New content</h2>
						</Col>
					</Row>
					<div className="position-relative">
						<CourseSlider newest={true} />
					</div>
				</Container>
			</div>

			<div className="pb-lg-3 pt-lg-3">
				<Container>
					<Row className="mb-4">
						<Col>
							<h2 className="mb-0 mx-2">Video courses</h2>
						</Col>
					</Row>
					<div className="position-relative">
						<CourseSlider videoCourses={true} />
					</div>
				</Container>
			</div>

			<div className="pb-lg-3 pt-lg-3">
				<Container>
					<Row className="mb-4">
						<Col>
							<h2 className="mb-0 mx-2">Most Popular</h2>
						</Col>
					</Row>
					<div className="position-relative">
						<CourseSlider popular={true} />
					</div>
				</Container>
			</div>

			{
				/*<div className="pb-lg-3 pt-lg-3">
					<Container>
						<Row className="mb-4">
							<Col>
								<h2 className="mb-0 mx-2">Reading books</h2>
							</Col>
						</Row>
						<div className="position-relative">
							<BookSlider reading={true} />
						</div>
					</Container>
				</div>*/
			}

			<div className="pb-lg-3 pt-lg-3">
				<Container>
					<Row className="mb-4">
						<Col>
							<h2 className="mb-0 mx-2">Recommended to you</h2>
						</Col>
					</Row>
					<div className="position-relative">
						<BookSlider recommended={true} />
					</div>
				</Container>
			</div>
		</Fragment>
	);
};

export default CourseIndex;
