import React from "react";

import { getPlayerUrl } from "services/api/KumajiroApi";

import ReactPlayer from "react-player";

const VideoPlayer = ({url, cachable = false, playing = true, controls = true, onProgress = (e) => {}}) => {
    let videoUrl = url
    if (cachable === true && url && url.startsWith('http') === true) {
        videoUrl = getPlayerUrl(url)
    }
    return (
        <ReactPlayer
            playing={playing}
            controls={controls}
            url={videoUrl}
            className='react-player'
            width='100%'
            height='100%'
            onProgress={onProgress} /> 
    )    
};

export default React.memo(VideoPlayer)