// import node module libraries
import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';

// import sub components
import BookCard from './BookCard';
import { Alert, Spinner } from 'react-bootstrap';

// import api rest
import { getReadingBooks, getRecommendedBooks } from 'services/api/KumajiroApi';

const BookSlider = ({ recommended, reading }) => {
	const [books, setBooks] = React.useState()
	const [emptyMessage, setEmptyMessage] = React.useState('')

	useEffect(() => {
		if (recommended) {
			setEmptyMessage('No books')
			getRecommendedBooks()
                .then(res => {
					if (res.status === 204) {
						setBooks([])
					} else {
						setBooks(res.data)
					}
                })
                .catch((error) => {
                    setBooks([])
                });
		} else if (reading) {
			setEmptyMessage('You haven\'t started reading a book yet')
			getReadingBooks()
                .then(res => {
					if (res.status === 204) {
						setBooks([])
					} else {
						setBooks(res.data)
					}
                })
                .catch((error) => {
                    setBooks([])
                });
		}
	  }, [recommended, reading])

	const settings = {
		infinite: false,
		slidesToShow: 4,
		slidesToScroll: 1,
		responsive: [
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 4,
					slidesToScroll: 1
				}
			},
			{
				breakpoint: 768,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 1,
					initialSlide: 2
				}
			},
			{
				breakpoint: 540,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1
				}
			}
		]
	};
	
	return (
		<Fragment>
			{!books &&
				<div className="d-flex align-items-center justify-content-center">
					<Spinner animation="border" variant="primary" />
				</div>
			}
			{books && books.length > 0 &&
				<Slider {...settings} className="pb-sm-5 mb-5 slick-slider-wrapper">
					{books.map((item, index) => (
						<div className="item px-md-1" key={item.id}>
							<BookCard
								key={index} 
								item={item} 
								extraclass="mx-2" 
								cachable={true} />
						</div>
					))}
				</Slider>
			}

			{books && books.length === 0 &&
				<Alert variant="primary" className='mb-5'>{emptyMessage}</Alert>
			}
		</Fragment>
	);	
};

// Specifies the default values for props
BookSlider.defaultProps = {
	recommended: false,
	reading: false,
	category: null
};

// Typechecking With PropTypes
BookSlider.propTypes = {
	recommended: PropTypes.bool,
	reading: PropTypes.bool,
	category: PropTypes.string
};

export default BookSlider;
