// import node module libraries
import { useEffect } from 'react';
import { Fragment, useState, useRef } from 'react';
import { Form } from 'react-bootstrap';

// rest api
import { createCategory, getCategory, updateCategory } from 'services/api/KumajiroApi';

// to display success/error messages
import Notify from 'services/Notify';

const AddNewCategoryPopup = ({categoryId, onLoading = (isLoading) => {}, onSubmited = (category) => {}}) => {

	const INITIAL_STATE = {
		categoryName: '',
		categoryCode: '',
		categoryRwId: '',
		categoryDescription: '',
		categoryRank: ''
	};

	const [form, setForm] = useState(INITIAL_STATE);

	const componentMounted = useRef(true);

	useEffect(() => {
		componentMounted.current = true
		if (categoryId) {
			onLoading(true)
			getCategory(categoryId)
				.then(response => {
					if (componentMounted.current === false) return
					onLoading(false)
					const category = response.data
					setForm({
						categoryName: category.name,
						categoryCode: category.code,
						categoryRwId: category.rwId,
						categoryDescription: category.description,
						categoryRank: category.rank
					})
				})
				.catch(error => {
					if (componentMounted.current === false) return
					onLoading(false)
					Notify.error(error.response?.data?.message ?? "Failed to get data")
				})
		}

		return () => {
            componentMounted.current = false;
        }
	}, [categoryId]);

	const handleSubmit = (event) => {
		event.preventDefault();

		onLoading(true)
		let promise
		if (categoryId) {
			promise = updateCategory(
				categoryId,
				form.categoryName,
				form.categoryCode,
				form.categoryRwId,
				form.categoryDescription,
				form.categoryRank
			)
		} else {
			promise = createCategory(
				form.categoryName,
				form.categoryCode,
				form.categoryRwId,
				form.categoryDescription,
				form.categoryRank
			)
		}
		promise.then((response) => {
			onLoading(false)			
			if (categoryId) {
				Notify.success("Category updated")
			} else {
				Notify.success("Category created")
			}			
			onSubmited(response.data)
		})
		.catch((error) => {
			onLoading(false)
			Notify.error(error.response?.data?.message ?? "An error occured")
		});
	};

	const handleChange = (event) => {
		setForm({
		  ...form,
		  [event.target.id]: event.target.value,
		});
	};

	const handleDescriptionChange = (text) => {
		form.categoryDescription = text;
	};

	return (
		<Fragment>
			{/*  Form  */}
			<Form onSubmit={handleSubmit} id="create-category-form">
				{/* Title  */}
				<Form.Group className="mb-3">
					<Form.Label>Title</Form.Label>
					<Form.Control
						type="text"
						placeholder="Category name"
						id="categoryName"
						value={form.categoryName}
						onChange={handleChange}
						required
					/>
					<Form.Text className="text-muted">
						Field must contain a unique value
					</Form.Text>
				</Form.Group>

				{/* Code  */}
				<Form.Group className="mb-3">
					<Form.Label>Code</Form.Label>
					<Form.Control
						type="text"
						placeholder="Path in the url"
						id="categoryCode"
						value={form.categoryCode}
						onChange={handleChange}
						required
					/>
					<Form.Text className="text-muted">
						Field must contain a unique value
					</Form.Text>
				</Form.Group>

				{/* RW id  */}
				<Form.Group className="mb-3">
					<Form.Label>RW id</Form.Label>
					<Form.Control
						type="text"
						placeholder="RW id"
						id="categoryRwId"
						value={form.categoryRwId}
						onChange={handleChange}
					/>
					<Form.Text className="text-muted">
						Field must contain a unique value
					</Form.Text>
				</Form.Group>	

				{/* RW id  */}
				<Form.Group className="mb-3">
					<Form.Label>Description</Form.Label>
					<Form.Control
						type="text"
						placeholder="RW id"
						id="categoryDescription"
						value={form.categoryDescription}
						onChange={handleChange}
					/>
					<Form.Text className="text-muted">
						Field must contain a unique value
					</Form.Text>
				</Form.Group>				

				{/* RW id  */}
				<Form.Group className="mb-3">
					<Form.Label>Rank</Form.Label>
					<Form.Control
						type="number"
						placeholder="Rank"
						id="categoryRank"
						value={form.categoryRank}
						onChange={handleChange}
					/>
					<Form.Text className="text-muted">
						Field must contain a unique value
					</Form.Text>
				</Form.Group>
			</Form>
		</Fragment>
	);
};

export default AddNewCategoryPopup;
