// import node module libraries
import { Fragment, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Col, Row, Container, Spinner, Tab, Nav } from 'react-bootstrap';

// import custom components
import PageHeadingBriefinfo from 'components/marketing/common/page-headings/PageHeadingBriefinfo';

// import sub components
import CourseCard from '../CourseCard';
import BookCard from '../../books/BookCard';

// import rest api
import { getCategory, getCategoryBooks, getCourses } from 'services/api/KumajiroApi';

// for error/success toast messages
import Notify from 'services/Notify';

const CourseCategory = () => {
	const { categoryId } = useParams();

	const [category, setCategory] = useState()
	const [books, setBooks] = useState()
	const [latestCourses, setLatestCourses] = useState()
	const [popularCourses, setPopularCourses] = useState()

	const history = useHistory();

	useEffect(() => {
		setCategory(null)		
		getCategory(categoryId)
		  .then(res => {
			setCategory(res.data)
		  })
		  .catch((error) => {
			if (error.response?.status === 404) {
				history.replace("/404-error", { replace: true });
			} else {
				history.replace("/500-error", { replace: true });
			}			
		  });
	}, [categoryId, history])

	useEffect(() => {
		setBooks(null)
		getCategoryBooks(categoryId)
		  .then(res => {
			if (res.status === 204) {
				setBooks([])
			} else {
				setBooks(res.data)
			}
		  })
		  .catch((error) => {
			Notify.error("Failed to get data")
			setBooks([])
		  });
	}, [categoryId])

	useEffect(() => {
		if(!category) {
			setPopularCourses(null)
			return
		}		
		getCourses(
			1,
			8,
			[],
			[category.id],
			[], 
			[], 
			'-popularity',
			''
		)
		.then(res => {
			if (res.status === 204) {
				setPopularCourses([])
			} else {
				setPopularCourses(res.data.data)
			}
		})
		.catch((error) => {
			setPopularCourses([])
		});		
	}, [category])

	useEffect(() => {
		if(!category) {
			setLatestCourses(null)
			return
		}
		getCourses(
			1,
			8,
			[],
			[category.id],
			[], 
			[], 
			'-released_at',
			''
		)
		.then(res => {
			if (res.status === 204) {
				setLatestCourses([])
			} else {
				setLatestCourses(res.data.data)
			}
		})
		.catch((error) => {
			setLatestCourses([])
		});
	}, [category])

	return (
		<Fragment>
			{/* Page header */}
			{category &&
				<PageHeadingBriefinfo
					pagetitle={category.name}
					briefinfo={category.description} />
			}	
			{/* Page header with loading indicator if data are loading */}	
			{!category &&
				<div className="pt-lg-8 pb-lg-16 pt-8 pb-12 bg-primary">
					<Container>
						<Row className="align-items-center">
							<div className="d-flex align-items-center justify-content-center">
								<Spinner animation="border" variant="light" />															
							</div>
						</Row>
					</Container>
				</div> 
			}

			<div className="py-6">
				<Container>
					{/* Courses */}
					<Row className="mb-6">
						<Col md={12}>
							<Tab.Container defaultActiveKey="newest">
								<Nav className="nav-lb-tab">									
									<Nav.Item>
										<Nav.Link eventKey="newest" className="mb-sm-3 mb-md-0">
											New content
										</Nav.Link>
									</Nav.Item>
									<Nav.Item className="ms-0">
										<Nav.Link
											eventKey="mostpopular"
											className="mb-sm-3 mb-md-0"
										>
											Most Popular
										</Nav.Link>
									</Nav.Item>
								</Nav>

								<Tab.Content>
									<Tab.Pane
										eventKey="mostpopular"
										className="pb-4 p-4 ps-0 pe-0"
									>
										{/* most popular started */}
										<Row>
											{!popularCourses &&
												<Col className="align-items-center">
													<div className="d-flex align-items-center justify-content-center">
														<Spinner animation="border" variant="primary" />
													</div>
												</Col>
											}
											{popularCourses?.map((item, index) => (
												<Col lg={3} md={6} sm={12} key={index}>
													<CourseCard item={item} />
												</Col>
											))}
											{popularCourses?.length === 0 && 
												<p>No content</p>
											}
										</Row>
										{/* end of most popular */}
									</Tab.Pane>
									<Tab.Pane eventKey="newest" className="pb-4 p-4 ps-0 pe-0">
										{/* trending courses started */}
										<Row>
											{!latestCourses &&
												<Col className="align-items-center">
													<div className="d-flex align-items-center justify-content-center">
														<Spinner animation="border" variant="primary" />
													</div>
												</Col>
											}
											{latestCourses?.map((item, index) => (
												<Col lg={3} md={6} sm={12} key={index}>
													<CourseCard item={item} />
												</Col>
											))}
											{latestCourses?.length === 0 && 
												<p>No content</p>
											}
										</Row>
										{/* end of trending courses */}
									</Tab.Pane>
								</Tab.Content>
							</Tab.Container>
						</Col>
					</Row>
					
					{/* Books */}
					<Row>
						<Col lg={12} md={12} sm={12}>
							<div className="mb-5">
								<h2 className="mb-1">All {category?.name ?? ''} books</h2>
								<p className="mb-0">
									
								</p>
							</div>
						</Col>
					</Row>
					<Row className="mb-6">
						{books?.map((item, index) => (
							<Col lg={3} md={6} sm={12} key={index}>
								<BookCard item={item} cachable={true} />
							</Col>
						))}
						{!books &&
							<Col className="align-items-center">
								<div className="d-flex align-items-center justify-content-center">
									<Spinner animation="border" variant="primary" />
								</div>
							</Col>
						}
					</Row>
					{/* end Books */}					
				</Container>
			</div>
		</Fragment>
	);
};

export default CourseCategory;
