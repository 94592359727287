// import node module libraries
import MarkdownToHtml from 'components/elements/markdown-html/MarkdownToHtml';
import { Fragment } from 'react';
import { Col, Row, ListGroup } from 'react-bootstrap';

const DescriptionTab = ({book}) => {
	return (
		<Fragment>
			<div className="mb-4">
				<MarkdownToHtml
                    content={book.info.summary}
					cachable={true}/>				
			</div>
			{book.sections.map((section) => {      
				return (
					<div key={section.id}>
						<h4 className="mb-3 mt-5">{section.name}</h4>
						<Row className="mb-3">
							<Col>
								<ListGroup bsPrefix="list-unstyled" variant="flush">
								{
									section.chapters.map((chapter) => {
										return (
											<ListGroup.Item bsPrefix=" " className="mb-2" key={chapter.id}>
												<h5><span className="far fa-check-circle text-success me-2 mt-2"></span>{chapter.name}</h5>
												<MarkdownToHtml
													content={chapter.summary}
													cachable={true}/>	
											</ListGroup.Item>										
										);
									})
								}
								</ListGroup>
							</Col>
						</Row>
					</div>
				)
			})}						
		</Fragment>
	);
};
export default DescriptionTab;
