// import node module libraries
import React, { useState, useEffect, Fragment } from 'react';
import { Col, Row, Card, Button, Spinner } from 'react-bootstrap';
import { Link, useHistory, useParams } from 'react-router-dom';
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';
import Tippy from '@tippyjs/react';

// import custom components
import NavBarReaderDefault from 'layouts/marketing/navbars/NavBarReaderDefault';
import MarkdownToHtml from 'components/elements/markdown-html/MarkdownToHtml';
import BookToc from '../BookToc';

// import api rest
import { getBookChapter, updateBookUserProgress } from 'services/api/KumajiroApi';

// for error/success toast message
import Notify from 'services/Notify';

// import hooks
import { useLocalStorage } from 'hooks/useLocalStorage';
import { BookReaderNote } from './notes/BookReaderNote';

// The forwardRef is important!!
// Dropdown needs access to the DOM node in order to position the Menu
const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
	<Link
		to="#"
		ref={ref}
		onClick={(e) => {
			e.preventDefault();
			onClick(e);
		}}
	>
		{children}
	</Link>
));

export const BookReader = (props) => {
    const { className, overflowHidden } = props;
	
	const { bookId, chapterId } = useParams();
	
	const [chapter, setChapter] = useState(null)
	const [loadingUserProgress, setLoadingUserProgress] = useState(false)	
    const [showMenu, setShowMenu] = useLocalStorage("show-left-menu", true);
	const [completed, setCompleted] = useState(false)
	const [showContentLoading, setContentLoading] = useState(true);

	const [showNotes, setShowNotes] = useState(false);
	const handleCloseNotes = () => setShowNotes(false);
	const handleShowNotes = () => setShowNotes(true);

	const history = useHistory();

	const ToggleMenu = () => {
		return setShowMenu(!showMenu);
	};

	const goToThePreviousChapter = () => {
		history.push(`/library/${chapter.code}/${chapter.previousChapter.code}`)
	};

	const goToTheNextChapter = () => {
		history.push(`/library/${chapter.code}/${chapter.nextChapter.code}`)
	};

	const onUpdateProgress = (chapterId, completed) => {
		if (chapter.chapter?.id === chapterId || chapter.chapter?.code === chapterId) {
			// If the user pressed the completed button of the current chapter,
			// update the completed button in the reader.
			setCompleted(completed)
		}
	}

    const handleCompletedClick = (event) => {
        event.preventDefault()
        setLoadingUserProgress(true)
        updateBookUserProgress(chapter.chapter.id, !completed)
            .then(res => {
				setLoadingUserProgress(false)
				// Update the table of content
				let newChapter = chapter
				newChapter.sections = newChapter.sections.map((section) => {
					section.chapters = section.chapters.map((item) => {
						if (item.id === chapter.chapter.id) {
							item.completed = !completed
						}
						return item
					});				
					return section;		
				});				
				setChapter(newChapter)
				Notify.success(`Chapter marked as ${!completed ? "completed" : "uncompleted"}`)
                // Update the completed button
                setCompleted(!completed)				
            })
            .catch((error) => {
				Notify.error("Failed to update the progress")
                setLoadingUserProgress(false)
            });
    }

	useEffect(() => {
		setContentLoading(true)
		getBookChapter(bookId, chapterId)
			.then(res => {
				setContentLoading(false)
				setChapter(res.data)
				setCompleted(res.data.chapter.completed)
			})
			.catch((error) => {
				setContentLoading(false)
				if (error.response?.status === 404) {
					history.replace("/404-error", { replace: true });
				} else {
					history.replace("/500-error", { replace: true });
				}
			});       
    }, [bookId, chapterId])

	return (
        <div
			id="db-wrapper"
			className={`${overflowHidden ? 'chat-layout' : ''} ${
				showMenu ? '' : 'toggled'
			}`}
		>
			<div className="navbar-vertical navbar">
                <Card className="course-sidebar " id="courseAccordion">
                    <SimpleBar style={{ maxHeight: '100vh' }}>
                        <Card>
                            <Card.Header>
                                <h4 className="mb-0">
								{chapter == null &&
									'Table of content'
								}
								{chapter != null &&
									<Link className='text-inherit text-decoration-none' to={`/library/${bookId}`}>{chapter.info.editionName}</Link>
								}
								</h4>
                            </Card.Header>
                            {/* Course Index Accordion */}
							{chapter != null &&
                            	<BookToc 
									book={chapter}
									onUpdateProgress={onUpdateProgress} />
							}
                        </Card>
                    </SimpleBar>
                </Card>
			</div>
			<div id="page-content">
				<div className="header">
					<NavBarReaderDefault
						showMenu={showMenu}
						SidebarToggleMenu={ToggleMenu}
                        bookId={bookId}
					/>
				</div>
				<div className={`container-fluid ${className ? className : 'p-4'}`}>
					<Row>
						<Col className="col-lg-8 mt-4 mx-10 mt-lg-0 mx-auto">
							{showContentLoading === true &&
								<div className="d-flex align-items-center justify-content-center" style={{height: "40vh"}}>
									<Spinner animation="border" variant="primary" />
								</div>								
							}
							{showContentLoading === false && chapter &&
								<Fragment>
									<article>            
										<MarkdownToHtml
											content={
												"# " + chapter.chapter.number + ". " + chapter.chapter.name + "\r\n\r\n" +
												chapter.chapter.content
											}
											cachable={true}/>
									</article>
									<div className="mt-6 mb-10 text-center">
										<Button variant={completed === true ? "primary" : "outline-primary"} className="me-1" disabled={loadingUserProgress === true} onClick={handleCompletedClick}>
											{loadingUserProgress === true && 
												<>
													<Spinner
														as="span"
														animation="grow"
														size="sm"
														role="status"
														aria-hidden="true"/>
													{' '}Progress
												</>
											} 
											{loadingUserProgress === false &&
												<><i className="fe fe-check"></i> {completed ? "Completed" : "Mark complete"}</>
											}
										</Button> 
									</div>	
									{chapter?.nextChapter &&
										<div className="border-top pt-2 mt-4 text-center">
											<Button variant="none" className="me-1" onClick={goToTheNextChapter}>{chapter.nextChapter.number}. {chapter.nextChapter.name} <i className="fe fe-arrow-right"></i></Button>
										</div>
									}
									{chapter?.previousChapter &&
										<div className="border-top pt-2 mt-2 text-center">
											<Button variant="none" className="me-1" onClick={goToThePreviousChapter}><i className="fe fe-arrow-left"></i> {chapter.previousChapter.number}. {chapter.previousChapter.name}</Button>
										</div>
									}
								</Fragment>
							}
						</Col>
					</Row>
				</div>
			</div>			
			{showContentLoading === false && chapter &&
				<ul className="nav flex-column position-fixed end-0 mt-12 d-none d-lg-block">				
					{chapter.nextChapter &&
						<li className="nav-item">
							<Tippy
								placement="left"
								content={chapter.nextChapter.number + ". " + chapter.nextChapter.name}
							>
								<Button variant="none" size="lg" className='me-1' onClick={goToTheNextChapter}>
									<i className="fe fe-arrow-right"></i>
								</Button>
							</Tippy>	
						</li>
					}
					{chapter.previousChapter &&
						<li className="nav-item">
							<Tippy
								placement="left"
								content={chapter.previousChapter.number + ". " + chapter.previousChapter.name}
							>
								<Button variant="none" size="lg" className='me-1' onClick={goToThePreviousChapter}>
									<i className="fe fe-arrow-left"></i>
								</Button>
							</Tippy>									
						</li>
					}
					<li className="nav-item">
						<Tippy
								placement="left"
								content="Notes"
							>
							<Button variant="none" size="lg" className='me-1' onClick={handleShowNotes}>
								<i className="fe fe-edit-2"></i>
							</Button>
						</Tippy>									
					</li>		
				</ul>				
			}
			<BookReaderNote show={showNotes} onHide={handleCloseNotes} placement="end"/>              
		</div>
		
	);
};

export default BookReader;
