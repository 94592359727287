import React, { useEffect, useRef, useState } from "react";

import { getMedia } from "services/api/KumajiroApi";

import ImageLoader from "./ImageLoader";

import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'

import Placeholder from 'assets/images/placeholder/placeholder.png';

const LazyImage = ({src, className, alt, zoomable = false, cachable = false, effect = null}) => {
    const [image, setImage] = useState(null);

    //prevent memory leaks
    const componentMounted = useRef(true);

    const updateImage = (source) => {
        if (componentMounted.current) {
            setImage(source)
        }
    }

    useEffect(() => {
        componentMounted.current = true
        if (!src) {
            updateImage(Placeholder)
        } else if (cachable === true && src && src.startsWith('http') === true) {
            getMedia(src)
                .then(source => {
                    let file = new File([source.data], null, { type: source.type })
				    updateImage(URL.createObjectURL(file))
                })
                .catch(err => {
                    updateImage('/assets/images/placeholder/placeholder-img.jpg')
                })
        } else {
            updateImage(src)
        }

        return () => {
            componentMounted.current = false;
        }
    }, [src, cachable])
    
    if (image == null) {
        return (
            <ImageLoader className={className} />
        )        
    } else if (zoomable) {
        return (
            <Zoom>
                <LazyLoadImage 
                    alt={alt}
                    src={image} 
                    className={className}
                    placeholderSrc={Placeholder}
                    effect={effect} />  
            </Zoom>
        )
    } else {
        return (
            <LazyLoadImage 
                alt={alt}                    
                src={image} 
                className={className}
                placeholderSrc={Placeholder}
                effect={effect} /> 
        )
    }
};

export default React.memo(LazyImage)