// import node module libraries
import { Fragment } from 'react';

const MaterialsTab = ({course}) => {
	return (
		<Fragment>
			<h3 className="mb-3">Course - Materials</h3>
            {course.downloadMaterialUrl &&
                <a className="btn btn-primary" href={course.downloadMaterialUrl}>                
                    Download Materials
                </a> 
            }
            {!course.downloadMaterialUrl &&
                <p>No material to download</p>
            }
		</Fragment>
	);
};
export default MaterialsTab;
