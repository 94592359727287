// import node module libraries
import { Fragment, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Col, Row, Card, Form, Button, Image, Spinner } from 'react-bootstrap';

// import sub-components
import DarkLightMode from 'layouts/DarkLightMode';

// import media files
import Logo from 'assets/images/brand/logo/logo.svg';

// for manage user session
import { useAuth } from 'hooks/useAuth';

// to handle go back navigation
import { useHistory } from "react-router-dom";

// rest api
import { verifyOtp } from 'services/api/KumajiroApi';

// to display success/error message
import Notify from 'services/Notify';

const MAX_OTP_DURATION = 60

const Otp = () => {
	const { auth2Token, login } = useAuth();

	const history = useHistory();

	const [otp, setOtp] = useState('');
	const [loading, setLoading] = useState(false);	
    const [counter, setCounter] = useState(60);

    useEffect(() => {
        const TimerInt = counter > 0 && setInterval(() => {
            const time = MAX_OTP_DURATION - Math.round((new Date().getTime() - auth2Token.startTime) / 1000)
            if (time <= 0) {
                Notify.error('Time expired')
				goBack()
                return
            }
            setCounter(time);
        }, 1000);
        return () => {
            clearInterval(TimerInt)
        }
    }, [counter]);

	const handleSubmit = (event) => {
		event.preventDefault();
		setLoading(true)
		verifyOtp(auth2Token.token, otp)
			.then(res => {
				setLoading(false)
				login(res.data);
			})
			.catch((error) => {
				Notify.error(error.response?.data?.message ?? "An error occured")
				if (error.response?.status === 403) {
					goBack()
				} else {
					setLoading(false)	
					setOtp('');
				}								
			});
  	};	

	const goBack = () => {
		history.replace("/", { replace: true });
	}

	return (
		<Fragment>
			<div className='position-absolute top-0 end-0 me-2 mt-2 d-none d-lg-block'>
				<DarkLightMode />
			</div>	
			<Row className="align-items-center justify-content-center g-0 min-vh-100">
				<Col lg={5} md={5} className="py-8 py-xl-0">
					<Card>
						<Card.Body className="p-6">
							<div className="mb-4">
								<Link to="/">
									<Image src={Logo} className="mb-4 brand" alt="" />
								</Link>
								<h1 className="mb-1 fw-bold">Sign in</h1>
								<span>
									Please open the Google Authenticator app and enter the secret code
								</span>
							</div>							
							{/* Form */}
							<Form onSubmit={handleSubmit}>								
								<Row>
									<Col lg={12} md={12} className="mb-3">
										{/* Username or email */}
										<Form.Label>Secret code </Form.Label>
										<Form.Control
											value={otp}
											onChange={e => setOtp(e.target.value)}
											type="text"
											id="otp"
											placeholder="Secret code here"
											required
										/>
									</Col>	
                                    <Col lg={12} md={12} className="mb-3">
                                        <p>Time left: {counter} seconds</p>
                                    </Col>								
									<Col lg={12} md={12} className="mb-0 d-grid gap-2">
										{/* Button */}
										{ !loading && 
											<Button variant="primary" type="submit">
												Sign in
											</Button>
										}
										{ loading && 
											<Button variant="primary" type="submit" disabled>
												<Spinner
													as="span"
													animation="grow"
													size="sm"
													role="status"
													aria-hidden="true"/>
												 {' '} Loading...
											</Button>
										}
                                        <Button variant='outline-primary' onClick={e => goBack()}>
                                            Cancel
                                        </Button>
									</Col>
								</Row>
							</Form>							
						</Card.Body>
					</Card>
				</Col>
			</Row>
		</Fragment>
	);
};

export default Otp;
