// import node module libraries
import { Fragment, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Col, Row, Breadcrumb, Card, Image, Spinner, Button } from 'react-bootstrap';

// import custom components
import RwLogoImage from 'assets/images/avatar/avatar-rw.png';
import CheckedMark from 'assets/images/svg/checked-mark.svg';

// rest api
import { deleteRwSession, getRwSsoState, getRwSsoUrl, updateRwSession } from 'services/api/KumajiroApi';

// to display success / error messages
import Notify from 'services/Notify';

const GeneralSetting = () => {
	const [rwSsoActive, setRwSsoActive] = useState(false);
	const [rwPremium, setRwPremium] = useState(false);
	const [loading, setLoading] = useState(false);

	const history = useHistory();

	const refreshState = () => {
		setLoading(true)
		getRwSsoState()
			.then((response) => {				
				setLoading(false)
				const data = response.data
				setRwSsoActive(data.active)
				setRwPremium(data.premium)
			})
			.catch((error) => {				
				setLoading(false)
				setRwSsoActive(false)
				setRwPremium(false)
				Notify.error(error.response?.data?.message ?? 'Failed to data')
			});
	};

	const handleLinkRwAccount = () => {
		setLoading(true)
		getRwSsoUrl()
			.then((response) => {
				setLoading(false)
				window.location = response.data.url
			})
			.catch((error) => {
				setLoading(false)
				Notify.error(error.response?.data?.message ?? 'Failed to get login url')
			});
	};

	const handleUnlinkRwAccount = () => {
		setLoading(true)
		deleteRwSession()
			.then((response) => {
				setLoading(false)
				refreshState()
			})
			.catch((error) => {
				setLoading(false)
				Notify.error(error.response?.data?.message ?? 'Failed to remove session')
			});
	};

	useEffect(() => {
		const params = new URLSearchParams(window.location.search)
    	let payload = params.get('sso')
		if (payload) {
			setLoading(true)
			updateRwSession(payload)
				.then((response) => {
					setLoading(false)
					refreshState()
					history.replace('/admin/settings/general', { replace: true });
				})
				.catch((error) => {
					setLoading(false)
					refreshState()
					Notify.error(error.response?.data?.message ?? 'Failed to update session')
				})
		} else {
			refreshState()
		}		
	}, [history]);

	return (
		<Fragment>
			<Row>
				<Col xl={{ offset: 3, span: 6 }} lg={{ offset: 2, span: 8 }} md={12}>
					<Row>
						<Col lg={12} md={12} sm={12}>
							<div className="border-bottom pb-4 mb-4 d-md-flex align-items-center justify-content-between">
								<div className="mb-3 mb-md-0">
									<h1 className="mb-1 h2 font-weight-bold">General Setting</h1>
									<Breadcrumb>
										<Breadcrumb.Item href="/admin">Dashboard</Breadcrumb.Item>
										<Breadcrumb.Item href="#">Settings</Breadcrumb.Item>
										<Breadcrumb.Item active>General</Breadcrumb.Item>
									</Breadcrumb>
								</div>
							</div>
						</Col>
					</Row>

					<Row>
						{/* general  settings */}
						<Col xl={12} lg={12} md={12}>
							<Card className="border-0">
								<Card.Header>
									<div className="mb-3 mb-lg-0">
										<h3 className="mb-0">Linked Accounts</h3>
										<p className="mb-0">
											You can link your RW accounts into your Bamboo accounts & also
											access your history of linked accounts and manage your accounts in
											this sections.
										</p>
									</div>
								</Card.Header>

								{/*  Card body  */}
								<Card.Body>
									<div className="mb-5 d-md-flex">
										{/*  facebook  */}
										<div className="position-relative">
											<Image
												src={RwLogoImage}
												alt=""
												className="avatar-md me-2"/>
											{rwPremium === true &&
												<Link
													to="#"
													className="position-absolute top-0 end-0 me-0"
													data-bs-toggle="tooltip"
													data-placement="top"
													title="Premium"
												>
													<Image src={CheckedMark} alt="" height="30" width="30" />
												</Link>
											}											
										</div>
										<div className="mt-1">
											<h3 className="mb-0">RW</h3>
											<p>
												Enable one-click login to get RW extra features
											</p>
											{loading &&
												<Button variant="outline-secondary" size="sm" disabled>
													<Spinner
														as="span"
														animation="grow"
														size="sm"
														role="status"
														aria-hidden="true"/>
													{' '} Loading...
												</Button>
											}
											{!loading && rwSsoActive === true &&
												<Button variant="primary" size="sm" onClick={handleUnlinkRwAccount}>
													Remove my RW Account
												</Button>
											}
											{!loading && rwSsoActive === false &&
												<Button variant="outline-secondary" size="sm" onClick={handleLinkRwAccount}>
													Link my RW Account
												</Button>
											}																				
										</div>
									</div>									
								</Card.Body>
							</Card>
						</Col>
						{/* end of general settings */}
					</Row>
				</Col>
			</Row>
		</Fragment>
	);
};

export default GeneralSetting;
