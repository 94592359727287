// ** Import from react dom
import { Route, Switch, Redirect } from 'react-router-dom';

// ** Import core SCSS styles
import 'assets/scss/theme.scss';

// ** Import Layouts
import DashboardIndex from 'layouts/dashboard/DashboardIndex';
import AuthLayout from 'layouts/dashboard/AuthLayout';
import ProtectedLayout from 'layouts/marketing/ProtectedLayout';
import EmptyProtectedLayout from 'layouts/marketing/EmptyProtectedLayout';

// ** Import Authentication components
import SignIn from 'components/dashboard/authentication/SignIn';
import SignUp from 'components/dashboard/authentication/SignUp';
import ForgetPassword from 'components/dashboard/authentication/ForgetPassword';
import Otp from 'components/dashboard/authentication/Otp';
import Logout from 'components/dashboard/authentication/Logout';

/* ----------------------------------- */
/* IMPORTS FOR MARKETING PAGES - START */
import BlankLayout from 'layouts/marketing/BlankLayout';
import NotFound from 'layouts/marketing/NotFound';
import CourseIndex from 'components/marketing/pages/courses/course-index/CourseIndex';
import CourseCategory from 'components/marketing/pages/courses/course-category/CourseCategory';

/* IMPORTS FOR FRONT SIDE PAGES MENU */
import CourseFilterPage from 'components/marketing/pages/courses/course-filter-page/CourseFilterPage';
import BookFilterPage from 'components/marketing/pages/books/book-filter-page/BookFilterPage';

/* IMPORTS FOR FRONT BLOG SUBMENU  ROUTERS */
import BookSynopsis from 'components/marketing/pages/books/book-synopsis/BookSynopsis';
import BookReader from 'components/marketing/pages/books/book-reader/BookReader';
import BookSearch from 'components/marketing/pages/books/book-reader/search/BookSearch';
import CourseReader from 'components/marketing/pages/courses/course-reader/CourseReader';
import CourseSingle2 from 'components/marketing/pages/courses/course-single2/CourseSingle2';

/* IMPORTS FOR FRONT SPECIALTY SUBMENU  ROUTERS */
import ComingSoon from 'components/marketing/pages/specialty/ComingSoon';
import Error404 from 'components/marketing/pages/specialty/Error404';
import TechnicalError from 'components/marketing/pages/specialty/TechnicalError';
import MaintenanceMode from 'components/marketing/pages/specialty/MaintenanceMode';
import TermsAndConditions from 'components/marketing/pages/specialty/TermsAndConditions';

// Student Dashboard Pages
import StudentDashboard from 'components/marketing/student/Dashboard';
import DeleteProfile from 'components/marketing/account-settings/DeleteProfile';
import EditProfile from 'components/marketing/account-settings/EditProfile';
import Security from 'components/marketing/account-settings/Security';

// ** Import Dahbaord Menu Pages
import Overview from 'components/dashboard/overview/Overview';
import AllBooks from 'components/dashboard/books/all-books/AllBooks';
import AllBookEditions from 'components/dashboard/books/all-editions/AllBookEditions';
import AllBookSections from 'components/dashboard/books/all-sections/AllBookSections';
import AllBookChapters from 'components/dashboard/books/all-chapters/AllBookChapters';
import BookChapterEditor from 'components/dashboard/books/editor/BookChapterEditor';
import BookSectionEditor from 'components/dashboard/books/editor/BookSectionEditor';
import BookEditor from 'components/dashboard/books/editor/BookEditor';
import CoursesCategory from 'components/dashboard/courses/CoursesCategory';
import Students from 'components/dashboard/user/Students';
import General from 'components/dashboard/settings/General';
import Sso from 'components/dashboard/settings/Sso';

// Context
import { AuthProvider } from 'context/providers/AuthProvider';
import { FiltersProvider } from 'context/providers/FiltersProvider';

/* IMPORTS FOR MARKETING PAGES - END */
/* --------------------------------- */

const AppRoute = ({ component: Component, layout: Layout, ...rest }) => (
	<Route
		{...rest}
		render={(props) => (
			<Layout>
				<Component {...props}></Component>
			</Layout>
		)}
	></Route>
);

function AllRoutes() {
	return (
		<AuthProvider>
			<FiltersProvider>
				<Switch>
					<AppRoute exact path="/home" layout={ProtectedLayout} component={CourseIndex} />
					
					{/* AUTHENTICATION ROUTERS */}
					<AppRoute
						exact
						path="/"
						layout={AuthLayout}
						component={SignIn}/>
					<AppRoute
						exact
						path="/sign-in"
						layout={AuthLayout}
						component={SignIn}
					/>
					<AppRoute
						exact
						path="/otp"
						layout={AuthLayout}
						component={Otp}
					/>
					<AppRoute
						exact
						path="/sign-up"
						layout={AuthLayout}
						component={SignUp}
					/>
					<AppRoute
						exact
						path="/forget-password"
						layout={AuthLayout}
						component={ForgetPassword}
					/>
					<AppRoute
						exact
						path="/logout"
						layout={ProtectedLayout}
						component={Logout}
					/>

					{/* FRONT SIDE STUDENTS DASHBOARD-ACCOUNT SETTINGS ROUTERS */}
					<AppRoute
						exact
						path="/account/student/dashboard"
						layout={ProtectedLayout}
						component={StudentDashboard}
					/>					

					{/* FRONT SIDE STUDENTS DASHBOARD-ACCOUNT SETTINGS ROUTERS */}
					<AppRoute
						exact
						path="/account/student/edit-profile"
						layout={ProtectedLayout}
						component={EditProfile}
					/>
					<AppRoute
						exact
						path="/account/student/security"
						layout={ProtectedLayout}
						component={Security}
					/>				
					<AppRoute
						exact
						path="/account/student/delete-profile"
						layout={ProtectedLayout}
						component={DeleteProfile}
					/>

					{/* Books */}
					<AppRoute
						exact
						path="/library/:bookId"
						layout={ProtectedLayout}
						component={BookSynopsis}
					/>
					<AppRoute
						exact
						path="/library/:bookId/search"
						layout={EmptyProtectedLayout}
						component={BookSearch}
					/>
					<AppRoute
						exact
						path="/library/:bookId/:chapterId"
						layout={EmptyProtectedLayout}
						component={BookReader}
					/>			
					<AppRoute
						exact
						path="/library"
						layout={ProtectedLayout}
						component={BookFilterPage}
					/>

					{/* Category */}
					<AppRoute
						exact
						path="/category/:categoryId"
						layout={ProtectedLayout}
						component={CourseCategory}
					/>

					{/* Courses */}
					<AppRoute
						exact
						path="/courses"
						layout={ProtectedLayout}
						component={CourseFilterPage}
					/>

					<AppRoute
						exact
						path="/course/:courseId"
						layout={ProtectedLayout}
						component={CourseReader}
					/>	

					<AppRoute
						exact
						path="/video/:courseId"
						layout={ProtectedLayout}
						component={CourseSingle2}
					/>		

					{/* FRONT SIDE BLOG SPECIALTY ROUTERS */}
					<AppRoute
						exact
						path="/coming-soon"
						layout={NotFound}
						component={ComingSoon}
					/>
					<AppRoute
						exact
						path="/404-error"
						layout={NotFound}
						component={Error404}
					/>
					<AppRoute
						exact
						path="/500-error"
						layout={NotFound}
						component={TechnicalError}
					/>
					<AppRoute
						exact
						path="/maintenance-mode"
						layout={NotFound}
						component={MaintenanceMode}
					/>
					<AppRoute
						exact
						path="/terms-and-conditions"
						layout={BlankLayout}
						component={TermsAndConditions}
					/>		

					{/* ADMIN DASHBOARD */}
					<AppRoute
						exact
						path="/admin"
						layout={DashboardIndex}
						component={Overview}
					/>
					<AppRoute
						exact
						path="/admin/library"
						layout={DashboardIndex}
						component={AllBooks}
					/>
					<AppRoute
						exact
						path="/admin/library/list/:bookId"
						layout={DashboardIndex}
						component={AllBookEditions}
					/>
					<AppRoute
						exact
						path="/admin/library/list/:bookId/:version"
						layout={DashboardIndex}
						component={AllBookSections}
					/>
					<AppRoute
						exact
						path="/admin/library/list/:bookId/:version/:sectionId"
						layout={DashboardIndex}
						component={AllBookChapters}
					/>
					<AppRoute
						exact
						path="/admin/library/editor/update-chapter/:bookId/:version/:chapterId"
						layout={DashboardIndex}
						component={BookChapterEditor}
					/>
					<AppRoute
						exact
						path="/admin/library/editor/create-chapter/:bookId/:version/:sectionId"
						layout={DashboardIndex}
						component={BookChapterEditor}
					/>
					<AppRoute
						exact
						path="/admin/library/editor/update-section/:bookId/:version/:sectionId"
						layout={DashboardIndex}
						component={BookSectionEditor}
					/>
					<AppRoute
						exact
						path="/admin/library/editor/create-section/:bookId/:version"
						layout={DashboardIndex}
						component={BookSectionEditor}
					/>
					<AppRoute
						exact
						path="/admin/library/editor/update-book/:bookId/:version"
						layout={DashboardIndex}
						component={BookEditor}
					/>
					<AppRoute
						exact
						path="/admin/library/editor/update-book/:bookId"
						layout={DashboardIndex}
						component={BookEditor}
					/>
					<AppRoute
						exact
						path="/admin/library/editor/create-book"
						layout={DashboardIndex}
						component={BookEditor}
					/>
					<AppRoute
						exact
						path="/admin/course/categories"
						layout={DashboardIndex}
						component={CoursesCategory}
					/>
					<AppRoute
						exact
						path="/admin/user/students"
						layout={DashboardIndex}
						component={Students}
					/>
					<AppRoute
						exact
						path="/admin/settings/general"
						layout={DashboardIndex}
						component={General}
					/>
					<AppRoute
						exact
						path="/admin/settings/sso"
						layout={DashboardIndex}
						component={Sso}
					/>

					{/*Redirect*/}
					<Redirect to="/404-error" />
				</Switch>
			</FiltersProvider>
		</AuthProvider>
	);
}

export default AllRoutes;
