export const DashboardMenu = [
	{
		id: 1,
		title: 'My Subscriptions',
		link: '/marketing/student/student-subscriptions/',
		icon: 'home'
	},
	{
		id: 2,
		title: 'Billing Info',
		link: '/marketing/student/student-billing-info/',
		icon: 'book'
	},
	{
		id: 3,
		title: 'Payment',
		link: '/marketing/student/student-payment/',
		icon: 'star'
	},
	{
		id: 4,
		title: 'Invoice',
		link: '/marketing/student/student-invoice/',
		icon: 'pie-chart'
	}
];

export const AccountSettingsMenu = [
	{
		id: 1,
		title: 'Edit Profile',
		link: '/account/student/edit-profile',
		icon: 'settings'
	},
	{
		id: 2,
		title: 'Security',
		link: '/account/student/security',
		icon: 'user'
	},		
	{
		id: 6,
		title: 'Delete Profile',
		link: '/account/student/delete-profile/',
		icon: 'trash'
	},
	{
		id: 6,
		title: 'Sign Out',
		link: '/logout',
		icon: 'power'
	}
];

export const InstructorDashboardMenu = [AccountSettingsMenu];

export default InstructorDashboardMenu;
