// import node module libraries
import { Fragment, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Col, Row, Card, Form, Button, Image, Spinner } from 'react-bootstrap';

// import sub-components
import DarkLightMode from 'layouts/DarkLightMode';

// import media files
import Logo from 'assets/images/brand/logo/logo.svg';

// for manage user session
import { useAuth } from 'hooks/useAuth';

// rest api
import { getIp, doLogin } from 'services/api/KumajiroApi';

// to display success/error message
import Notify from 'services/Notify';

const INITIAL_STATE = {
	email: '',
	password: '',
};

const SignIn = () => {
	const { login, pushOtp } = useAuth();

	const [form, setForm] = useState(INITIAL_STATE);
	const [loading, setLoading] = useState(false);
	const [ip, setIp] = useState(null)

	useEffect(() => {
		getIp()
			.then(res => {
				setIp(res.data.ip)
			})
	}, [])

	const handleSubmit = (event) => {
		event.preventDefault();

		const email = form.email
		const password = form.password

		setLoading(true)
		doLogin(email, password, ip)
			.then(res => {
				setLoading(false)
				login(res.data);
			})
			.catch((error) => {
				setLoading(false)	
				setForm(INITIAL_STATE);
				if (error.response?.status === 451) {
					const auth2Token = error.response?.data?.auth2Token
					pushOtp(auth2Token)
				} else {
					Notify.error(error.response?.data?.message ?? "An error occured")
				}
			});
  	};	

	const handleChange = (event) => {
		setForm({
		  ...form,
		  [event.target.id]: event.target.value,
		});
	  };

	return (
		<Fragment>
			<div className='position-absolute top-0 end-0 me-2 mt-2 d-none d-lg-block'>
				<DarkLightMode />
			</div>			
			<Row className="align-items-center justify-content-center g-0 min-vh-100">
				<Col lg={5} md={5} className="py-8 py-xl-0">
					<Card>
						<Card.Body className="p-6">
							<div className="mb-4">
								<Link to="/">
									<Image src={Logo} className="mb-4 brand" alt="" />
								</Link>
								<h1 className="mb-1 fw-bold">Sign in</h1>
								<span>
									Don’t have an account?{' '}
									<Link to="/sign-up" className="ms-1">
										Sign up
									</Link>
								</span>
							</div>							
							{/* Form */}
							<Form onSubmit={handleSubmit}>								
								<Row>
									<Col lg={12} md={12} className="mb-3">
										{/* Username or email */}
										<Form.Label>Username or email </Form.Label>
										<Form.Control
											value={form.email}
											onChange={handleChange}
											type="email"
											id="email"
											placeholder="Email address here"
											required
										/>
									</Col>
									<Col lg={12} md={12} className="mb-3">
										{/* Password */}
										<Form.Label>Password </Form.Label>
										<Form.Control
											value={form.password}
											onChange={handleChange}
											type="password"
											id="password"
											placeholder="**************"
											required
										/>
									</Col>
									<Col lg={12} md={12} className="mb-3">
										{/* Checkbox */}
										<div className="d-md-flex justify-content-between align-items-center">
											<div className="mb-3 mb-md-0"></div>
											<Link to="/forget-password">
												Forgot your password?
											</Link>
										</div>
									</Col>
									<Col lg={12} md={12} className="mb-0 d-grid gap-2">
										{/* Button */}
										{ !loading && 
											<Button variant="primary" type="submit">
												Sign in
											</Button>
										}
										{ loading && 
											<Button variant="primary" type="submit" disabled>
												<Spinner
													as="span"
													animation="grow"
													size="sm"
													role="status"
													aria-hidden="true"/>
												 {' '} Loading...
											</Button>
										}									
									</Col>
								</Row>
							</Form>							
						</Card.Body>
					</Card>
				</Col>
			</Row>
		</Fragment>
	);
};

export default SignIn;
