// import node module libraries
import MarkdownToHtml from 'components/elements/markdown-html/MarkdownToHtml';
import { Fragment } from 'react';
import { Col, Row, ListGroup } from 'react-bootstrap';

const DescriptionTab = ({course}) => {
	return (
		<Fragment>
			<div className="mb-4">
				<h3 className="mb-2">Course Descriptions</h3>
				<MarkdownToHtml 
					content={course.summary}/>
			</div>
			{course.collection &&
				<>
					<h4 className="mb-3">Episodes</h4>
					<Row className="mb-3">
						<Col>
							<ListGroup bsPrefix="list-unstyled" variant="flush">
							{
								course.collection.map((item) => {
									return (
										<ListGroup.Item bsPrefix=" " className="mb-2" key={item.id}>
											<h5><span className="far fa-check-circle text-success me-2 mt-2"></span>{item.name}</h5>
											<MarkdownToHtml
												content={item.summary}/>	
										</ListGroup.Item>										
									);
								})
							}
							</ListGroup>
						</Col>
					</Row>	
				</>
			}					
		</Fragment>
	);
};
export default DescriptionTab;
