// import node module libraries
import React, { useState } from 'react';
import { Button, Card, Modal, Spinner } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';

// import hooks
import { useAuth } from 'hooks/useAuth';

// import rest api
import { deleteProfile } from 'services/api/KumajiroApi';

// show success / error message
import Notify from 'services/Notify';

// import profile layout wrapper
import ProfileLayoutWrap from './ProfileLayoutWrap';

const DeleteProfile = () => {
	const location = useLocation();

	const {logout} = useAuth();

	const [showModal, setShowModal] = useState(false)
	const [loading, setLoading] = useState(false)

	const handleCloseModal = () => setShowModal(false);
    const handleShowModal = () => setShowModal(true);
	const askForDeleteProfile = () => {
		handleShowModal()
	}
	const performDelete = () => {
		setLoading(true)
		deleteProfile()
			.then(res => {
				setLoading(false)
				Notify.success("Your account has been deleted")
				logout()
			})
			.catch((error) => {
				setLoading(false)
				Notify.error(error.response?.data?.message ?? "An error occured")
			});		
	}

	return (
		<ProfileLayoutWrap pathpara={location.pathname}>
			<Card className="border-0">
				<Card.Header>
					<div className="mb-3 mb-lg-0">
						<h3 className="mb-0">Delete your account</h3>
						<p className="mb-0">Delete or Close your account permanently.</p>
					</div>
				</Card.Header>
				<Card.Body>
					<span className="text-danger h4">Warning</span>
					<p>
						If you close your account, all your data will be deleted. Are you sure ?
					</p>
					<Button variant='outline-danger' size='sm' onClick={askForDeleteProfile}>
						Close My Account
					</Button>
				</Card.Body>
			</Card>

			<Modal show={showModal} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirmation request</Modal.Title>
                </Modal.Header>
                <Modal.Body>If you close your account, all your data will be deleted. Are you sure ?</Modal.Body>
                <Modal.Footer>
                    <Button variant="light" onClick={handleCloseModal}>
                        Cancel
                    </Button>
					{ !loading && 
						<Button variant="danger" type="submit" onClick={performDelete}>
							Close My Account
						</Button>
					}
					{ loading && 
						<Button variant="primary" type="submit" disabled>
							<Spinner
								as="span"
								animation="grow"
								size="sm"
								role="status"
								aria-hidden="true"/>
								{' '} Loading...
						</Button>
					}                   
                </Modal.Footer>
            </Modal>
		</ProfileLayoutWrap>
	);
};

export default DeleteProfile;
