// import node module libraries
import { Fragment } from 'react';
import { Col, Row } from 'react-bootstrap';
import ReactPaginate from 'react-paginate';
import { ChevronLeft, ChevronRight } from 'react-feather';

// import hooks
import { useFilters } from 'context/providers/FiltersProvider';

// import sub components
import BookCard from '../BookCard';

const BookListView = (props) => {
	const filters = useFilters()
	
	const { items } = props;

	const RecordsPerPage = 9;
	const pagesVisited = filters.books.page * RecordsPerPage;
	const pageCount = Math.ceil((items?.length ?? 0) / RecordsPerPage);
	const changePage = ({ selected }) => {		
		filters.books.setPage(selected)
	};
	const displayRecords = items.slice(
		pagesVisited,
		pagesVisited + RecordsPerPage
	).map((item, index) => {
		return (
			<Col sm={12} md={12} lg={12} key={index}>
				{<BookCard item={item} viewby="list" cachable={true} />}
			</Col>
		);
	});
	// end of paging

	return (
		<Fragment>
			<Row>
				{(items?.length ?? 0) > 0 ? (
					displayRecords
				) : (
					<Col>No matching records found.</Col>
				)}
			</Row>

			<ReactPaginate
				previousLabel={<ChevronLeft size="14px" />}
				nextLabel={<ChevronRight size="14px" />}
				pageCount={pageCount}
				onPageChange={changePage}
				forcePage={filters.books.page}
				containerClassName={'justify-content-center mb-0 pagination'}
				previousLinkClassName={'page-link mx-1 rounded'}
				nextLinkClassName={'page-link mx-1 rounded'}
				pageClassName={'page-item'}
				pageLinkClassName={'page-link mx-1 rounded'}
				disabledClassName={'paginationDisabled'}
				activeClassName={'active'}
			/>
		</Fragment>
	);
};
export default BookListView;
