import { toast } from "react-toastify";

const config = {
    position: "bottom-left",
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
};

const success = (message) => {
    toast.success(message, config);
};

const error = (message) => {
    toast.error(message, config);
};
  
const Notify = {
    success,
    error
};
  
  export default Notify;