// import node module libraries
import React, { useState, useEffect, Fragment } from 'react';
import { useHistory, useParams } from 'react-router-dom';

// subcomponents
import { Col, Row, Container, Card, Form, Button, Spinner, Breadcrumb, Modal, ButtonGroup } from 'react-bootstrap';
import Select from 'react-select'
import MDEditor from '@uiw/react-md-editor';

// rest api
import { createBook, generateNewEdition, getBook, getCategories, getEditors, updateBook } from 'services/api/KumajiroApi';

// to display success / error messages
import Notify from 'services/Notify';

const BookEditor = () => {
	const INITIAL_STATE = {
        bookCode: '',      
        bookTitle: '',
        bookSummary: '',
        bookAuthor: '',
        bookCover: '',
        bookThumbnail: '',
        bookEdition: '',
        bookPlatform: '',
        bookCodeEditor: '',
        bookCodeLanguage: '',
        bookCategories: [],
        bookEditor: undefined
    };

    const { bookId, version } = useParams();

    const history = useHistory()

    const [fullScreenLoading, setFullScreenLoading] = useState(false);
    const [loading, setLoading] = useState(false);
    const [book, setBook] = useState();
	const [formData, setFormData] = useState(INITIAL_STATE);
    const [categories, setCategories] = useState([]);
    const [editors, setEditors] = useState([]);
    
    const [sourceUrl, setSourceUrl] = useState('');
    const [showGenerateModal, setShowGenerateModal] = useState(false);
    const handleCloseGenerateModal = () => setShowGenerateModal(false);	
	const handleShowGenerateModal = () => setShowGenerateModal(true);	
    
	const handleChange = (event) => {  
		setFormData({
			...formData,
			[event.target.name]: event.target.value
		});
	};    
    const handleSummaryChange = (value) => {  
		setFormData({
			...formData,
			'bookSummary': value
		});
	};
    const handleCategoriesChange = (value) => {
        setFormData({
			...formData,
			'bookCategories': value
		});
    };
    const handleEditorChange = (value) => {
        setFormData({
			...formData,
			'bookEditor': value
		});
    };
    const handleGenerate = (e) => {
        e.preventDefault()
        setLoading(true)
        generateNewEdition(bookId, sourceUrl)
            .then((response) => {
                Notify.success(`${response?.data?.info?.editionName ?? "New Edition"} generated`)
                setLoading(false)
                handleCloseGenerateModal()
                goBack()
            })
            .catch((error) => {
                setLoading(false)
                Notify.error(error.response?.data?.message ?? "An error occured")
            })        
    };

    const goBack = () => {
        if (bookId) {
            history.push(`/admin/library/list/${bookId}`)
        } else {
            history.push(`/admin/library`)
        }
    };

    useEffect(() => {
        if (bookId) {
            setFullScreenLoading(true)
            getBook(bookId, version)
                .then((response) => {
                    setFullScreenLoading(false)
                    const book = response.data
                    if (version) {                        
                        setBook(book)
                        const getParam = (code) => book.params.filter((param) => param.code === code)[0]?.value ?? '';
                        setFormData({
                            bookCode: book.code ?? '',
                            bookTitle: book.info.editionName ?? '',
                            bookSummary: book.info.summary ?? '',
                            bookAuthor: book.info.author ?? '',
                            bookCover: book.info.cover ?? '',
                            bookThumbnail: book.info.thumbnail ?? '',
                            bookEdition: getParam('VERSION'),
                            bookPlatform: getParam('PLATFORM'),
                            bookCodeEditor: getParam('EDITOR'),
                            bookCodeLanguage: getParam('LANGUAGE'),
                            bookCategories: book.categories.map((item) => ({ 
                                value: item.id,
                                label: item.name 
                            })),
                            bookEditor: { value: book.editor.id, label: book.editor.name }
                        });                                     
                    } else {
                        setFormData({
                            ...formData,
                            bookCode: book.code ?? '',
                            bookTitle: book.info.editionName ?? '',
                            bookEditor: { value: book.editor.id, label: book.editor.name }
                        });
                    }
                })
                .catch((error) => {
                    setFullScreenLoading(false)
                    Notify.error(error.response?.data?.message ?? "An error occured")
                    if (error.response?.status === 404) {
                        goBack()
                    }
                });
        }
    }, [bookId, version, history])

    useEffect(() => {
        getCategories()
            .then((response) => {
                const items = response.data.map((item) => ({ 
                    value: item.id,
                    label: item.name 
                }))
                setCategories(items)
            })
            .catch((error) => {
                Notify.error(error.response?.data?.message ?? "An error occured")
            });
    }, [])

    useEffect(() => {
        getEditors()
            .then((response) => {
                const items = response.data.map((item) => ({ 
                    value: item.id,
                    label: item.name 
                }))
                setEditors(items)
            })
            .catch((error) => {
                Notify.error(error.response?.data?.message ?? "An error occured")
            });
    }, [])

    const handleSubmit = (e) => {
        e.preventDefault()

        setLoading(true)

        let params = []
        if (formData.bookCodeEditor.length > 0) {
            params.push({
                code: 'EDITOR',
                name: 'Editor',
                value: formData.bookCodeEditor
            })
        }
        if (formData.bookCodeLanguage.length > 0) {
            params.push({
                code: 'LANGUAGE',
                name: 'Language',
                value: formData.bookCodeLanguage
            })
        }
        if (formData.bookPlatform.length > 0) {
            params.push({
                code: 'PLATFORM',
                name: 'Platform',
                value: formData.bookPlatform
            })
        }
        if (formData.bookEdition.length > 0) {
            params.push({
                code: 'VERSION',
                name: 'Version',
                value: formData.bookEdition
            })
        }

        let categories = formData.bookCategories.map((item) => (item.value))

        const data = {
            id: bookId,
            code: formData.bookCode,
            name: book?.name ?? formData.bookTitle,
            editorId: formData.bookEditor?.value ?? '',
            version: book?.info?.version,
            info: {
                version: book?.info?.version,
                editionName: formData.bookTitle,
                author: formData.bookAuthor,
                summary: formData.bookSummary,
                cover: formData.bookCover,
                thumbnail: formData.bookThumbnail
            },
            params: params,
            categories: categories
        }

        let promise
        if (bookId && version) {
            promise = updateBook(bookId, data)
        } else {
            promise = createBook(data)
        }
        promise
            .then((response) => {
                setLoading(false)
                if (bookId) {
                    Notify.success(version ? 'Edition updated' : 'New edition created')
                } else {
                    Notify.success('New book created')
                }
                goBack()
            })
            .catch((error) => {
                setLoading(false)
                Notify.error(error.response?.data?.message ?? "An error occured")
                if (error.response?.status === 404) {
                    goBack()
                }
            });
    };

    const Header = ({title, subtitle}) =>
        <div className="py-4 py-lg-6 bg-primary">
            <Container>
                <Row>
                    <Col lg={{ span: 10, offset: 1 }} md={12} sm={12}>
                        <div className="d-lg-flex align-items-center justify-content-between">
                            <div className="mb-4 mb-lg-0">
                                <h1 className="text-white mb-1">{title}</h1>
                                <p className="mb-0 text-white lead">{subtitle}</p>                                    
                            </div>		
                            <div>
                                {!loading && 
                                    <ButtonGroup>
                                        <Button
                                            variant="white"
                                            onClick={handleShowGenerateModal}
                                        >
                                            Generate
                                        </Button>{' '}
                                        <Button variant="success" type="submit" form="book-chapter">
                                            Save
                                        </Button>
                                    </ButtonGroup>
                                }
                                { loading && 
                                    <ButtonGroup>
                                        <Button
                                            variant="white"
                                            onClick={handleShowGenerateModal}
                                            disabled
                                        >
                                            Generate
                                        </Button>{' '}
                                        <Button variant="success" type="submit" form="book-chapter" disabled>
                                            <Spinner
                                                as="span"
                                                animation="grow"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"/>
                                                {' '} Loading...
                                        </Button>
                                    </ButtonGroup>
                                }
                            </div>						
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>

    if (fullScreenLoading === true) {
        return (
            <div className="d-flex align-items-center justify-content-center" style={{height: "40vh"}}>
                <Spinner animation="border" variant="primary" />
            </div>
        );
    }

	return (
		<Fragment>
            <Fragment>
                <Breadcrumb>
                    <Breadcrumb.Item href="/admin">Dashboard</Breadcrumb.Item>
                    <Breadcrumb.Item href="/admin/library">Books</Breadcrumb.Item>   
                    {bookId &&
                        <>
                            <Breadcrumb.Item href={`/admin/library/list/${bookId}`}>
                                {formData.bookTitle}
                            </Breadcrumb.Item>
                            <Breadcrumb.Item active>
                                {version ? formData.bookEdition : 'New Edition'}
                            </Breadcrumb.Item>                    
                        </>
                    } 
                    {!bookId &&                   
                        <Breadcrumb.Item active>
                            New Book
                        </Breadcrumb.Item>
                    }
                </Breadcrumb>
                <Header
                    title={formData.bookTitle}
                    subtitle={formData.bookEdition}/>
            </Fragment>

            {/* Card */}
            <Card className="mb-3 ">                
                {/* Card body */}
                <Card.Body>
                    <Form id="book-chapter" onSubmit={handleSubmit}>
                        {/* Code  */}
                        <Form.Group className="mb-3">
                            <Form.Label htmlFor="bookCode">Book Code</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Book Code"
                                id="bookCode"
                                name="bookCode"
                                value={formData.bookCode}
                                onChange={handleChange}
                                required
                            />
                            <Form.Text className="text-muted">
                                Code used to get the book
                            </Form.Text>
                        </Form.Group>                

                        {/* Title  */}
                        <Form.Group className="mb-3">
                            <Form.Label htmlFor="bookTitle">Book Title</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Book Title"
                                id="bookTitle"
                                name="bookTitle"
                                value={formData.bookTitle}
                                onChange={handleChange}
                                required
                            />
                            <Form.Text className="text-muted">
                                Write a 60 character book title
                            </Form.Text>
                        </Form.Group>

                        {/* Summary  */}
                        <Form.Group className="mb-3" data-color-mode="light">
                            <Form.Label htmlFor="bookSummary">Book Summary</Form.Label>
                            <MDEditor
                                type="text"
                                placeholder="Book Summary"
                                id="bookSummary"
                                name="bookSummary"
                                value={formData.bookSummary}
                                onChange={handleSummaryChange}
                                required
                            />                                            
                        </Form.Group>

                        {/* Author  */}
                        <Form.Group className="mb-3">
                            <Form.Label htmlFor="bookAuthor">Book Author</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Book Author"
                                id="bookAuthor"
                                name="bookAuthor"
                                value={formData.bookAuthor}
                                onChange={handleChange}
                                required
                            />                                            
                        </Form.Group> 

                        {/* Cover  */}
                        <Form.Group className="mb-3">
                            <Form.Label htmlFor="bookCover">Book Cover</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Book Cover"
                                id="bookCover"
                                name="bookCover"
                                value={formData.bookCover}
                                onChange={handleChange}
                                required
                            />    
                            <Form.Text className="text-muted">
                                PNG, JPG image
                            </Form.Text>
                        </Form.Group>

                        {/* Thumbnail  */}
                        <Form.Group className="mb-3">
                            <Form.Label htmlFor="bookThumbnail">Book Thumbnail</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Book Thumbnail"
                                id="bookThumbnail"
                                name="bookThumbnail"
                                value={formData.bookThumbnail}
                                onChange={handleChange}
                                required
                            />    
                            <Form.Text className="text-muted">
                                PNG, JPG image
                            </Form.Text>
                        </Form.Group>

                        {/* Version  */}
                        <Form.Group className="mb-3">
                            <Form.Label htmlFor="bookEdition">Book Edition</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Book Edition"
                                id="bookEdition"
                                name="bookEdition"
                                value={formData.bookEdition}
                                onChange={handleChange}
                                required
                            />    
                            <Form.Text className="text-muted">
                                Sample: 2e edition, ...
                            </Form.Text>
                        </Form.Group>

                        {/* Platform  */}
                        <Form.Group className="mb-3">
                            <Form.Label htmlFor="bookPlatform">Platform</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Platform"
                                id="bookPlatform"
                                name="bookPlatform"
                                value={formData.bookPlatform}
                                onChange={handleChange}
                            />    
                            <Form.Text className="text-muted">
                                Sample: iOS 15, Android 13...
                            </Form.Text>
                        </Form.Group>

                        {/* Code Editor  */}
                        <Form.Group className="mb-3">
                            <Form.Label htmlFor="bookCodeEditor">IDE/Software</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="IDE/Software"
                                id="bookCodeEditor"
                                name="bookCodeEditor"
                                value={formData.bookCodeEditor}
                                onChange={handleChange}
                            />    
                            <Form.Text className="text-muted">
                                Sample: Eclipse, XCode...
                            </Form.Text>
                        </Form.Group>
                        
                        {/* Programming Language  */}
                        <Form.Group className="mb-3">
                            <Form.Label htmlFor="bookCodeLanguage">Programming Language</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Programming Language"
                                id="bookCodeLanguage"
                                name="bookCodeLanguage"
                                value={formData.bookCodeLanguage}
                                onChange={handleChange}
                            />    
                            <Form.Text className="text-muted">
                                Sample: Kotlin, Swift...
                            </Form.Text>
                        </Form.Group>

                        {/* Categories  */}
                        <Form.Group className="mb-3">
                            <Form.Label htmlFor="bookCodeLanguage">Categories</Form.Label>
                            <Select 
                                options={categories}
                                value={formData.bookCategories}
                                onChange={handleCategoriesChange}
                                isMulti />
                            <Form.Text className="text-muted">
                                Select one or several categories
                            </Form.Text>
                        </Form.Group>

                        {/* Editor  */}
                        <Form.Group className="mb-3">
                            <Form.Label htmlFor="bookEditor">Editor</Form.Label>
                            <Select 
                                options={editors}
                                value={formData.bookEditor}
                                onChange={handleEditorChange}
                                isDisabled={bookId ? true : false}
                                required={true}/>
                            <Form.Text className="text-muted">
                                Select the editor
                            </Form.Text>
                        </Form.Group>
                    </Form>
                </Card.Body>
            </Card>
            {/* Button */}
            {!loading && 
                <Button variant="primary" type="submit" form="book-chapter">
                    Save
                </Button>
            }
            { loading && 
                <Button variant="primary" type="submit" form="book-chapter" disabled>
                    <Spinner
                        as="span"
                        animation="grow"
                        size="sm"
                        role="status"
                        aria-hidden="true"/>
                        {' '} Loading...
                </Button>
            }

            <Modal show={showGenerateModal} onHide={handleCloseGenerateModal} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Auto Generate</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form id="book-chapter-generator" onSubmit={handleGenerate}>
                        <Form.Group className="mb-3">
                            <Form.Label htmlFor="sourceUrl">URL</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Source URL"
                                id="sourceUrl"
                                name="sourceUrl"
                                value={sourceUrl}
                                onChange={e => setSourceUrl(e.target.value)}
                            />
                            <Form.Text className="text-muted">
                                URL used to generate the book. Auto-create chapters, metadata...
                            </Form.Text>
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer className="d-flex justify-content-start border-0 pt-0">
                    {/*  Action Buttons  */}
                    {loading &&
                        <Button variant="primary" type="submit" form='book-chapter-generator' disabled>
                            <Spinner
                                as="span"
                                animation="grow"
                                size="sm"
                                role="status"
                                aria-hidden="true"/>
                            {' '} Loading...
                        </Button>
                    }
                    {!loading &&
                        <Button variant="primary" type="submit" form='book-chapter-generator'>
                            Generate
                        </Button>
                    }									
                    <Button variant="white" onClick={handleCloseGenerateModal}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
		</Fragment>
	);
};

export default BookEditor;
