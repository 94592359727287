// import node module libraries
import { Fragment, useState } from 'react';
import { Col, Row, Card } from 'react-bootstrap';

// for manage user session
import { useAuth } from 'hooks/useAuth';

const Logout = () => {
	const { logout } = useAuth();

    useState(() => {
        logout()
    }, [])    

	return (
		<Fragment>
			<Row className="align-items-center justify-content-center g-0 min-vh-100">
				<Col lg={5} md={5} className="py-8 py-xl-0">
					<Card>
						<Card.Body className="p-6">
							<p>Logout...</p>
						</Card.Body>
					</Card>
				</Col>
			</Row>
		</Fragment>
	);
};

export default Logout;
