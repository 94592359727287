// import node module libraries
import React, { Fragment, useState, useEffect } from 'react';
import { Col, Row, Container, Tab, Spinner } from 'react-bootstrap';

// import custom components
import FormSelect from 'components/elements/form-select/FormSelect';
import GridListViewButton from 'components/elements/miscellaneous/GridListViewButton';
import PageHeading from 'components/marketing/common/page-headings/PageHeading';

// import sub components
import FilterOptions from './FilterOptions';
import CourseGridView from './CourseGridView';
import CourseListView from './CourseListView';

// import rest api
import { getCourses } from 'services/api/KumajiroApi';

// import hooks
import { useFilters } from 'context/providers/FiltersProvider';

// for error toast message
import Notify from 'services/Notify';

const CourseFilterPage = () => {
	const filters = useFilters()
	
	const sortByOptions = [
		{ value: '-released_at', label: 'Newest' },
		{ value: '-popularity', label: 'Popularity' }
	];

	const handleSortChange = (event) => {
		filters.courses.setSortBy(event.target.value)		
		filters.courses.setPage(0)
	};

	const handleDisplayChange = (display) => {
		filters.courses.setDisplay(display)
	};	

	const [courses, setCourses] = useState([])
	const [totalResult, setTotalResult] = useState(0)
	const [totalPage, setTotalPage] = useState(0)	

	useEffect(() => {
		setCourses(null)
		setTotalResult(0)
		setTotalPage(0)		
		getCourses(
			filters.courses.page + 1,
			21,
			filters.courses.contentTypes,
			filters.courses.categories,
			filters.courses.subCategories, 
			filters.courses.difficulties, 
			filters.courses.sortBy,
			filters.courses.query
		)
		.then(res => {	
			if (res.status === 204) {
				setCourses([])
			} else {
				setCourses(res.data.data)
				setTotalResult(res.data.totalResult)
				setTotalPage(res.data.totalPage)
			}
		})
		.catch((error) => {
			Notify.error("Failed to get data")
			setCourses([])
		});
	}, [filters.courses.page, filters.courses.query, filters.courses.contentTypes, filters.courses.categories, filters.courses.subCategories, filters.courses.difficulties, filters.courses.sortBy])

	useEffect(() => {		
		setTimeout(() => {
			window.scrollTo(0, 0);
		}, 50);
	}, [filters.courses.page]);

	return (
		<Fragment>
			{/* Page header */}
			<PageHeading pagetitle="Courses" />

			{/* Content */}
			<div className="py-6">
				<Container>
					<Tab.Container defaultActiveKey={filters.courses.display} activeKey={filters.courses.display}>
						<Row>
							<Col lg={12} md={12} sm={12} className="mb-4">
								<Row className="d-lg-flex justify-content-between align-items-center">
									<Col md={6} lg={8} xl={9}>
										<h4 className="mb-3 mb-lg-0">
											{totalResult} result{totalResult > 0 && "s"}
										</h4>
									</Col>
									<Col md={6} lg={4} xl={3} className="d-inline-flex">
										<div className="me-2">
											<GridListViewButton keyGrid="grid" keyList="list" onChange={handleDisplayChange} defaultValue={filters.courses.display} />
										</div>
										<FormSelect options={sortByOptions} placeholder="Sort by" onChange={handleSortChange} defaultselected={filters.courses.sortBy} />
									</Col>
								</Row>
							</Col>
							<Col xl={3} lg={3} md={4} sm={12} className="mb-4 mb-lg-0">
								<FilterOptions />
							</Col>
							{/* Tab content */}
							<Col xl={9} lg={9} md={8} sm={12}>
								{!courses &&
									<div className="d-flex align-items-center justify-content-center" style={{height: "40vh"}}>
										<Spinner animation="border" variant="primary" />
									</div>		
								}
								{courses && 
									<Tab.Content>
										<Tab.Pane eventKey="grid" className="pb-4 px-0">
											<CourseGridView items={courses} pageCount={totalPage} />
										</Tab.Pane>
										<Tab.Pane eventKey="list" className="pb-4 px-0 react-code">
											<CourseListView items={courses} pageCount={totalPage} />
										</Tab.Pane>
									</Tab.Content>
								}																
							</Col>
						</Row>
					</Tab.Container>
				</Container>
			</div>
		</Fragment>
	);
};

export default CourseFilterPage;
