// import node module libraries
import React, { Fragment, useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Col, Row, Card, Breadcrumb, Spinner } from 'react-bootstrap';

// import sub custom components
import BooksTable from './BooksTable';

// rest api
import { deleteBook, getBooks, updateBook } from 'services/api/KumajiroApi';

// to display success / error messages
import Notify from 'services/Notify';

const AllBooks = () => {
	const history = useHistory()

	const [books, setBooks] = useState([]);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		refreshData();
	}, []);

	const handleCreate = () => {
		history.push(`/admin/library/editor/create-book`)
	};

	const handleUpdate = (book) => {
		history.push(`/admin/library/editor/update-book/${book.id}`)
	};

	const handlePublish = (book, publish) => {
		setLoading(true)
		const data = {
			id: book.id,
			version: publish ? book.version : null,
			published: publish
		}
		updateBook(book.id, data)
			.then((response) => {
				setLoading(false)
				refreshData()
				Notify.success(publish ? "Book published" : "Book suspended")
			})
			.catch((error) => {
				setLoading(false)
				Notify.error(error.response?.data?.message ?? (publish ? "Failed to publish the book" : "Failed to suspend the book"))
			})
	};

	const handleDelete = (book) => {
		setLoading(true)
		deleteBook(book.id)
			.then((response) => {
				setLoading(false)
				refreshData()
				Notify.success("Book deleted")
			})
			.catch((error) => {
				setLoading(false)
				Notify.error(error.response?.data?.message ?? "Failed to delete book")
			})
	};

	const refreshData = () => {
		setLoading(true)
		getBooks()
			.then(response => {
				setLoading(false)
                if (response.status === 204) {
                    setBooks([])
                } else {                    
				    setBooks(response.data)
                }   
			})
			.catch(error => {
				setLoading(false)
				setBooks([])
				Notify.error(error.response?.data?.message ?? "An error occured")				
			});
	};

	return (
		<Fragment>
			<Row>
				<Col lg={12} md={12} sm={12}>
					<div className="border-bottom pb-4 mb-4 d-md-flex align-items-center justify-content-between">
						<div className="mb-3 mb-md-0">
							<h1 className="mb-1 h2 fw-bold">All Books</h1>
							<Breadcrumb>
								<Breadcrumb.Item href="/admin">Dashboard</Breadcrumb.Item>
								<Breadcrumb.Item href="#">Books</Breadcrumb.Item>
								<Breadcrumb.Item active>All</Breadcrumb.Item>
							</Breadcrumb>
						</div>
						<div>
							{loading === true && 
							 	<Spinner animation="border" variant="primary" size="sm" className='me-5' />
							}
							<Link to="#" className="btn btn-primary" onClick={handleCreate}>
								Add New Book
							</Link>
						</div>
					</div>
				</Col>
			</Row>
			<Row>
				<Col lg={12} md={12} sm={12}>
					<Card>						
						<BooksTable 
							items={books}
							onUpdate={handleUpdate}
							onPublish={handlePublish}
							onDelete={handleDelete} />
					</Card>
				</Col>
			</Row>
		</Fragment>
	);
};

export default AllBooks;
