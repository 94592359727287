// import node module libraries
import React, { useContext, Fragment } from 'react';
import { Link } from 'react-router-dom';
import {
	Accordion,
	useAccordionButton,
	AccordionContext,
	ListGroup	
} from 'react-bootstrap';

import BookTocProgressButton from './BookTocProgressBtn';
import { useState } from 'react';
import { useEffect } from 'react';

const BookToc = ({ book, itemClass, onUpdateProgress = (chapterId, completed) => {}}) => {

	const [readingSection, setReadingSection] = useState(book.chapter?.bookSectionId ?? book.nextChapter?.bookSectionId ?? book.sections[0].id)

	useEffect(() => {
		setReadingSection(book.chapter?.bookSectionId ?? book.nextChapter?.bookSectionId ?? book.sections[0].id)
	}, [book])
	
	const ContextAwareToggle = ({ children, eventKey, callback }) => {		
		const { activeEventKey } = useContext(AccordionContext);

		const decoratedOnClick = useAccordionButton(
			eventKey,
			() => callback && callback(eventKey)
		);

		const isCurrentEventKey = activeEventKey === eventKey;

		return (
			<Fragment>
				<Link
					to="#"
					onClick={decoratedOnClick}
					aria-expanded={isCurrentEventKey}
					className="d-flex align-items-center text-inherit text-decoration-none h4 mb-0"
					data-bs-toggle="collapse"
					aria-controls="courseTwo"
				>
					<div className="me-auto">{children.name}</div>
					<span className="chevron-arrow ms-4">
						<i className="fe fe-chevron-down fs-4"></i>
					</span>
				</Link>
			</Fragment>
		);
	};

	return (
		<Fragment>
			<Accordion activeKey={readingSection}>
				<ListGroup as="ul" variant="flush">
					{book.sections.map((item, index) => {
						if (item.chapters.length === 0) {
							return (
								<ListGroup.Item
									key={index}
									as="li"
									className={`${itemClass ? itemClass : ''}`}
								>
									<ContextAwareToggle eventKey={item.id} callback={eventKey => setReadingSection(eventKey)}>
										{item}
									</ContextAwareToggle>
									<Accordion.Collapse eventKey={item.id}>
										<ListGroup variant="flush">
											<ListGroup.Item className="border-0 fs-5 px-0 py-4">
												{item.name}
											</ListGroup.Item>
										</ListGroup>
									</Accordion.Collapse>
								</ListGroup.Item>
							);
						} else {
							return (
								<ListGroup.Item
									key={index}
									as="li"
									className={`${itemClass ? itemClass : ''}`}
								>
									<ContextAwareToggle eventKey={item.id} callback={eventKey => setReadingSection(eventKey)}>
										{item}
									</ContextAwareToggle>
									<Accordion.Collapse eventKey={item.id} className="test">
										<ListGroup className="py-4" as="ul">
											{item.chapters.map((subitem) => (
												<ListGroup.Item
													key={subitem.id + subitem.completed}
													as="li"
													className="px-0 py-1 border-0"
												>
													<Link
														to={`/library/${book.code}/${subitem.code}`}
														className={`d-flex justify-content-between align-items-center text-inherit text-decoration-none`}
													>
														<div className="text-truncate ">
														<BookTocProgressButton
																item={subitem}
																onUpdateProgress={onUpdateProgress}/>
															<span className="fs-5">{`${subitem.number}. ${subitem.name}`}</span>
														</div>
														<div className="text-truncate">
															
														</div>
													</Link>
												</ListGroup.Item>
											))}
										</ListGroup>
									</Accordion.Collapse>
								</ListGroup.Item>
							);
						}
					})}
				</ListGroup>
			</Accordion>
		</Fragment>
	);
};

export default BookToc;
