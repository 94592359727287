import { ToggleButtonGroup, ToggleButton } from 'react-bootstrap';

const GridListViewButton = ({ keyGrid, keyList, defaultValue=keyGrid, onChange=(e) => {} }) => {
	return (
		<div className="me-2">
			<ToggleButtonGroup
				 type="radio" 
				 name="display" 
				 defaultValue={[defaultValue]} 
				 onChange={e => {onChange(e)}}
				 className="float-end flex-nowrap">
				<ToggleButton id="tbg-check-1" value={keyGrid} variant="outline-primary">
					<span className="fe fe-grid"></span>
				</ToggleButton>
				<ToggleButton id="tbg-check-2" value={keyList} variant="outline-primary">
					<span className="fe fe-list"></span>
				</ToggleButton>				
			</ToggleButtonGroup>			
		</div>
	);
};

export default GridListViewButton;
