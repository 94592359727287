// import node module libraries
import { Fragment, useState } from 'react';
import { Link } from 'react-router-dom';
import { Col, Row, Card, Form, Button, Image, Spinner } from 'react-bootstrap';

// import sub-components
import DarkLightMode from 'layouts/DarkLightMode';

// import media files
import Logo from 'assets/images/brand/logo/logo.svg';

// for success / error message
import Notify from 'services/Notify';

// import rest api
import { resetPassword } from 'services/api/KumajiroApi';

const ForgetPassword = () => {
	const [mail, setMail] = useState('')
	const [loading, setLoading] = useState(false)

	const handleSubmit = (event) => {
		event.preventDefault();

		setLoading(true)
		resetPassword(mail)
			.then(res => {
				setLoading(false)
				setMail("")
				Notify.success('A mail has been sent')
			})
			.catch((error) => {
				setLoading(false)
				Notify.error(error.response?.data?.message ?? "An error occured")				
			});
	}

	return (
		<Fragment>
			<div className='position-absolute top-0 end-0 me-2 mt-2 d-none d-lg-block'>
				<DarkLightMode />
			</div>	
			<Row className="align-items-center justify-content-center g-0 min-vh-100">
				<Col lg={5} md={5} className="py-8 py-xl-0">
					<Card>
						<Card.Body className="p-6">
							<div className="mb-4">
								<Link to="/">
									<Image src={Logo} className="mb-4 brand" alt="" />
								</Link>
								<h1 className="mb-1 fw-bold">Forgot Password</h1>
								<span>Fill the form to reset your password.</span>
							</div>
							{/* Form */}
							<Form onSubmit={handleSubmit}>
								<Row>
									<Col lg={12} md={12} className="mb-3">
										{/*  email */}
										<Form.Label>Email</Form.Label>
										<Form.Control
											type="email"
											id="email"
											placeholder="Enter your email"
											value={mail}
											onChange={e => setMail(e.target.value)}
											required
										/>
									</Col>
									<Col lg={12} md={12} className="mb-3 d-grid gap-2">
										{/* Button */}
										{!loading && 
											<Button variant="primary" type="submit">
												Reset my password
											</Button>
										}
										{ loading && 
											<Button variant="primary" type="submit" disabled>
												<Spinner
													as="span"
													animation="grow"
													size="sm"
													role="status"
													aria-hidden="true"/>
													{' '} Loading...
											</Button>
										}										
									</Col>
								</Row>
								<span>
									Return to <Link to="/sign-in">Sign in</Link>
								</span>
							</Form>
						</Card.Body>
					</Card>
				</Col>
			</Row>
		</Fragment>
	);
};

export default ForgetPassword;
