import { AuthContext } from "context/Context";
import { useProtectedLocalStorage } from "hooks/useProtectedLocalStorage";
import { useMemo } from "react";
import { useHistory } from "react-router-dom";
import { doLogout } from "services/api/KumajiroApi";
import UserSession from "services/UserSession";

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useProtectedLocalStorage("user", null);
  const [auth2Token, setAuth2Token] = useProtectedLocalStorage("auth2Token", '');
  const history = useHistory();

  const pushOtp = async (token) => {
    setAuth2Token({token: token, startTime: new Date().getTime()})
    history.push("/otp")
  };

  // call this function when you want to authenticate the user
  const login = async (data) => {
    setUser(data);
    history.push("/home");
  };

  // call this function to sign out logged in user
  const logout = () => {    
    doLogout(UserSession.getLocalRefreshToken())
      .then((response) => {})
      .catch((err) => {})
    setUser(null);
    history.replace("/", { replace: true });
  };

  const value = useMemo(
    () => ({
      user,
      auth2Token,
      login,
      logout,
      pushOtp
    }),
    [user, auth2Token]
  );
  
  return (
    <AuthContext.Provider value={value}>
        {children}
    </AuthContext.Provider>
  );
};
