// import node module libraries
import { Fragment } from 'react';
import { Col, Row } from 'react-bootstrap';
import ReactPaginate from 'react-paginate';
import { ChevronLeft, ChevronRight } from 'react-feather';

// import sub components
import CourseCard from '../CourseCard';

// import hooks
import { useFilters } from 'context/providers/FiltersProvider';

const CourseListView = (props) => {
	const filters = useFilters()
	
	const { items, pageCount } = props;

	// paging start	
	const changePage = ({ selected }) => {		
		filters.courses.setPage(selected)
	};
	const displayRecords = items.map((item, index) => {
		return (
			<Col sm={12} md={12} lg={12} key={index}>
				<CourseCard item={item} viewby="list" />
			</Col>
		);
	});
	// end of paging

	return (
		<Fragment>
			<Row>
				{items.length > 0 ? (
					displayRecords
				) : (
					<Col>No matching records found.</Col>
				)}
			</Row>

			<ReactPaginate
				previousLabel={<ChevronLeft size="14px" />}
				nextLabel={<ChevronRight size="14px" />}
				pageCount={pageCount}
				onPageChange={changePage}
				forcePage={filters.courses.page}
				containerClassName={'justify-content-center mb-0 pagination'}
				previousLinkClassName={'page-link mx-1 rounded'}
				nextLinkClassName={'page-link mx-1 rounded'}
				pageClassName={'page-item'}
				pageLinkClassName={'page-link mx-1 rounded'}
				disabledClassName={'paginationDisabled'}
				activeClassName={'active'}
			/>
		</Fragment>
	);
};
export default CourseListView;
