
export const AppConfigReducer = (state, action) => {
    const { type, payload } = action;
    switch (type) {
      case 'CHANGE_SKIN':
        localStorage.setItem('skin', payload.skin); 
        return {
          ...state,
          skin : payload.skin
        }   
      default:
        return state;
    }
  };
  