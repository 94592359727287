// import node module libraries
import React, { Fragment, useState, useEffect } from 'react';
import {
	Col,
	Row,
	Container,
	Card,
	Image,
	Nav,
	Tab,
	OverlayTrigger,
	Tooltip,
	Spinner,
	Button
} from 'react-bootstrap';
import { useHistory, useParams } from 'react-router-dom';

// import MDI icons
import Icon from '@mdi/react';
import { mdiCalendar, mdiClock } from '@mdi/js';

// import custom components
import VideoPlayer from 'components/elements/video/VideoPlayer';
import LevelIcon from 'components/marketing/common/miscellaneous/LevelIcon';
import CourseCollectionAccordionProgress from '../course-single/CourseCollectionAccordionProgress';

// import sub components tabs
import DescriptionTab from 'components/marketing/pages/courses/course-single/DescriptionTab';
import MaterialsTab from '../course-single/MaterialsTab';
import TranscriptionTab from '../course-single/TranscriptionTab';
import CourseCard from '../CourseCard';

// import rest api
import { getCourse, updateCourseUserBookmark, updateCourseUserProgress } from 'services/api/KumajiroApi';

// to display success or error message
import Notify from 'services/Notify';

// import utils
import { getHHMM } from 'helper/utils';

const CourseSingle2 = () => {
	const { courseId } = useParams();

    const [course, setCourse] = useState(null)
	const [video, setVideo] = useState(null)
	const [lastRefreshTime, setLastRefreshTime] = useState(0)
	const [completed, setCompleted] = useState(false)
	const [bookmarked, setBookmarked] = useState(false)
	const [loadingUserProgress, setLoadingUserProgress] = useState(false)	
	const [loadingUserBookmark, setLoadingUserBookmark] = useState(false)	

	const history = useHistory();

	const updateState = (course, selectedVideo = null) => {
		// compute progress
		if (course.contentType === 'collection') {
			const countVideos = (course.collection?.length ?? 1)
			const countCompletedVideos = course.collection?.filter((item) => item.completed === true)?.length ?? 0
			course.progress = Math.min(Math.round((countCompletedVideos / countVideos) * 100), 100)
		} else {
			course.progress = (course.completed === true) ? 100 : 0
		}							
		// get current video to play
		let currentVideo = selectedVideo ?? course
		if (!selectedVideo) {		
			if (course.contentType === 'collection') {
				const uncompletedVideo = course.collection?.filter((item) => item.completed !== true)
				if (uncompletedVideo.length > 0) {
					currentVideo = uncompletedVideo[0]
				} else {
					currentVideo = course.collection[0]
				}
			}
		}
		// get state foreach videos
		if (course.contentType === 'collection') {
			course.collection?.forEach((item) => {
				if (item === currentVideo) {
					item.status = 'continue'
				} else {
					item.status = item.completed === true ? 'finished' : 'pending'
					item.locked = item.videoUrl == null
				}						
			})
		} else {
			course.status = 'continue'
		}	
		// set states
		setVideo(currentVideo)
		setCourse(course)				
		setCompleted(course.completed)
		setBookmarked(course.bookmarked)
		forceRefresh()
	}

	useEffect(() => {
        setCourse(null)
		getCourse(courseId)
			.then(res => {
				const course = res.data				
				updateState(course)			
			})
			.catch((error) => {
				if (error.response?.status === 404) {
					history.replace("/404-error", { replace: true });
				} else {
					history.replace("/500-error", { replace: true });
				}
			});       
    }, [courseId, history])
	
	useEffect(() => {		
		window.scrollTo(0, 0);
		if (!course || !video) return
		updateState(course, video)
	}, [course, video])

	const forceRefresh = () => {
		setLastRefreshTime(Date.now())
	}

	const handleCompletedClick = (event) => {
        event.preventDefault()
        completeCourse()
    }

	const handleBookmarkedClick = (event) => {
        setLoadingUserBookmark(true)
        updateCourseUserBookmark(course.id, !bookmarked)
            .then(res => {
				setLoadingUserBookmark(false)				
                // Update the completed button
				if (!bookmarked) {
					Notify.success(`You have bookmarked ${course.name}`)
				} else {
					Notify.success(`You have removed the bookmark for ${course.name}`)
				}
                setBookmarked(!bookmarked)				
            })
            .catch((error) => {
				Notify.error("Failed to update bookmarks")
                setLoadingUserBookmark(false)
            });
	}

	const completeCourse = () => {
		setLoadingUserProgress(true)
        updateCourseUserProgress(course.id, !completed)
            .then(res => {
				setLoadingUserProgress(false)
				Notify.success(`Course marked as ${!completed ? "completed" : "uncompleted"}`)				
                // Update the completed button
				course.completed = !completed
				course.collection?.forEach((item) => {
					item.completed = !completed
				})
                setCompleted(!completed)
				// refresh UI
				updateState(course)
            })
            .catch((error) => {
				Notify.error("Failed to update the progress")
                setLoadingUserProgress(false)
            });
	}

	const nextVideo = () => {
		if (!course.collection) return false
		const currentIndex = course.collection.indexOf(video)
		if (currentIndex >= 0 && currentIndex < course.collection.length - 1) {
			setVideo(course.collection[currentIndex + 1])
			return true
		} else {
			return false
		}
	}

	const handleVideoItemClick = (item) => {
		setVideo(item)
	}

	const handlePlayerProgress = (e) => {
		if (e.played === 1.0) {
			if (course.contentType === 'collection') {
				updateCourseUserProgress(video.id, true)
					.then(res => {})
					.catch((error) => {});			
				course.collection?.forEach((item) => {
					if (item.id === video.id) {
						video.completed = true
					}
				})
				const uncompletedVideos = course.collection?.filter((item) => item.completed !== true)
				if (uncompletedVideos.length === 0) {
					completeCourse()
				} else {
					nextVideo()
				}
			} else {
				completeCourse()
			}
		}
	}

	if (!course) {
		return (
			<Fragment>
				<div className="d-flex align-items-center justify-content-center" style={{height: "40vh"}}>
					<Spinner animation="border" variant="primary" />
				</div>
			</Fragment>
		);
	}

	return (
		<Fragment>
			<div className="py-lg-5 py-5">
				<Container>
					{/*  Video section  */}
					<Row>
						<Col lg={12} md={12} sm={12} className="mb-5">
							<div
								className="rounded-3 position-relative w-100 d-block overflow-hidden p-0"
								style={{ height: '600px' }}
							>
								<VideoPlayer 
									url={video.videoUrl}
									onProgress={handlePlayerProgress} />
							</div>
						</Col>
					</Row>
					{/*  Content  */}
					<Row>
						<Col xl={8} lg={12} md={12} sm={12} className="mb-4 mb-xl-0">
							{/*  Card  */}
							<Tab.Container defaultActiveKey="description">
								<Card className="mb-5">
									{/*  Card body  */}
									<Card.Body>
										<div className="d-flex justify-content-between align-items-center">
											<h1 className="fw-semi-bold mb-2">
												{course.name}
											</h1>
											<OverlayTrigger
												key="top"
												placement="top"
												overlay={
													<Tooltip id="tooltip-top">Add to Bookmarks</Tooltip>
												}
											>
												<Button variant='transparent' size="lg" className="text-muted bookmark" onClick={handleBookmarkedClick} disabled={loadingUserBookmark === true}>
													{loadingUserBookmark === true &&							
														<Spinner
															as="span"
															animation="grow"
															size="sm"
															role="status"
															aria-hidden="true"/>
													}
													{loadingUserBookmark === false &&							
														<i className={`${bookmarked ? 'fa fa-bookmark text-primary' : 'fe fe-bookmark'}`}></i>
													}
												</Button>												
											</OverlayTrigger>
										</div>
										<div className="d-flex mb-5">
											<span className="d-none d-md-block">
												<LevelIcon level={course.difficulty} />
												<span className='text-capitalize'>{course.difficulty}</span>
											</span>
											<span className="ms-4 d-none d-md-block">
												<Icon path={mdiCalendar} size={0.7} />{' '}
												<span>{new Date(course.publishingDate).toLocaleDateString('en-GB')}</span>
											</span>
											<span className="ms-4 d-none d-md-block">
												<Icon path={mdiClock} size={0.7} />{' '}
												<span>{getHHMM(course.duration)}</span>
											</span>											
										</div>
										<div className="d-flex justify-content-between">
											<div className="d-flex align-items-center">
												<Image
													src={course.editor.avatar}
													className="rounded-circle avatar-md"
													alt=""
												/>
												<div className="ms-2 lh-1">
													<h4 className="mb-1">{course.author}</h4>
													<p className="fs-6 mb-0">{course.editor.name}</p>
												</div>
											</div>
											<div>
												<Button variant={completed === true ? "primary" : "outline-primary"} size="sm" className="me-1" disabled={loadingUserProgress === true} onClick={handleCompletedClick}>
													{loadingUserProgress === true && 
														<>
															<Spinner
																as="span"
																animation="grow"
																size="sm"
																role="status"
																aria-hidden="true"/>
															{' '}Progress
														</>
													} 
													{loadingUserProgress === false &&
														<><i className="fe fe-check"></i> {completed ? "Completed" : "Mark complete"}</>
													}
												</Button> 
											</div>
										</div>
									</Card.Body>
									{/*  Nav tabs  */}
									<Nav className="nav-lt-tab">
										{['Description', 'Materials', 'Transcript'].map(
											(item, index) => (
												<Nav.Item key={index}>
													<Nav.Link
														href={`#${item.toLowerCase()}`}
														eventKey={item.toLowerCase()}
														className="mb-sm-3 mb-md-0"
													>
														{item}
													</Nav.Link>
												</Nav.Item>
											)
										)}
									</Nav>
								</Card>
								{/*  Card  */}
								<Card className="rounded-3">
									{/*  Card body  */}
									<Card.Body className="p-0">
										<Tab.Content>
											<Tab.Pane eventKey="description" className="pb-4 p-4">
												{/* Description Tab */}
												<DescriptionTab course={course} />
											</Tab.Pane>											
											<Tab.Pane eventKey="materials" className="pb-4 p-4">
												{/* Materials Tab */}
												<MaterialsTab course={video} />
											</Tab.Pane>
											<Tab.Pane eventKey="transcript" className="pb-4 p-4">
												{/* Transcript Tab */}
												<TranscriptionTab course={video}/>
											</Tab.Pane>
										</Tab.Content>
									</Card.Body>
								</Card>
							</Tab.Container>
						</Col>
						<Col xl={4} lg={12} md={12} sm={12}>
							<Card>
								<CourseCollectionAccordionProgress 
									course={course} 
									refreshTime={lastRefreshTime}
									onItemClicked={handleVideoItemClick}/>
							</Card>
						</Col>
					</Row>

					{/* Previous versions */}
					{course.previousVersion && course.previousVersion.length > 0 && 
					<Container>
						<Row>
							<Col xl={12} lg={12} md={12} sm={12}>
								<div className="my-5">
									<h2>Others versions</h2>
								</div>
							</Col>
							{course.previousVersion.map((item, index) => (
								<Col xl={4} lg={4} md={6} sm={12} key={index} className="d-flex">
									<CourseCard item={item} />
								</Col>
							))}
						</Row>
					</Container>
					}	
				</Container>
			</div>
		</Fragment>
	);
};

export default CourseSingle2;
