// import node module libraries
import React, { useState, useEffect } from 'react';
import { Offcanvas, Spinner } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

// import sub components
import NoteCell from './NoteCell';
import NoteEditor from './NoteEditor';

// api rest
import { createBookNote, deleteBookNote, getBookNotes, updateBookNote } from 'services/api/KumajiroApi';

// To display success / error message
import Notify from 'services/Notify';

export const BookReaderNote = (props) => {
    const DEFAULT_COLOR = "#2196f3"

    const { bookId, chapterId } = useParams();

    const [loading, setLoading] =  useState(false)
    const [text, setText] =  useState('')
    const [color, setColor] =  useState(DEFAULT_COLOR)
    const [notes, setNotes] =  useState()
    const [editingNote, setEditingNote] = useState()

    const refreshData = () => {
        setLoading(true)
        getBookNotes(bookId)
            .then(response => {
                setLoading(false)
                if (response.status === 204) {
                    setNotes([])
                } else {                    
                    setNotes(response.data)
                }   
            })
            .catch(error => {
                setLoading(false)
                setNotes([])
                Notify.error(error.response?.data?.message ?? "Failed to get notes")				
            });
    }

    const handleEdit = (note) => {
        setEditingNote(note)
    }

    const handleDelete = (note) => {
        setLoading(true)
        deleteBookNote(bookId, note.id)
            .then(response => {
                setLoading(false)
                Notify.success("Note deleted")
                if (note.id === editingNote?.id) {
                    setEditingNote('')
                }                
                refreshData() 
            })
            .catch(error => {
                setLoading(false)
                Notify.error(error.response?.data?.message ?? "Failed to delete the note")				
            });
    }

    const handleSubmit = () => {
        const sanitizedText = text.replace(/\n{2,}/g, '\n').trim()  
        let promise
        if (editingNote) {
            promise = updateBookNote(bookId, editingNote.id, sanitizedText, color)
        } else {
            promise = createBookNote(bookId, chapterId, sanitizedText, color)
        }
        setLoading(true)
        promise
            .then(response => {
                setLoading(false)
                Notify.success(`${editingNote ? "Note updated" : "New note created"}`)
                setEditingNote(null)
                setText('')
                setColor(DEFAULT_COLOR)
                refreshData()
            })
            .catch(error => {
                setLoading(false)
                Notify.error(error.response?.data?.message ?? `Failed to ${editingNote ? "update" : "create"} the note`)
            });
    }

    useEffect(() => {
        refreshData()
    }, [bookId])

    useEffect(() => {
        setText(editingNote?.text ?? '')
        setColor(editingNote?.color ?? DEFAULT_COLOR)
    }, [editingNote])

	return (
        <Offcanvas {...props}>
            <Offcanvas.Header closeButton>
            <Offcanvas.Title>
                <h1 className="h2">
                    Notes <span className='fs-5 text-muted'>({notes?.length ?? 0})</span>{' '} 
                    {loading === true && 
                        <Spinner animation="border" variant="primary" size="sm" className='ms-2'/>
                    }
                </h1>
            </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body className='d-block mb-14 p-0'>                
                {notes?.map((note) => (
                    <NoteCell 
                        note={note} 
                        onEdit={handleEdit}
                        onDelete={handleDelete}
                        key={note.id} />
                ))}
                <NoteEditor
                    bookId={bookId}
                    chapterId={chapterId}
                    text={text} 
                    color={color}
                    onChangeText={text => setText(text)}
                    onChangeColor={color => setColor(color)}
                    onSubmit={handleSubmit} />
            </Offcanvas.Body>
        </Offcanvas>		
	);
};

export default BookReaderNote;
