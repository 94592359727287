// import node module libraries
import React, { Fragment, useMemo, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
	useTable,
	useFilters,
	useGlobalFilter,
	usePagination,
	useRowSelect
} from 'react-table';
import {
	Col,
	Row,
	Dropdown,
	Card,
	Breadcrumb,
	Button,
	Modal,
	Table,
	Spinner
} from 'react-bootstrap';
import { Trash, Edit, MoreVertical } from 'react-feather';

// import custom components
import GlobalFilter from 'components/elements/advance-table/GlobalFilter';
import Pagination from 'components/elements/advance-table/Pagination';
import DotBadge from 'components/elements/bootstrap/DotBadge';

// import sub components
import AddNewCategoryPopup from './AddNewCategoryPopup';

// rest api
import { deleteCategory, getCategories } from 'services/api/KumajiroApi';

// to display success/error messages
import Notify from 'services/Notify';

const CoursesCategory = () => {
	const [categories, setCategories] = useState([]);
	const [show, setShow] = useState(false);
	const [editingCategoryId, setEditingCategoryId] = useState();
	const [loading, setLoading] = useState(false);
	const [submitLoading, setSubmitLoading] = useState(false);
	const handleClose = () => setShow(false);	
	const handleShow = () => setShow(true);	

	useEffect(() => {
		refreshData();
	}, []);

	const handleSubmit = () => {
		handleClose()
		refreshData();
		setEditingCategoryId(null)
	};

	const handleEdit = (categoryId) => {
		setEditingCategoryId(categoryId)		
		handleShow()
	};

	const handleCreate = () => {
		setEditingCategoryId(null)
		handleShow()
	};

	const handleDelete = (categoryId) => {
		setLoading(true)
		deleteCategory(categoryId)
			.then(response => {
				setLoading(false)
				refreshData()
				Notify.success("Category deleted")	
			})
			.catch(error => {
				setLoading(false)
				Notify.error(error.response?.data?.message ?? "Failed to remove category")				
			});
	};

	const refreshData = () => {
		setLoading(true)
		getCategories()
			.then(response => {
				setLoading(false)
				setCategories(response.data)
			})
			.catch(error => {
				setLoading(false)
				setCategories([])
				Notify.error(error.response?.data?.message ?? "An error occured")				
			});
	};

	// The forwardRef is important!!
	// Dropdown needs access to the DOM node in order to position the Menu
	const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
		<Link
			to=""
			ref={ref}
			onClick={(e) => {
				e.preventDefault();
				onClick(e);
			}}
		>
			{children}
		</Link>
	));

	const ActionMenu = ({category}) => {
		const categoryId = category.id
		return (
			<Dropdown>
				<Dropdown.Toggle as={CustomToggle}>
					<MoreVertical size="15px" className="text-secondary" />
				</Dropdown.Toggle>
				<Dropdown.Menu align="end">
					<Dropdown.Header>ACTION</Dropdown.Header>
					<Dropdown.Item eventKey="2" onClick={(e) => handleEdit(categoryId)}>
						{' '}
						<Edit size="18px" className="dropdown-item-icon" /> Edit
					</Dropdown.Item>
					<Dropdown.Item eventKey="3" onClick={e => handleDelete(categoryId)}>
						{' '}
						<Trash size="18px" className="dropdown-item-icon" /> Delete
					</Dropdown.Item>
				</Dropdown.Menu>
			</Dropdown>
		);
	};

	const columns = useMemo(
		() => [
			{ accessor: 'name', Header: 'TITLE' },
			{ accessor: 'code', Header: 'CODE' },
			{ accessor: 'rwId', Header: 'RW_ID' },
			{ accessor: 'rank', Header: 'RANK' },			
			{
				accessor: 'status',
				Header: 'STATUS',
				Cell: ({ value }) => {					
					return (
						<DotBadge bg='success'></DotBadge>
					);
				}
			}
		],
		[]
	);

	const data = useMemo(() => categories, [categories]);

	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		page,
		nextPage,
		previousPage,
		state,
		gotoPage,
		pageCount,
		prepareRow,
		setGlobalFilter
	} = useTable(
		{
			columns,
			data,
			initialState: {
				pageSize: 10,
				hiddenColumns: columns.map((column) => {
					if (column.show === false) return column.accessor || column.id;
					else return false;
				})
			}
		},
		useFilters,
		useGlobalFilter,
		usePagination,
		useRowSelect,
		(hooks) => {
			hooks.visibleColumns.push((columns) => [				
				...columns,
				{
					id: 'selection',
					Header: ({ getToggleAllRowsSelectedProps }) => (
						''
					),
					Cell: ({ row }) => <ActionMenu category={row.original} />
				},
			]);
		}
	);

	const { pageIndex, globalFilter } = state;

	return (
		<Fragment>
			<Row>
				<Col lg={12} md={12} sm={12}>
					<div className="border-bottom pb-4 mb-4 d-md-flex align-items-center justify-content-between">
						<div className="mb-3 mb-md-0">
							<h1 className="mb-1 h2 fw-bold">Courses Categories</h1>
							<Breadcrumb>
								<Breadcrumb.Item href="/admin">Dashboard</Breadcrumb.Item>
								<Breadcrumb.Item href="#">Courses</Breadcrumb.Item>
								<Breadcrumb.Item active>Courses Categories</Breadcrumb.Item>
							</Breadcrumb>
						</div>
						<div>
							{loading === true && 
							 	<Spinner animation="border" variant="primary" size="sm" className='me-5' />
							}
							<Button variant="primary" onClick={handleCreate}>
								Add New Category
							</Button>
							<Modal show={show} onHide={handleClose} size="lg">
								<Modal.Header closeButton>
									<Modal.Title>Create New Category</Modal.Title>
								</Modal.Header>
								<Modal.Body>
									<AddNewCategoryPopup
										categoryId={editingCategoryId}
										onLoading={setSubmitLoading}
										onSubmited={handleSubmit} />
								</Modal.Body>
								<Modal.Footer className="d-flex justify-content-start border-0 pt-0">
									{/*  Action Buttons  */}
									{submitLoading &&
										<Button variant="primary" type="submit" form='create-category-form' disabled>
											<Spinner
												as="span"
												animation="grow"
												size="sm"
												role="status"
												aria-hidden="true"/>
											{' '} Loading...
										</Button>
									}
									{!submitLoading &&
										<Button variant="primary" type="submit" form='create-category-form'>
											{!editingCategoryId && 
												'Add New Category'
											}
											{editingCategoryId && 
												'Update Category'
											}
										</Button>
									}									
									<Button variant="white" onClick={handleClose}>
										Close
									</Button>
								</Modal.Footer>
							</Modal>
						</div>
					</div>
				</Col>
			</Row>

			<Row>
				<Col lg={12} md={12} sm={12}>
					<Card>
						<Card.Body className="p-0">
							<div className=" overflow-hidden">
								<Row>
									<Col
										lg={12}
										md={12}
										sm={12}
										className="mb-lg-0 mb-2 px-5 py-4"
									>
										<GlobalFilter
											filter={globalFilter}
											setFilter={setGlobalFilter}
											placeholder="Search Category"
										/>
									</Col>
								</Row>
							</div>

							<div className="table-responsive ">
								<Table {...getTableProps()} className="text-nowrap">
									<thead className="table-light">
										{headerGroups.map((headerGroup) => (
											<tr {...headerGroup.getHeaderGroupProps()}>
												{headerGroup.headers.map((column) => (
													<th {...column.getHeaderProps()}>
														{column.render('Header')}
													</th>
												))}
											</tr>
										))}
									</thead>
									<tbody {...getTableBodyProps()}>
										{page.map((row) => {
											prepareRow(row);
											return (
												<tr {...row.getRowProps()}>
													{row.cells.map((cell) => {
														return (
															<td {...cell.getCellProps()}>
																{cell.render('Cell')}
															</td>
														);
													})}
												</tr>
											);
										})}
									</tbody>
								</Table>
							</div>

							{/* Pagination @ Footer */}
							<Pagination
								previousPage={previousPage}
								pageCount={pageCount}
								pageIndex={pageIndex}
								gotoPage={gotoPage}
								nextPage={nextPage}
							/>
						</Card.Body>
					</Card>
				</Col>
			</Row>
		</Fragment>
	);
};

export default CoursesCategory;
