// import node module libraries
import { Fragment, useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import {
	Image,
	Card,
	Row,
	Col,
	ProgressBar,
	ListGroup,
	Badge,
	Spinner,
	Button
} from 'react-bootstrap';

// import media files
import Placeholder from 'assets/images/placeholder/placeholder.png';

// import custom components
import GKTooltip from 'components/elements/tooltips/GKTooltip';
import Ratings from 'components/marketing/common/ratings/Ratings';
import LevelIcon from 'components/marketing/common/miscellaneous/LevelIcon';
import LazyImage from 'components/elements/images/LazyImage';

// import utility file
import { numberWithCommas, getHHMM } from 'helper/utils';

// import rest api
import { getMedia, updateCourseUserBookmark } from 'services/api/KumajiroApi';

// for success/error message
import Notify from 'services/Notify';

const CourseCard = ({ item, viewby, showprogressbar, extraclass, cachable, onBookmarked = (id, bookmarked) => {} }) => {
	const [bookmarked, setBookmarked] = useState(item.bookmarked)
	const [loadingUserBookmark, setLoadingUserBookmark] = useState(false)

	useEffect(() => {
		setBookmarked(item.bookmarked)
		setLoadingUserBookmark(false)
	}, [item])

	const handleBookmarkedClick = (event) => {
        setLoadingUserBookmark(true)
        updateCourseUserBookmark(item.id, !bookmarked)
            .then(res => {
				setLoadingUserBookmark(false)				
                // Update the completed button
				if (!bookmarked) {
					Notify.success(`You have bookmarked ${item.name}`)
				} else {
					Notify.success(`You have removed the bookmark for ${item.name}`)
				}
                setBookmarked(!bookmarked)
				onBookmarked(item.id, !bookmarked)
            })
            .catch((error) => {
				Notify.error("Failed to update bookmarks")
                setLoadingUserBookmark(false)
            });
    }

	const getContentUrl = (item) => {
		if (item.contentType === 'screencast' || item.contentType === 'collection' || item.contentType === 'episode') {
			return `/video/${item.code}`
		} else {
			return `/course/${item.code}`
		}
	}

	/** Used in Course Index, Course Category, Course Filter Page, Student Dashboard etc...  */
	const GridView = () => {
		return (
			<Card className={`mb-4 card-hover ${extraclass}`}>
				{item.completed === true && 
					<Badge pill bg="primary"  className="mt-3 ms-3 position-absolute">Completed</Badge>
				}
				<Link to={getContentUrl(item)} className="text-center">
					<LazyImage
						src={item.thumbnail}
						alt={item.name}
						cachable={cachable}
						className="card-img-top rounded-top-md"
					/>
				</Link>
				{/* Card body  */}
				<Card.Body>
					<h3 className="h4 mb-2 text-truncate-line-2 ">
						<Link to={getContentUrl(item)} className="text-inherit">
							{item.name}
						</Link>
					</h3>
					<ListGroup as="ul" bsPrefix="list-inline" className="mb-3">
						<ListGroup.Item as="li" bsPrefix="list-inline-item">
							<i className="far fa-calendar me-1"></i>
							{new Date(item.publishingDate).toLocaleDateString('en-GB')}
						</ListGroup.Item>
						<ListGroup.Item as="li" bsPrefix="list-inline-item">
							<i className="far fa-clock me-1"></i>
							{getHHMM(item.duration)}
						</ListGroup.Item>
						<ListGroup.Item as="li" bsPrefix="list-inline-item">
							<LevelIcon level={item.difficulty} />
							<span className='text-capitalize'>{item.difficulty}</span>
						</ListGroup.Item>
					</ListGroup>					
				</Card.Body>
				{/* Card Footer */}
				<Card.Footer>
					<Row className="align-items-center g-0">
						<Col className="col-auto">
							<Image
								src={item.editor.avatar}
								className="rounded-circle avatar-xs"
								alt=""
							/>
						</Col>
						<Col className="col ms-2 text-truncate">
							<span>{item.author}</span>
						</Col>
						<Col className="col-auto">
							<GKTooltip tooltipText="Add to Bookmarks">
								<Button variant='transparent' size="sm" className="text-muted bookmark" onClick={handleBookmarkedClick} disabled={loadingUserBookmark === true}>
									{loadingUserBookmark === true &&							
										<Spinner
											as="span"
											animation="grow"
											size="sm"
											role="status"
											aria-hidden="true"/>
									}
									{loadingUserBookmark === false &&							
										<i className={`${bookmarked ? 'fa fa-bookmark text-primary' : 'fe fe-bookmark'}`}></i>
									}                            
								</Button>
							</GKTooltip>
						</Col>
					</Row>
					<span className={`${showprogressbar ? '' : 'd-none'}`}>
						{' '}
						<ProgressBar
							variant="success"
							now={item.progress}
							className="mt-3"
							style={{ height: '5px' }}
						/>
					</span>
				</Card.Footer>
			</Card>
		);
	};

	/** Used in Course Filter Page  */
	const ListView = () => {
		const [image, setImage] = useState(Placeholder);

		//prevent memory leaks
		const componentMounted = useRef(true);

		useEffect(() => {
			componentMounted.current = true
			const src = item.thumbnail
			if (src) {
				getMedia(src)
					.then(source => {
						if (componentMounted.current) {
							let file = new File([source.data], null, { type: source.type })
				    		setImage(URL.createObjectURL(file))
						}
					})
					.catch(err => {
						if (componentMounted.current) {
							setImage(Placeholder)
						}
					})
			} else {
				if (componentMounted.current) {
					setImage(Placeholder)
				}
			}		
			return () => {
				componentMounted.current = false;
			}
		}, [])

		return (
			<Card className="mb-4 card-hover">
				{item.completed === true && 
					<Badge pill bg="primary" className="mt-3 ms-3 position-absolute">Completed</Badge>
				}
				<Row className="g-0">
					<Link
						to={getContentUrl(item)}
						className="bg-cover img-left-rounded col-12 col-md-12 col-xl-3 col-lg-3 text-center"
						style={{
							backgroundImage: `url(${image})`,
							backgroundRepeat: 'no-repeat',
							backgroundSize: 'cover',
							backgroundPosition: 'center center'
						}}
					>
						<LazyImage
							src={item.thumbnail}
							alt={item.name}
							cachable={cachable}
							className="img-fluid d-lg-none invisible"
						/>
					</Link>
					<Col lg={9} md={12} sm={12}>
						{/* <!-- Card body --> */}
						<Card.Body>
							<h3 className="mb-2 text-truncate-line-2 ">
								<Link to={getContentUrl(item)} className="text-inherit">
									{item.name}
								</Link>								
							</h3>
							{/* <!-- List inline --> */}
							<ListGroup as="ul" bsPrefix="list-inline" className="mb-5">
								<ListGroup.Item as="li" bsPrefix="list-inline-item">
									<i className="far fa-calendar me-1"></i>
									{new Date(item.publishingDate).toLocaleDateString('en-GB')}
								</ListGroup.Item>
								<ListGroup.Item as="li" bsPrefix="list-inline-item">
									<i className="far fa-clock me-1"></i>
									{getHHMM(item.duration)}
								</ListGroup.Item>
								<ListGroup.Item as="li" bsPrefix="list-inline-item">
									<LevelIcon level={item.difficulty} />
									<span className='text-capitalize'>{item.difficulty}</span>
								</ListGroup.Item>				
							</ListGroup>
							{/* <!-- Row --> */}
							<Row className="align-items-center g-0">
								<Col className="col-auto">
									<Image
										src={item.editor.avatar}
										className="rounded-circle avatar-xs"
										alt=""/>
								</Col>
								<Col className="col ms-2 text-truncate">
									<span>{item.author}</span>
								</Col>
								<Col className="col-auto">
									<GKTooltip tooltipText="Add to Bookmarks">
										<Button variant='transparent' size="sm" className="text-muted bookmark" onClick={handleBookmarkedClick} disabled={loadingUserBookmark === true}>
											{loadingUserBookmark === true &&							
												<Spinner
													as="span"
													animation="grow"
													size="sm"
													role="status"
													aria-hidden="true"/>
											}
											{loadingUserBookmark === false &&							
												<i className={`${bookmarked ? 'fa fa-bookmark text-primary' : 'fe fe-bookmark'}`}></i>
											}                            
										</Button>
									</GKTooltip>
								</Col>
							</Row>
						</Card.Body>
					</Col>
				</Row>
			</Card>
		);
	};

	/** Used in Instructor Profile Page  */
	const ListGroupView = () => {
		return (
			<div className="d-lg-flex align-items-center">
				<div>
					<Image src={item.image} alt="" className="rounded img-4by3-lg" />
				</div>
				<div className="ms-lg-3 mt-2 mt-lg-0">
					<h4 className="text-primary-hover">
						{item.title}{' '}
						<Badge bg="light-success" className="text-success">
							New
						</Badge>
					</h4>
					<ListGroup
						as="ul"
						bsPrefix="list-inline"
						className="fs-6 mb-0 text-inherit"
					>
						<ListGroup.Item as="li" bsPrefix="list-inline-item">
							<i className="far fa-clock me-1"></i>
							{item.duration}
						</ListGroup.Item>
						<ListGroup.Item as="li" bsPrefix="list-inline-item">
							<LevelIcon level={item.level} />
							{item.level}
						</ListGroup.Item>
						<ListGroup.Item as="li" bsPrefix="list-inline-item">
							<span className="text-warning">
								{' '}
								<Ratings rating={item.rating} /> {item.rating.toFixed(1)}
							</span>
							<span className="fs-6 text-muted">
								{' '}
								({numberWithCommas(item.ratingby)})
							</span>
						</ListGroup.Item>
					</ListGroup>
				</div>
			</div>
		);
	};
	return (
		<Fragment>
			{viewby === 'grid' ? (
				<GridView />
			) : viewby === 'list' ? (
				<ListView />
			) : (
				<ListGroupView />
			)}
		</Fragment>
	);
};

// Specifies the default values for props
CourseCard.defaultProps = {
	viewby: 'grid',
	showprogressbar: false,
	extraclass: '',
	cachable: false
};

// Typechecking With PropTypes
CourseCard.propTypes = {
	item: PropTypes.object.isRequired,
	viewby: PropTypes.string,
	showprogressbar: PropTypes.bool,
	extraclass: PropTypes.string,
	cachable: PropTypes.bool
};

export default CourseCard;
