// import node module libraries
import React, { useState, useEffect } from 'react';
import { Col, Row, Card, Spinner } from 'react-bootstrap';
import { Link, useHistory, useParams, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';

// import custom components
import NavBarReaderDefault from 'layouts/marketing/navbars/NavBarReaderDefault';
import BookSearchResultList from './BookSearchResultList';
import BookToc from '../../BookToc';

// import hooks
import { useLocalStorage } from 'hooks/useLocalStorage';

// import api rest
import { getBook, searchInBook } from 'services/api/KumajiroApi';

// for error/success toast message
import Notify from 'services/Notify';

// The forwardRef is important!!
// Dropdown needs access to the DOM node in order to position the Menu
const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
	<Link
		to="#"
		ref={ref}
		onClick={(e) => {
			e.preventDefault();
			onClick(e);
		}}
	>
		{children}
	</Link>
));

export const BookSearch = (props) => {
    const { className, overflowHidden } = props;
	
	const { bookId } = useParams();
    const { search } = useLocation()

    const queryParams = queryString.parse(search)
    const query = queryParams.q

	const history = useHistory();

    const [book, setBook] = useState(null)
    const [result, setResult] = useState()
    const [showMenu, setShowMenu] = useLocalStorage("show-left-menu", true);

    const ToggleMenu = () => {
		return setShowMenu(!showMenu);
	};

	useEffect(() => {
		if (book?.id !== bookId) {
			setBook(null)
		}
        getBook(bookId)
          .then(res => {
			setBook(res.data)
          })
          .catch((error) => {
            if (error.response?.status === 404) {
				history.replace("/404-error", { replace: true });
			} else {
				history.replace("/500-error", { replace: true });
			}
          });
    }, [bookId, history])

    useEffect(() => {
        if (query === undefined) {
            // When theme changed, do nothing.
            return
        }

        if (!query) {
            // If empty query, reset results
            setResult([]) 
            return
        }
        
        setResult(null)        
        searchInBook(bookId, query)
        .then(res => {
			setResult(res.data)
          })
          .catch((error) => {
            setResult([])
            if (error.response?.status !== 404) {
				Notify.error("Failed to get data")
			}
          });
    }, [query]);

	return (
        <div
            id="db-wrapper"
            className={`${overflowHidden ? 'chat-layout' : ''} ${
                showMenu ? '' : 'toggled'
            }`}
        >
            <div className="navbar-vertical navbar">
                <Card className="course-sidebar " id="courseAccordion">
                    <SimpleBar style={{ maxHeight: '100vh' }}>
                        <Card>
                            <Card.Header>
                                <h4 className="mb-0">
                                {book == null &&
                                    'Table of content'
                                }
                                {book != null &&
                                    <Link className='text-inherit text-decoration-none' to={`/library/${bookId}`}>{book.name}</Link>
                                }
                                </h4>
                            </Card.Header>
                            {/* Course Index Accordion */}
                            {book != null &&
                                <BookToc 
                                    book={book} />
                            }
                        </Card>
                    </SimpleBar>
                </Card>
            </div>	
			<div id="page-content">
				<div className="header">
                    <NavBarReaderDefault
						showMenu={showMenu}
						SidebarToggleMenu={ToggleMenu}
                        bookId={bookId}
                        initialQuery={query}
					/>
				</div>
				<div className={`container-fluid ${className ? className : 'p-4'}`}>
					<Row>
						<Col lg="2">

						</Col>
						<Col className="col-lg-8 mt-4 mx-10 mt-lg-0 mx-auto">
                            {!result &&
								<div className="d-flex align-items-center justify-content-center" style={{height: "40vh"}}>
									<Spinner animation="border" variant="primary" />
								</div>								
							}
                            {result && 
                                <BookSearchResultList items={result}/>
                            }
						</Col>
						<Col lg="2">

						</Col>
					</Row>
				</div>
			</div>
		</div>
		
	);
};

export default BookSearch;
