// import node module libraries
import { Fragment, useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import {
	Image,
	Card,
	Row,
	Col,
	ProgressBar,
	ListGroup,
	Badge,
	DropdownButton,
	Dropdown
} from 'react-bootstrap';

// import custom components
import Ratings from 'components/marketing/common/ratings/Ratings';
import LevelIcon from 'components/marketing/common/miscellaneous/LevelIcon';
import LazyImage from 'components/elements/images/LazyImage';

// import media files
import Placeholder from 'assets/images/placeholder/placeholder.png';

// import utility file
import { numberWithCommas } from 'helper/utils';
import { bookParamToIcon } from 'services/books/utils';

// import rest api
import { deleteBookUserProgress, getMedia, setBookAsCompleted } from 'services/api/KumajiroApi';

// for success/error toast messages
import Notify from 'services/Notify';

const BookCard = ({ item, viewby, extraclass, cachable }) => {

	const [progress, setProgress] = useState(item.progress)

	useEffect(() => {
		setProgress(item.progress)
	}, [item])

	const handleResetProgress = (event) => {
		deleteBookUserProgress(item.id, item.version)
			.then(result => {
				setProgress(0)
				Notify.success("Progress reset")
			})
			.catch(err => {
				Notify.error("Failed to reset progress")
			})
	}

	const handleComplete = (event) => {
		setBookAsCompleted(item.id, item.version)
			.then(result => {
				setProgress(100)
				Notify.success("Book marked as completed")
			})
			.catch(err => {
				Notify.success("Failed to update the progress")
			})
	}

	/** Used in Course Index, Course Category, Course Filter Page, Student Dashboard etc...  */
	const GridView = () => {
		return (
			<Card className={`mb-4 card-hover ${extraclass}`}>
				{progress === 100 && 
					<Badge pill bg="primary"  className="mt-3 ms-3 position-absolute">Completed</Badge>
				}
				<Link to={`/library/${item.code}`} className='text-center'>
					<LazyImage
						src={item.info.cover}
						cachable={cachable}
						alt=""
						className="card-img-top rounded-top-md"
					/>
				</Link>
				{/* Card body  */}
				<Card.Body>
					<h3 className="h4 mb-2 text-truncate-line-2 ">
						<Link to={`/library/${item.code}`} className="text-inherit">
							{item.info.editionName}
						</Link>
					</h3>
					<ListGroup as="ul" bsPrefix="list-inline" className="mb-2">
						{item.params?.filter((param) => param.code === "VERSION").map((param) => {
							return (	
								<ListGroup.Item as="li" bsPrefix="list-inline-item" key={item.id + "-" + param.code}>
									<i className={`fe ${bookParamToIcon(param.code)} me-1`}></i>
									{param.value}
								</ListGroup.Item>							
							);
						})}						
					</ListGroup>            
				</Card.Body>
                {/* Card Footer */}
				<Card.Footer>	
                    <Row className="align-items-center g-0">
						<Col className="col-auto">
							<Image
								src={item.editor.avatar}
								className="rounded-circle avatar-xs"
								alt=""
							/>
						</Col>
						<Col className="col ms-2 text-truncate">
							<span>{item.info.author}</span>
						</Col>											
					</Row>	
					<Row>
						<Col>
							{progress > 0 && progress < 100 &&
								<span>
									{' '}
									<ProgressBar
										variant="success"
										now={progress}
										className="mt-3"
										style={{ height: '5px' }}
									/>
								</span>
							}
						</Col>
						<Col className="col-auto">
							{progress > 0 && 
								<DropdownButton title="" variant="transparent" size="sm">
									<Dropdown.Item eventKey="1" onClick={handleResetProgress}>Reset Progress</Dropdown.Item>
									{progress < 100 && 
										<Dropdown.Item eventKey="2" onClick={handleComplete}>Mark Complete</Dropdown.Item>
									}
								</DropdownButton>
							}
						</Col>	
					</Row>					
				</Card.Footer>				
			</Card>
		);
	};

	/** Used in Course Filter Page  */
	const ListView = () => {
		const [image, setImage] = useState(Placeholder);

		//prevent memory leaks
		const componentMounted = useRef(true);

		useEffect(() => {
			componentMounted.current = true
			const src = item.info.thumbnail
			if (cachable === true) {
				getMedia(src)
					.then(source => {
						if (componentMounted.current) {
							let file = new File([source.data], null, { type: source.type })
							setImage(URL.createObjectURL(file))
						}
					})
					.catch(err => {
						if (componentMounted.current) {
							setImage(Placeholder)
						}
					})
			} else {
				setImage(src)
			}
			return () => {
				componentMounted.current = false;
			}
		}, [])

		return (
			<Card className="mb-4 card-hover">
				{progress === 100 && 
					<Badge pill bg="primary" className="mt-3 ms-3 position-absolute">Completed</Badge>
				}
				{progress > 0 && progress < 100 &&
					<Badge pill bg="success" className="mt-3 ms-3 position-absolute">{Math.round(item.progress)}% complete</Badge>
				}
				<Row className="g-0">
					<Link
						to={`/library/${item.code}`}
						className="bg-cover img-left-rounded col-12 col-md-12 col-xl-3 col-lg-3 text-center"
						style={{
							backgroundImage: `url(${image})`,
							backgroundRepeat: 'no-repeat',
							backgroundSize: 'cover',
							backgroundPosition: 'center center'
						}}
					>
						<LazyImage
							src={item.info.thumbnail}
							cachable={cachable}
							alt=""
							className="img-fluid d-lg-none invisible"
						/>
					</Link>
					<Col lg={9} md={12} sm={12}>												
						{/* <!-- Card body --> */}
						<Card.Body>						
							<h3 className="mb-2 text-truncate-line-2 ">
								<Link to={`/library/${item.code}`} className="text-inherit">
									{item.info.editionName}
								</Link>								
							</h3>
							{/* <!-- List inline --> */}
							<ListGroup as="ul" bsPrefix="list-inline" className="mb-5">
								{item.params.filter((param) => param.code === "VERSION").map((param) => {
									return (	
										<ListGroup.Item as="li" bsPrefix="list-inline-item" key={item.id + "-" + param.code}>
											<i className={`fe ${bookParamToIcon(param.code)} me-1`}></i>
											{param.value}
										</ListGroup.Item>							
									);
								})}	
							</ListGroup>
							{/* <!-- Row --> */}
							<Row className="align-items-center g-0">
								<Col className="col-auto">
									<Image
										src={item.editor.avatar}
										className="rounded-circle avatar-xs"
										alt=""/>
								</Col>
								<Col className="col ms-2 text-truncate">
									<span>{item.info.author}</span>
								</Col>
								<Col className="col-auto">
									{progress > 0 && 
										<DropdownButton title="" variant="transparent" size="sm">											
											<Dropdown.Item eventKey="1" onClick={handleResetProgress}>Reset Progress</Dropdown.Item>
											{progress < 100 && 
												<Dropdown.Item eventKey="2" onClick={handleComplete}>Mark Complete</Dropdown.Item>
											}
										</DropdownButton>
									}
								</Col>
							</Row>
						</Card.Body>
					</Col>
				</Row>
			</Card>
		);
	};

	/** Used in Instructor Profile Page  */
	const ListGroupView = () => {
		return (
			<div className="d-lg-flex align-items-center">
				<div>
					<Image src={item.image} alt="" className="rounded img-4by3-lg" />
				</div>
				<div className="ms-lg-3 mt-2 mt-lg-0">
					<h4 className="text-primary-hover">
						{item.title}{' '}
						<Badge bg="light-success" className="text-success">
							New
						</Badge>
					</h4>
					<ListGroup
						as="ul"
						bsPrefix="list-inline"
						className="fs-6 mb-0 text-inherit"
					>
						<ListGroup.Item as="li" bsPrefix="list-inline-item">
							<i className="far fa-clock me-1"></i>
							{item.duration}
						</ListGroup.Item>
						<ListGroup.Item as="li" bsPrefix="list-inline-item">
							<LevelIcon level={item.level} />
							{item.level}
						</ListGroup.Item>
						<ListGroup.Item as="li" bsPrefix="list-inline-item">
							<span className="text-warning">
								{' '}
								<Ratings rating={item.rating} /> {item.rating.toFixed(1)}
							</span>
							<span className="fs-6 text-muted">
								{' '}
								({numberWithCommas(item.ratingby)})
							</span>
						</ListGroup.Item>
					</ListGroup>
				</div>
			</div>
		);
	};
	return (
		<Fragment>
			{viewby === 'grid' ? (
				<GridView />
			) : viewby === 'list' ? (
				<ListView />
			) : (
				<ListGroupView />
			)}
		</Fragment>
	);
};

// Specifies the default values for props
BookCard.defaultProps = {
	viewby: 'grid',
	extraclass: '',
	cachable: false
};

// Typechecking With PropTypes
BookCard.propTypes = {
	item: PropTypes.object.isRequired,
	viewby: PropTypes.string,
	extraclass: PropTypes.string,
	cachable: PropTypes.bool
};

export default BookCard;
